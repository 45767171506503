import {bind} from '@react-rxjs/core';
import {catchError, debounceTime, EMPTY, Observable, of, startWith, switchMap, tap} from 'rxjs';
import {FullMeeting, GoogleMeetWithMessages} from '../../../types/full-meeting.type';
import {createSignal} from '@react-rxjs/utils';
import {Divider, Join, Loading} from 'react-daisyui';
import {MeetingMessageComponent} from './meeting-message/meeting-message.component';
import './meeting.component.scss';
import React, {useEffect} from 'react';
import FilterPicker from './meeting-message/filter/message-filter.component';
import {MessageMark} from '../../../types/message-marks.enum';
import {OnePaw} from '../../logo/logo.component';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {MBoxDebounceTimeMS} from '../../../constants';
import {NoMeeting, NoMeetingId} from './meeting-message/meeting-errors/meeting-errors.component';
import {useServices} from '../../../service.context';
import {MeetService} from '../../../services/meet';
import {UserSettings} from '../../../services/settings/user-settings';

const [filter, onFilter] = createSignal<{ speakers?: string[], marks?: string[] } | null>();
const getMeetingByMeetId = (meetService: MeetService, meetKey: string): Observable<GoogleMeetWithMessages | null> => {
    if (!meetKey) {
        return EMPTY;
    }

    return fromPromise(meetService.getById(meetKey))
};
const [isSelectedMeetLoaded, onSelectedMeetLoaded] = createSignal<boolean>();
const [selectedMeet, onSelectedMeet] = createSignal<GoogleMeetWithMessages | null>()
const [speakers, onSpeakers] = createSignal<{ user: string, avatar?: string }[]>();

const getMeet = (meetService: MeetService, meetKey: string) => getMeetingByMeetId(meetService, meetKey).pipe(
    tap(meet => {
        onSelectedMeet(meet);
    }),
    tap((meet) => {
        if (meet) {
            onSpeakers(
                meet?.messages
                    .map(m => ({user: m.user, avatar: m.userAvatar}))
                    .filter((v, i, a) =>
                        a.findIndex(u => u.user === v.user) === i));
        }
        onSelectedMeetLoaded(true);
    }),
    catchError(error => {
        console.error(error);
        return EMPTY;
    })
);

const [useMeet] = bind((meetService: MeetService, meetKey: string) => selectedMeet.pipe(
    startWith(undefined),
    debounceTime(MBoxDebounceTimeMS),
    switchMap(meet => {
        if (meet) {
            return of(meet as FullMeeting);
        }

        return getMeet(meetService, meetKey);
    })
), null);
const [useIsSelectedMeetingLoaded] = bind(isSelectedMeetLoaded, false);
const [useSpeakers] = bind(speakers, []);
const [useFilter] = bind(filter.pipe(
), null);

const [useIsPremium] = bind((userService: UserSettings) => fromPromise(userService.isPremium()), false);


export function MeetingComponent({id}: { id?: string }) {
    const {meetService, userSettings} = useServices();
    const meeting = useMeet(meetService!, id!);
    const isMeetingLoaded = useIsSelectedMeetingLoaded()
    const speakersList = useSpeakers();
    const filter = useFilter();
    const isPremium = useIsPremium(userSettings!);

    useEffect(() => {
        onSelectedMeetLoaded(false);
        onFilter(null);
    }, [id])

    const onMark = async (messageId: string, mark: MessageMark) => {
        const message = meeting?.messages.find(m => m.key === messageId);
        if (!message) {
            return
        }
        const marks = message?.marks?.includes(mark) ? message?.marks?.filter(m => m !== mark) : [...(message?.marks || []), mark];
        onSelectedMeet({...meeting!, messages: meeting!.messages.map(m => m.key === messageId ? {...m, marks} : m)});
        await meetService!.markMessage(messageId, marks || []);
    }
    const onFilterAccepted = (speakers: { user: string, avatar?: string }[], marks?: string[]) => {
        onFilter({speakers: speakers.map(s => s.user), marks});
    }
    const onMessageFix = async (messageId: string): Promise<string> => {
        const fixedMessage = await meetService!.aiFixMessage(messageId);
        if (fixedMessage) {
            onSelectedMeet({
                ...meeting!,
                messages: meeting!.messages.map(m => m.key === messageId ? {...m, aiFixed: fixedMessage.message} : m)
            });
        }

        return fixedMessage;
    }

    if (!id) {
        return <NoMeetingId/>
    }
    if (!isMeetingLoaded) {
        return <div>
            <Join horizontal style={{width: '100%', justifyContent: 'space-between'}}>
                <h2 key="transcription"
                    className="font-bold text-2xl p-4 bg-white rounded-lg f-black">Transcription</h2>
                {speakersList?.length > 0 && (<FilterPicker speakers={speakersList} onAccept={onFilterAccepted}/>)}
            </Join>
            <Loading variant={"dots"}/>
        </div>
    }

    if (!meeting) {
        return <NoMeeting/>
    }


    return (
        <>
            <Join horizontal style={{width: '100%', justifyContent: 'space-between'}}>
                <h2 key="transcription"
                    className="font-bold text-2xl p-4 bg-white rounded-lg f-black">Transcription</h2>
                {speakersList?.length > 0 && (<FilterPicker speakers={speakersList} onAccept={onFilterAccepted}/>)}
            </Join>
            <div className={"mbox-chat"}>
                <div className={"messages"}>
                    {meeting.messages.map((cc, index) => {
                            if (filter?.speakers?.length) {
                                if (filter.speakers.includes(cc.user)) {
                                    return <MeetingMessageComponent onMark={onMark}
                                                                    showPremiumFeature={isPremium}
                                                                    onFix={onMessageFix}
                                                                    key={index.toString()} cc={cc}/>
                                }

                                if (filter?.marks && filter.marks.length && (cc.marks?.some(m => filter.marks?.includes(m)))) {
                                    return <MeetingMessageComponent onMark={onMark}
                                                                    showPremiumFeature={isPremium}
                                                                    onFix={onMessageFix}
                                                                    key={index.toString()} cc={cc}/>
                                }

                                return null;
                            }

                            if (filter?.marks?.length) {
                                if (filter?.marks && filter.marks.length && (cc.marks?.some(m => filter.marks?.includes(m)))) {
                                    return <MeetingMessageComponent onMark={onMark}
                                                                    showPremiumFeature={isPremium}
                                                                    onFix={onMessageFix}
                                                                    key={index.toString()} cc={cc}/>
                                }

                                if (filter.marks.includes(MessageMark.Commented) && cc.comment) {
                                    return <MeetingMessageComponent onMark={onMark}
                                                                    showPremiumFeature={isPremium}
                                                                    onFix={onMessageFix}
                                                                    key={index.toString()} cc={cc}/>
                                }

                                return null;
                            }

                            return <MeetingMessageComponent onMark={onMark}
                                                            showPremiumFeature={isPremium}
                                                            onFix={onMessageFix}
                                                            key={index.toString()}
                                                            cc={cc}/>
                        }
                    )}
                    <Divider color={"neutral"}><OnePaw/>Meeting finished<OnePaw/></Divider>
                </div>
            </div>
        </>
    )
}
