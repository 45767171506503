import {API_BASE_URL} from '../constants/base';

export const getChangeLog = async (getTokenFn: Promise<() => ({
    token: string,
    getRefreshedToken: () => Promise<string>
})>) => {
    try {
        const token = (await getTokenFn)().token;
        const response = await fetch(`${API_BASE_URL}/v1/changelog`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok) throw new Error('Failed to fetch changelog');
        const json = await response.json();
        return json;
    } catch (err) {
        console.error(err);
        return [];
    }
}
