import React from 'react';
import {Button} from 'react-daisyui';
import {useNavigate} from 'react-router-dom';
import {CheckIcon, CrossIcon} from '../icons/icons.component';
import Subscription from '../settings/account/subscription';

export function PaymentSucceeded() {
    const navigate = useNavigate();
    const onClick= () => {
        navigate('/settings');
    }

    return (
        <div className="flex flex-col items-center gap-6 p-8 text-center">
            <CheckIcon className="h-24 w-24 text-green-600" color="green"/>
            <h1 className="text-4xl font-bold text-green-600">Payment Successful!</h1>
            <p className="text-lg mt-4">
                Thank you for your purchase! Your subscription is now active, and you can enjoy all the premium
                features.
            </p>
            <Button
                color="primary"
                className="mt-6"
                onClick={() => onClick()}
            >
                Go to Your Account
            </Button>
        </div>
    );
}

export function PaymentFailed() {
    const navigate = useNavigate();
    const onClick= () => {
        navigate('/settings');
    }

    return (
        <div className="flex flex-col items-center gap-6 p-8 text-center">
            <CrossIcon className="h-24 w-24 text-green-600" color="red"/>
            <h1 className="text-4xl font-bold text-green-600">Payment Failed!</h1>
            <p className="text-lg mt-4">
                We were unable to process your payment. Please try again or contact support if the issue persists.
            </p>
            <Button
                color="primary"
                className="mt-6"
                onClick={() => onClick()}
            >
                Go to Your Account
            </Button>
        </div>
    );
}

export function SubscribePage() {
    return <Subscription/>
}
