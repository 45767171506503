import {ChromeExtensionId} from '../../constants';

export function sendUserToExtension(user: any, customToken: string) {
    const message = { mboxHandshake: true, authenticated: true, user, mboxToken: customToken};

// Sending the message to the extension
// @ts-ignore
    chrome.runtime?.sendMessage(ChromeExtensionId, message, (response) => {
        console.log("Response from extension:", response);
    });
}

export function askExtensionForUser() {
    const message = { mboxHandshake: true, action: 'need-user', authenticated: true};

    return new Promise((resolve, reject) => {
        // @ts-ignore
        if (!chrome || !chrome.runtime) {
            resolve(false);
        }
        // Sending the message to the extension
        // @ts-ignore
        chrome.runtime?.sendMessage(ChromeExtensionId, message, (response) => {
            if (!response) {
                console.log("No response from extension");
                resolve(false);
                return;
            }
            const need = response.need;
            console.log("Response from extension:", need);
            resolve(need);
        });
    });
}
