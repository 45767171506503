export const TranslateLanguagesMap: { [key: string]: string } = {
    "ar": "Arabic",
    "bn": "Bengali",
    "zh": "Chinese",
    "da": "Danish",
    "nl": "Dutch",
    "en": "English",
    "fr": "French",
    "de": "German",
    "gu": "Gujarati",
    "hi": "Hindi",
    "id": "Indonesian",
    "it": "Italian",
    "ja": "Japanese",
    "kn": "Kannada",
    "ko": "Korean",
    "ml": "Malayalam",
    "mr": "Marathi",
    "no": "Norwegian",
    "pl": "Polish",
    "pt": "Portuguese",
    "ua": "Ukrainian",
    "es": "Spanish",
    "sv": "Swedish",
    "ta": "Tamil",
    "te": "Telugu",
    "tr": "Turkish",
    "th": "Thai",
    "ur": "Urdu",
    "vi": "Vietnamese",
}

export const TranslateLanguagesReversedMap: { [key: string]: string } = {
    Arabic: 'ar',
    Bengali: 'bn',
    Chinese: 'zh',
    Danish: 'da',
    Dutch: 'nl',
    English: 'en',
    French: 'fr',
    German: 'de',
    Gujarati: 'gu',
    Hindi: 'hi',
    Indonesian: 'id',
    Italian: 'it',
    Japanese: 'ja',
    Kannada: 'kn',
    Korean: 'ko',
    Malayalam: 'ml',
    Marathi: 'mr',
    Norwegian: 'no',
    Polish: 'pl',
    Portuguese: 'pt',
    Ukrainian: 'ua',
    Spanish: 'es',
    Swedish: 'sv',
    Tamil: 'ta',
    Telugu: 'te',
    Turkish: 'tr',
    Thai: 'th',
    Urdu: 'ur',
    Vietnamese: 'vi'
}

export const TranslateLanguages: [string, string][] = [
    ["English", "🇬🇧"],
    ["Ukrainian", "🇺🇦"],
    ["French", "🇫🇷"],
    ["Polish", "🇵🇱"],
    ["Arabic", "🇸🇦"],
    ["Bengali", "🇧🇩"],
    ["Chinese", "🇨🇳"],
    ["Danish", "🇩🇰"],
    ["Dutch", "🇳🇱"],
    ["German", "🇩🇪"],
    ["Gujarati", "🇮🇳"],
    ["Hindi", "🇮🇳"],
    ["Indonesian", "🇮🇩"],
    ["Italian", "🇮🇹"],
    ["Japanese", "🇯🇵"],
    ["Kannada", "🇮🇳"],
    ["Korean", "🇰🇷"],
    ["Malayalam", "🇮🇳"],
    ["Marathi", "🇮🇳"],
    ["Norwegian", "🇳🇴"],
    ["Portuguese", "🇵🇹"],
    ["Spanish", "🇪🇸"],
    ["Swedish", "🇸🇪"],
    ["Tamil", "🇮🇳"],
    ["Telugu", "🇮🇳"],
    ["Turkish", "🇹🇷"],
    ["Thai", "🇹🇭"],
    ["Urdu", "🇵🇰"],
    ["Vietnamese", "🇻🇳"],
];

export const TranslateFullMap: { [key: string]: [string, string] } = {
    Arabic: ['Arabic', '🇸🇦'],
    Bengali: ['Bengali', '🇧🇩'],
    Chinese: ['Chinese', '🇨🇳'],
    Danish: ['Danish', '🇩🇰'],
    Dutch: ['Dutch', '🇳🇱'],
    English: ['English', '🇬🇧'],
    French: ['French', '🇫🇷'],
    German: ['German', '🇩🇪'],
    Gujarati: ['Gujarati', '🇮🇳'],
    Hindi: ['Hindi', '🇮🇳'],
    Indonesian: ['Indonesian', '🇮🇩'],
    Italian: ['Italian', '🇮🇹'],
    Japanese: ['Japanese', '🇯🇵'],
    Kannada: ['Kannada', '🇮🇳'],
    Korean: ['Korean', '🇰🇷'],
    Malayalam: ['Malayalam', '🇮🇳'],
    Marathi: ['Marathi', '🇮🇳'],
    Norwegian: ['Norwegian', '🇳🇴'],
    Polish: ['Polish', '🇵🇱'],
    Portuguese: ['Portuguese', '🇵🇹'],
    Ukrainian: ['Ukrainian', '🇺🇦'],
    Spanish: ['Spanish', '🇪🇸'],
    Swedish: ['Swedish', '🇸🇪'],
    Tamil: ['Tamil', '🇮🇳'],
    Telugu: ['Telugu', '🇮🇳'],
    Turkish: ['Turkish', '🇹🇷'],
    Thai: ['Thai', '🇹🇭'],
    Urdu: ['Urdu', '🇵🇰'],
    Vietnamese: ['Vietnamese', '🇻🇳']
};

export const DefaultLanguageCode = 'en';
