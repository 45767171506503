import {Button, Divider} from 'react-daisyui';
import React from 'react';
import {useNavigate} from 'react-router-dom';

const DefaultTitle = '🎁 Premium Feature';
const DefaultBody = 'Upgrade to premium to unlock more features';
const DefaultBtnText = 'Upgrade';

export function PremiumShortUpgrade({title, body, btnText, onUpgradeClicked}: {
    title?: string,
    body?: string,
    btnText?: string,
    onUpgradeClicked?: () => void
}) {
    const navigate = useNavigate();

    const onClick = () => {
        if (onUpgradeClicked) {
            onUpgradeClicked();
            return
        }
        navigate('/subscribe');
    }

    return <div className="w-1/4 font-white h-max">
        <div className="bg-neutral p-4 rounded-box">
            <h2 className="mbox-h2">{title || DefaultTitle}</h2>
            <Divider/>
            <div className="grid grid-rows-2 grid-cols-1 gap-y-4">
                <p>{body || DefaultBody}</p>
                <div className="justify-self-end">
                    <Button color="primary" className="" size="md" onClick={() => onClick()}>
                        {btnText || DefaultBtnText}
                    </Button>
                </div>
            </div>
        </div>
    </div>
}
