import React from 'react';
import {AIIcon, ChangelogIcon, FeedbackIcon, MeetingIcon, SettingsIcon, SupportIcon} from '../icons/icons.component';
import {Badge, Button, Divider, Dropdown, Indicator, Menu} from 'react-daisyui';
import {useNavigate} from 'react-router-dom';
import {useServices} from '../../../service.context';
import {bind} from '@react-rxjs/core';
import {UserSettings} from '../../../services/settings/user-settings';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';

const MenuList = [

    {
        title: 'Last meetings',
        icon: <MeetingIcon/>,
        route: '/',
        isPremium: false
    },
    {
        title: 'AI Tools',
        icon: <AIIcon height={"1.5em"} width={"1.5em"} fill={"white"}/>,
        route: '/tools',
        badge: 'New',
        isPremium: false
    },


];

const ExtraMenuList = [
    {
        title: 'Settings',
        icon: <SettingsIcon/>,
        route: '/settings',
        isPremium: false
    },
    {
        title: 'Support',
        icon: <SupportIcon/>,
        route: 'https://forms.gle/PFMFEwzqWZKjd7Uy9',
        isPremium: false
    },
    {
        title: 'ChangeLog',
        icon: <ChangelogIcon/>,
        route: '/changelog',
        isPremium: false
    },
    {
        title: 'Anonymous feedback',
        icon: <FeedbackIcon/>,
        route: 'https://forms.gle/vZwhnet4X6ufxFww9',
        isPremium: false

    }
];

const [useIsPremium] = bind(((userSettings: UserSettings) => fromPromise(userSettings.isPremium())), false);

export function MenuComponent() {
    const navigate = useNavigate();
    const {userSettings} = useServices();
    const isPremium = useIsPremium(userSettings!);
    const goTo = (route: string) => {
        if (route.startsWith('http')) {
            window.open(route, '_blank');
            return;
        }
        navigate(route);
    }

    return (
        <Menu className="text-white" horizontal>
            {MenuList.map((menuItem, index) => (
                menuItem.isPremium && !isPremium ? null :
                    <Menu.Item key={menuItem.title}>
                    <span role="link" onClick={(e) => goTo(menuItem.route)}>
                        <Indicator>
                            {menuItem.badge && <Badge color="primary" className={Indicator.Item.className({className: '-my-2', vertical: 'top', horizontal: 'end'})}>
                                {menuItem.badge}
                            </Badge>}
                            {menuItem.icon}&nbsp;{menuItem.title}
                        </Indicator>
                    </span>
                    </Menu.Item>
            ))}
            <Divider horizontal/>
            <Dropdown>
                <Button tag="label" color="ghost" size="sm" className="my-1" tabIndex={0}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                         stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7"/>
                    </svg>
                    More
                </Button>
                <Dropdown.Menu className="z-50">
                    {ExtraMenuList.map((menuItem, index) => (
                        <Dropdown.Item key={menuItem.title} onClick={() => goTo(menuItem.route)}>
                            {menuItem.icon}{menuItem.title}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </Menu>
    )
}
