import {Button, Hero} from 'react-daisyui';
import {OnboardingStepParams} from '../../onbording.interface';
import {OnboardingGetSummaryAsset} from '../../onboarding.assets';
import {NextIcon, PrevIcon} from '../../../icons/icons.component';

export function OnboardingSecurityComponent({next, prev}: OnboardingStepParams) {
    const stepName = 'security';

    return (
        <Hero>
            <Hero.Content>
                <div className="max-w-sm rounded-lg welcome">
                    <OnboardingGetSummaryAsset/>
                </div>
                <div className="ml-16">
                    <h1 className="text-5xl font-bold">MBox Meet is approved by Google</h1>
                    <p className="py-6">
                        MBox Meet is approved by Google and is compliant with Google's security standards.
                        MBox Meet is not storing any of your personal data, audio or video of the meeting.
                    </p>
                    <div className="flex justify-between">
                        <Button shape="circle" className="right-1" startIcon={<PrevIcon/>}
                                onClick={() => prev!(stepName)}></Button>
                        <Button shape="circle" className="right-1" startIcon={<NextIcon/>}
                                onClick={() => next(stepName)}></Button>
                    </div>
                </div>
            </Hero.Content>
        </Hero>
    )
}
