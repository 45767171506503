import React, {useEffect, useState} from 'react';
import {Button, Card} from 'react-daisyui';
import {useServices} from '../../../../service.context';
import {bind} from '@react-rxjs/core';
import {UserSettings} from '../../../../services/settings/user-settings';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';

const [useCurrentPlan] = bind(((userSettings: UserSettings) => fromPromise(userSettings.getCurrentPackage())), null);

const PremiumUserPage = ({isPremium, premiumUntil}: { isPremium?: boolean, premiumUntil?: number }) => {
    const {userSettings} = useServices();
    const currentPlan = useCurrentPlan(userSettings!);
    const [settings, setSettings] = useState<{
        isPremium: boolean,
        premiumUntil: number
    }>({isPremium: isPremium || false, premiumUntil: premiumUntil || 0});

    useEffect(() => {
        setSettings({isPremium: isPremium || false, premiumUntil: premiumUntil || 0});
    }, [isPremium, premiumUntil]);
    const onCancel = () => {
        userSettings!.cancelSubscription();
    }

    return (
        <div className="flex flex-col items-center gap-6 p-8">
            <h1 className="text-3xl font-bold">Welcome, Premium User!</h1>
            <Card className="w-full max-w-lg bg-base-200 shadow-lg">
                <Card.Body>
                    <Card.Title className="text-2xl">Premium Membership</Card.Title>
                    <p className="text-lg my-4">
                        Thank you for subscribing to our Premium Plan{currentPlan ? `(${currentPlan?.title})` : ''}! You now have access to all premium features:
                    </p>
                    {!currentPlan && <ul className="list-none pl-5 mb-4">
                        <li>Unlimited AI summaries per meeting</li>
                        <li>Advanced transcription analysis</li>
                        <li>Priority customer support</li>
                        <li>Enhanced security with end-to-end encryption</li>
                    </ul>}
                    {currentPlan && <ul className="list-none pl-5 mb-4 columns-2">
                        {currentPlan.features.map((feature, i) => (
                            <li key={i}>{feature}</li>
                        ))}
                    </ul>
                    }

                    <p className="text-lg my-4 text italic">
                        Your subscription is valid
                        until <b>{premiumUntil ? new Date(settings.premiumUntil).toLocaleDateString() : '--'}</b>. If
                        you wish to cancel your subscription, you can do so below:
                    </p>
                    <Button
                        color="primary"
                        onClick={() => onCancel()}
                    >
                        Cancel Subscription
                    </Button>
                </Card.Body>
            </Card>
        </div>
    );
};

export default PremiumUserPage;
