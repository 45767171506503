import {createSignal} from '@react-rxjs/utils';
import {bind} from '@react-rxjs/core';
import {catchError, debounceTime, map, of, startWith, switchMap, tap} from 'rxjs';
import {Summary} from '../../../../types/summary';
import {Divider} from 'react-daisyui';
import {OnePaw} from '../../../logo/logo.component';
import React from 'react';
import {QuickPrompts} from '../../../../constants/prompts';
import {MBoxDebounceTimeMS} from '../../../../constants';
import {TranslateLanguagesMap, TranslateLanguagesReversedMap} from '../../../../constants/languages';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import './meeting-summary.component.scss';
import {SummaryMenuComponent} from './summary-menu/summary-menu.component';
import {PromptTypes} from '../../../../types/prompt.types';
import {NoSummaryError, SummaryDisabledError} from './summary-errors/summary-errors.component';
import {SummaryLoadingComponent} from './summary-loading/summary-loading.component';
import {SummarySettings} from '../../../../types/summary-settings';
import {useServices} from '../../../../service.context';
import {MeetService} from '../../../../services/meet';

const [selectedSummary, onSelectedSummary] = createSignal<Summary | null>();
const [isLoading, onIsLoading] = createSignal<boolean>();
const [isSummaryDisabled, onIsSummaryDisabled] = createSignal<boolean>();
const [selectedLanguageCode, onSelectedLanguageCode] = createSignal<string>();
const [summaries, onSummaries] = createSignal<Summary[]>();
const [prompts, onPrompts] = createSignal<{ key: string, prompt: string }[]>();
const [selectedPrompt, onSelectedPrompt] = createSignal<string>();

const processSummariesData = (data: { summaries: Summary[]; summaryDisabled: boolean } | null) => {
    if (data === null || !data) {
        onIsSummaryDisabled(true);
        onIsLoading(false);
        return of(null);
    }
    const {summaries, summaryDisabled} = data;
    onSummaries(summaries);
    onIsSummaryDisabled(summaryDisabled);
    if (summaries && !summaryDisabled) {
        setLanguage('en');
        onSelectedSummary(summaries.find((s) => s.type === PromptTypes.Summary) || null);
    }
    onIsLoading(false);
    return of(summaries);
};

const getSummaries = (meetService: MeetService, id: string) =>
    fromPromise(meetService.getSummaries(id)).pipe(
        startWith(undefined),
        tap(() => onIsLoading(true)),
        switchMap((data) => {
            if (data === undefined) {
                return of(null);
            }

            return processSummariesData(data);
        }),
        catchError(error => {
            console.error(error);
            onIsLoading(false);
            return of(undefined);
        })
    );

const [useSummaries] = bind((meetService: MeetService, id: string) => summaries.pipe(
    startWith(undefined),
    debounceTime(MBoxDebounceTimeMS),
    switchMap(summaries => {
        if (summaries) {
            const customSummaries = summaries.filter(s => s.type === 'custom');
            onPrompts(customSummaries?.length ? [...QuickPrompts, ...customSummaries.map(s => ({
                key: s.docId,
                prompt: s.title
            }))] : [...QuickPrompts])
            return of(summaries);
        }
        return getSummaries(meetService, id!);
    })
), []);

const [useSelectedSummary] = bind(selectedSummary, null);
const [useIsLoading] = bind(isLoading.pipe(
    tap((loading) => {
        if (loading) {
            onSelectedPrompt('Summary');
        }
    })
), true);
const [useSelectedPrompt] = bind(selectedPrompt, 'Summary');
const [useIsSummaryDisabled] = bind(isSummaryDisabled, false);
const [usePrompts] = bind(prompts, QuickPrompts);

const [useSelectedLanguage] = bind(((languageCode?: string) => selectedLanguageCode.pipe(
    map(selectedCode => TranslateLanguagesMap[languageCode || selectedCode || 'en'])
)), TranslateLanguagesMap['en']);

const setLanguage = (lngCode: string) => {
    onSelectedLanguageCode(lngCode);
}

export function MeetingSummaryComponent({id}: { id?: string }) {
    const {meetService} = useServices();
    const selectedLanguage = useSelectedLanguage();
    const isSummaryDisabled = useIsSummaryDisabled();
    const summaries = useSummaries(meetService!, id!);
    const selectedSummary = useSelectedSummary();
    const isLoading = useIsLoading();
    const selectedPrompt = useSelectedPrompt();
    const quickPrompts = usePrompts();

    const onQuestion = async (question: string) => {
        if (!id) {
            return;
        }
        onIsLoading(true);
        onSelectedPrompt(question);
        const summary = await meetService!.customPrompt(id, question);
        if (!summary) {
            onIsLoading(false);
            return
        }
        const customPrompt = {key: summary.docId, prompt: summary.title};
        onPrompts([...quickPrompts, customPrompt]);
        onSummaries([...summaries!, summary]);
        onSelectedPrompt(customPrompt.prompt);
        onSelectedSummary(summary);
        onIsLoading(false);

    }
    const onSummaryChange = (type: string) => {
        if (isSummaryDisabled) {
            return;
        }
        const selectedPrompt = quickPrompts.find((prompt) => prompt.key === type);
        onSelectedPrompt(selectedPrompt?.prompt || 'Summary');
        setLanguage('en');
        const existedSummary = summaries?.find(s => s.type === type || s.docId === type);
        if (!existedSummary) {
            fetchSummary(type);
            return;
        }
        onSelectedSummary(existedSummary);
        closeDropdown();
    }
    const onTranslate = async (language: [string, string]) => {
        const languageCode = TranslateLanguagesReversedMap[language[0]];
        if (!languageCode || languageCode === 'en') {
            setLanguage('en');
            return
        }
        onIsLoading(true);
        if (selectedSummary?.translations && selectedSummary?.translations[languageCode]) {
            setLanguage(languageCode);
            onIsLoading(false);
            return;
        }
        const result = await meetService!.translateSummary(id!, selectedSummary?.type!, languageCode);

        if (result) {
            setLanguage(languageCode);
            onSelectedSummary({
                ...selectedSummary!,
                translations: {...selectedSummary?.translations, [languageCode]: result}
            });
        }
        onIsLoading(false);
    }
    const fetchSummary = async (promptKey: string) => {
        onIsLoading(true);
        const result = await meetService!.promptMeet(id!, promptKey);
        if (!result?.error) {
            onSummaries([...summaries!, result])
            onSelectedSummary(result);
        } else {
            console.error(result.error);
        }

        onIsLoading(false);
    }
    const regeneratePrompt = async (settings?: SummarySettings) => {
        onIsLoading(true);
        setLanguage('en');
        onSelectedPrompt(quickPrompts.find((prompt) => prompt.key === selectedSummary?.type)?.prompt || 'Summary');
        const result = await meetService!.updateSummary(id!, selectedSummary?.docId!, {
            promptKey: selectedSummary?.type!,
            settings
        });
        if (result) {
            onSelectedSummary(result);
        }

        onIsLoading(false);
    }
    const closeDropdown = () => {
        // @ts-ignore
        document.activeElement?.blur();
    }

    if (isLoading) {
        return <SummaryLoadingComponent prompt={selectedPrompt}/>
    }

    if (!selectedSummary && !isLoading) {
        return isSummaryDisabled ? <SummaryDisabledError/> :
            <NoSummaryError onGenerateClicked={() => onSummaryChange(PromptTypes.Summary)}/>
    }

    return (
        <div className="summary-container">
            <SummaryMenuComponent
                id={id!}
                isEncrypted={selectedSummary?.encrypted || false}
                selectedPrompt={selectedPrompt}
                selectedLng={selectedLanguage} promptList={quickPrompts}
                onRegeneratePrompt={regeneratePrompt}
                onSummaryChange={onSummaryChange}
                onTranslate={onTranslate}
                onQuestion={onQuestion}
            />

            <article className="prose mbox-summary text-white" style={{padding: 5, marginTop: 5}}
                     dangerouslySetInnerHTML={{
                         __html:
                             (selectedLanguage?.toLowerCase() !== 'english' && selectedSummary?.translations !== undefined) ?
                                 (selectedSummary?.translations!)[TranslateLanguagesReversedMap[selectedLanguage]]?.text :
                                 selectedSummary?.text || ''
                     }}/>

            <Divider color={"neutral"}><OnePaw/>End of summary<OnePaw/></Divider>
        </div>
    )
}
