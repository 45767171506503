import './onboarding.scss';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import React from 'react';
import {OnboardingWelcomeComponent} from './onboarding-steps/welcome/onboarding-welcome.component';
import {OnboardingSummaryComponent} from './onboarding-steps/summary/onboarding-summary.component';
import {OnboardingStepParams} from './onbording.interface';
import {OnboardingJoinMeetingComponent} from './onboarding-steps/join/onboarding-join-meeting.component';
import {OnboardingSecurityComponent} from './onboarding-steps/secure/onboarding-security.component';
import {getNextStep, getPrevStep} from './onboarding-steps/onboarding-stepper';
import {Button, Loading} from 'react-daisyui';
import {CloseIcon} from '../icons/icons.component';
import {createSignal} from '@react-rxjs/utils';
import {bind} from '@react-rxjs/core';
import {useServices} from '../../../service.context';
import {raiseNotification} from '../../../services/notify.service';

const getStepComponent = (step: string | undefined, onboardingStepParams: OnboardingStepParams) => {
    switch (step) {
        case 'welcome':
            return <OnboardingWelcomeComponent next={onboardingStepParams.next}/>;
        case 'summary':
            return <OnboardingSummaryComponent next={onboardingStepParams.next} prev={onboardingStepParams.prev}/>;
        case 'join-meeting':
            return <OnboardingJoinMeetingComponent next={onboardingStepParams.next} prev={onboardingStepParams.prev}/>;
        case 'security':
            return <OnboardingSecurityComponent next={onboardingStepParams.next} prev={onboardingStepParams.prev}/>;
        default:
            return <OnboardingWelcomeComponent next={onboardingStepParams.next}/>;
    }
}

const [isLoading, onLoading] = createSignal<boolean>();
const [useIsLoading] = bind(isLoading, false);

export default function OnboardingComponent({onboardCompletedFn}: { onboardCompletedFn: () => void }) {
    const {userSettings} = useServices();
    const location = useLocation();
    const {step} = useParams();
    const navigate = useNavigate();
    const isLoading = useIsLoading();

    const query = new URLSearchParams(location.search);
    const onNext = async (currentStep: string) => {
        const nextStep = getNextStep(currentStep);
        if (nextStep === '/') {
            await completeOnboarding()
            return;
        }

        navigate(`/onboarding/${nextStep}`);
    }

    const onSkip = async () => {
        await completeOnboarding();
    }

    const onPrev = (currentStep: string) => {
        const prevStep = getPrevStep(currentStep);
        navigate(`/onboarding/${prevStep}`);
    }

    const completeOnboarding = async () => {
        onLoading(true);
        await userSettings!.onboardingComplete();
        if (query.get('code')) {
            const res = await userSettings!.referralAccept(query.get('code')!);
            if (res?.status) {
                raiseNotification('You have successfully accepted the referral, enjoy your premium features!', 10000);
            }
        }
        onLoading(false);
        onboardCompletedFn()
        navigate('/');
    }

    if (isLoading) {
        return <div className="flex justify-center min-h-screen bg-neutral text-white mbox-loader">
            <Loading variant="dots"/>
        </div>;
    }

    return (
        <div className=" bg-neutral text-white onboarding-container">
            <div className="flex flex-row justify-end p-3">
                <Button
                    size="md"
                    color="neutral"
                    className="text-white"
                    startIcon={<CloseIcon/>}
                    onClick={onSkip}
                >Skip onboarding</Button>
            </div>
            <div className="flex flex-row min-h-screen justify-center items-center">
                {getStepComponent(step, {next: onNext, prev: onPrev})}
            </div>
        </div>
    );
}
