import {SummaryTextComponent} from '../../v2/text-summary/text/text.component';
import React, {useEffect, useState} from 'react';
import {Alert, Divider} from 'react-daisyui';
import {SummaryLinkComponent} from '../../v2/text-summary/link/link.component';
import {LogoSmallComponentAurora} from '../../logo/logo.component';
import {useServices} from '../../../service.context';
import {useLocation} from 'react-router-dom';

function isValidUrl(string: string) {
    if (!string) return false;
    if (!string.includes('http://') && !string.includes('https://')) {
        string = `https://${string}`;
    }
    try {
        new URL(string);
        return true;
    } catch {
        return false;
    }
}
export function PublicSummarization() {
    const {publicUtilsService} = useServices();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const queryText = query.get('text');
    const queryLink = query.get('link');

    const [text, setText] = useState(queryText || '');
    const [link, setLink] = useState(queryLink || '');
    const [summary, setSummary] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [selectedPrompt, setSelectedPrompt] = useState('c_summary');

    useEffect(() => {
        if (!text && !link) {
            setSummary('');
        }
    }, [text, link]);

    useEffect(() => {
        if (queryText || queryLink) {
            if (queryLink && !isValidUrl(queryLink)) {
                return
            }
            summarize();
        }
        if (!queryText && !queryLink && summary) {
            setSummary('');

        }
    }, [queryText, queryLink]);

    const onPromptChanged = (promptKey: string) => {
        setSelectedPrompt(promptKey);
    }
    const summarize = async () => {
        setLoading(true);

        try {
            const response = await publicUtilsService!.useSummarization({text, url: link, summaryKey: selectedPrompt});
            setSummary('');
            if (response?.error) {
                setError(response?.error);
                return
            }
            setSummary(response?.result?.summary || '');
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={`container m-auto ${loading && !summary ? 'fade-out' : ''}`}>
            {!summary && <div className={`text-center mb-8 text-white`}>
                <LogoSmallComponentAurora
                    className="fade-down w-28 h-28 mb-4 m-auto"
                />

                <h1 className="fade-down text-4xl font-bold mb-8">
                    Summarize Anything
                </h1>

            </div>}
            <div className="w-3/4 m-auto">
                <SummaryTextComponent
                    showPrompts={!!((!text && !link) || (text && !link))}
                    onSelectPrompt={onPromptChanged}
                    selectedPromptKey={selectedPrompt}
                    textState={[text, setText]}
                    isLoading={loading}
                    summaryText={!loading ? summary : ''}
                    display={!link}
                    onSummarise={summarize}
                />
                {(!text && !link) && <Divider vertical className="w-1/2 m-auto">OR</Divider>}
                <SummaryLinkComponent
                    showPrompts={link.length > 0}
                    onSelectPrompt={onPromptChanged}
                    selectedPromptKey={selectedPrompt}
                    linkState={[link, setLink]}
                    isLoading={loading}
                    summaryText={!loading ? summary : ''}
                    display={!text}
                    placeholder="Enter a link to summarize..."
                    onSummarise={summarize}
                />
                {error && <Alert status="error">{error}</Alert>}
            </div>
        </div>);
}
