import {SummaryLinkComponent} from '../../v2/text-summary/link/link.component';
import React, {useEffect, useState} from 'react';
import {LogoSmallComponentAurora} from '../../logo/logo.component';
import {useServices} from '../../../service.context';

export function PublicSummarizationYoutube() {
    const {publicUtilsService} = useServices();
    const [link, setLink] = useState('');
    const [summary, setSummary] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (!link) {
            setSummary('');
        }
    }, [link]);
    const summarize = async () => {
        setLoading(true);

        try {
            const response = await publicUtilsService!.useYoutubeSummarization({url: link});
            setSummary('');
            if (response?.error) {
                setError(response?.error);
                return
            }
            setSummary(response?.result?.summary || '');
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className={`container m-auto ${loading && !summary ? 'fade-out' : ''}`}>
            {!summary && <div className="text-center mb-8 text-white">
                <LogoSmallComponentAurora
                    className="fade-down w-28 h-28 mb-4 m-auto"
                />

                <h1 className="fade-down text-4xl font-bold mb-8">
                    Summarize YouTube
                </h1>

            </div>}
            <SummaryLinkComponent
                youtubeStrict
                selectedPromptKey={"summary"}
                linkState={[link, setLink]}
                isLoading={loading}
                summaryText={!loading ? summary : ''}
                onSummarise={summarize}
                onSelectPrompt={() => {}}
                placeholder="Enter YouTube link"
                display
            />
        </div>
    )
}
