export type PlanType = {
    title: string;
    price: string;
    current: boolean;
    features: string[];
    actionText: string;
    planId?: string;
}

export const Plans: PlanType[] = [
    {
        title: 'Basic Plan',
        price: 'Free',
        current: true,
        features: [
            '✅ AI summaries (basic)',
            '✅ Real-time transcription with speaker detection',
            '❌ AI-Powered Transcription Improvements',
            '❌ Advanced summaries',
            '❌ Advanced AI Model',
            '❌ End-to-End encryption',
            '❌ Priority support',
        ],
        actionText: 'In use',
    },
];
