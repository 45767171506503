import {ErrorIcon} from '../../../icons/icons.component';
import {MinMessagesForSummary} from '../../../../../constants';
import React from 'react';
import {Join} from 'react-daisyui';

export function NoSummaryError({onGenerateClicked}: { onGenerateClicked: () => void }) {
    return (
        <Join vertical style={{width: '100%'}}>
            <Join horizontal style={{width: '100%', justifyContent: 'space-between'}}>
                <span className="h2-white-title"><h2 className="mbox-h2">Summary</h2></span>
                {/*<Button startIcon={<ReloadIcon/>} color="neutral"*/}
                {/*        onClick={onGenerateClicked}>Generate</Button>*/}
            </Join>
            <div>No summaries for this meeting</div>
        </Join>
    )
}

export function SummaryDisabledError() {
    return (
        <Join vertical style={{width: '100%', gap: 10}}>
            <div className="w-36">
                <span className="h2-white-title"><h2 className="mbox-h2">Summary</h2></span>
            </div>
            <div role="alert" className="alert alert-error my-5">
            <ErrorIcon/>
                <span>To analyze the meeting and generate a summary the meeting should contain at least <span
                    className="font-bold">{MinMessagesForSummary}</span> replica from different participations</span>
            </div>
        </Join>
    )
}
