import React, {useState} from 'react';
import {createSignal} from '@react-rxjs/utils';
import {bind} from '@react-rxjs/core';
import {Avatar, Badge, Button, Dropdown, Indicator, Input, Join, Menu} from 'react-daisyui';
import './message-filter.component.scss';
import {MessageMark} from '../../../../../types/message-marks.enum';
import {CommentIcon, FilterIcon, ImportantMark, MarkIcon} from '../../../icons/icons.component';

const [selectedUsers, onSelectedUsers] = createSignal<{ user: string, avatar?: string }[]>();
const [selectedMarks, onSelectedMarks] = createSignal<string[]>();
const [isFilterOpened, onFilterOpened] = createSignal<boolean>();
const [isMarkFilterOpened, onMarkFilterOpened] = createSignal<boolean>();

const [useSelectedUsers] = bind(selectedUsers, []);
const [useIsFilterOpened] = bind(isFilterOpened, false);
const [useIsMarkFilterOpened] = bind(isMarkFilterOpened, true);
const [useSelectedMarks] = bind(selectedMarks, []);

const FilterPicker = ({speakers, marks, onAccept}: {
    speakers: {
        user: string,
        avatar?: string,
    }[],
    marks?: string[],
    onAccept: (speakers: { user: string, avatar?: string }[], marks?: string[]) => void
}) => {

    const selectedUsers = useSelectedUsers();
    const selectedMarks = useSelectedMarks();
    const isFilterOpened = useIsFilterOpened();
    const isMarkFilterOpened = useIsMarkFilterOpened();

    const [search, setSearch] = useState<string>('');
    const [displayItems, setDisplayItems] = useState<{ user: string, avatar?: string }[]>(speakers)
    const [isSpeakerFilterOpen, setIsSpeakerFilterOpen] = useState<boolean>(true);

    const handleOpenClick = () => {
        if (isFilterOpened) {
            closeFilter();
            return
        }
        onFilterOpened(true);
    };

    const onSearch = (value: string) => {
        setSearch(value);
        setDisplayItems(speakers.filter(item => item.user.toLowerCase().includes(value.toLowerCase())));
    }

    const selectUser = (user: { user: string, avatar?: string }) => {
        const isSelected = selectedUsers.some(u => u.user === user.user);
        if (isSelected) {
            onSelectedUsers(selectedUsers.filter(u => u.user !== user.user));
            return;
        }
        onSelectedUsers([...selectedUsers, user]);
    }

    const selectMark = (mark: string): void => {
        const isSelected = selectedMarks.some(m => m === mark);
        if (isSelected) {
            onSelectedMarks(selectedMarks.filter(m => m !== mark));
            return;
        }
        onSelectedMarks([...selectedMarks, mark]);
    }

    const onClear = () => {
        onSelectedUsers([]);
        onSelectedMarks([]);
        onAccept([]);
        closeFilter()
    }

    const onAccepted = () => {
        onAccept(selectedUsers, selectedMarks);
        closeFilter()
    }

    const closeFilter = () => {
        onFilterOpened(false)
        // @ts-ignore
        document.activeElement?.blur();
    }

    return (
        <div className="messages-filter">
            <Dropdown horizontal="left" className="text-white" vertical="bottom" open={isFilterOpened}>
                    {(selectedUsers.length === 0 && selectedMarks.length === 0) &&
                        <Button shape={"circle"}  startIcon={<FilterIcon/>}/>}
                    {(selectedUsers.length > 0 || selectedMarks.length > 0) &&
                        <Indicator>
                            <Badge
                                className={Indicator.Item.className({
                                    horizontal: 'center', vertical: 'bottom'
                                })}>
                                {selectedUsers.length + selectedMarks.length}
                            </Badge>
                            <Button shape={"circle"}  startIcon={<FilterIcon/>}/>
                        </Indicator>}
                <Dropdown.Menu
                    className="card card-compact p-2 shadow bg-neutral text-primary-content m-1 custom-width custom-height border text-white">
                    <Menu>
                        <Menu.Item className="text-white">
                            <Menu.Dropdown label={
                                <div>
                                    <span className="text-white">Speakers</span>
                                </div>
                            } open={isSpeakerFilterOpen} onClick={() => setIsSpeakerFilterOpen(!isSpeakerFilterOpen)}>
                                <Input
                                    placeholder="Search"
                                    className={"w-full bg-neutral"}
                                    size={"sm"}
                                    color={"primary"}
                                    value={search}
                                    onChange={(e) => onSearch(e.target.value)}
                                />

                                <div className="speakers">
                                    {displayItems.map((item, index) => (
                                        <Menu.Item className={"w-full"} key={index} onClick={() => selectUser(item)}>
                                            <div key={item.user} className="text-white">
                                                {item.avatar && <Avatar shape="circle" size={"xs"} src={item.avatar}/>}
                                                {item.user}
                                                {selectedUsers.some(u => u.user === item.user) &&
                                                    <span className="text-white">✓</span>}
                                            </div>
                                        </Menu.Item>
                                    ))}
                                </div>

                            </Menu.Dropdown>
                        </Menu.Item>
                        <Menu.Item>
                            <Menu.Dropdown className="text-white" label={"Marks"} open={isMarkFilterOpened}
                                           onClick={() => onMarkFilterOpened(!isMarkFilterOpened)}>
                                <Menu.Item key={MessageMark.Important}
                                           onClick={() => selectMark(MessageMark.Important)}>
                                    <div className="text-white">
                                        <ImportantMark/>{MessageMark.Important}{selectedMarks.some(m => m === MessageMark.Important) &&
                                        <span>✓</span>}</div>
                                </Menu.Item>
                                <Menu.Item key={MessageMark.Flagged} onClick={() => selectMark(MessageMark.Flagged)}>
                                    <div className="text-white">
                                        <MarkIcon/>{MessageMark.Flagged}{selectedMarks.some(m => m === MessageMark.Flagged) &&
                                        <span className="text-white">✓</span>}</div>
                                </Menu.Item>
                                <Menu.Item key={MessageMark.Commented}
                                           onClick={() => selectMark(MessageMark.Commented)}>
                                    <div className="text-white">
                                        <CommentIcon/>{MessageMark.Commented} {selectedMarks.some(m => m === MessageMark.Commented) &&
                                        <span className="text-white">✓</span>}</div>
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu.Item>
                    </Menu>
                    <Join horizontal style={{width: '100%', justifyContent: 'space-around'}}>
                        <Button color="neutral" className="action-button text-white"
                                onClick={() => onClear()}>Clear</Button>
                        <Button color="primary" className="action-button text-white"
                                onClick={() => onAccepted()}>Accept</Button>
                    </Join>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default FilterPicker;
