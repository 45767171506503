import {Button, Input, Skeleton} from 'react-daisyui';
import React, {useEffect, useState} from 'react';
import {useServices} from '../../../../service.context';
import './link.component.scss';
import {SummarySelector} from '../summary-selector';
import {NotMeetingPrompts} from '../../../../constants/prompts';


function isValidUrl(string: string) {
    if (!string) return false;
    if (!string.includes('http://') && !string.includes('https://')) {
        string = `https://${string}`;
    }
    try {
        new URL(string);
        return true;
    } catch {
        return false;
    }
}


export function SummaryLinkComponent({
                                         linkState,
                                         selectedPromptKey,
                                         isLoading,
                                         summaryText,
                                         display,
                                         placeholder,
                                         showPrompts,
                                         youtubeStrict,
                                         onSummarise,
                                         onSelectPrompt
                                     }: {
    linkState: [string, React.Dispatch<React.SetStateAction<string>>],
    selectedPromptKey: string,
    showPrompts?: boolean,
    isLoading?: boolean,
    summaryText: string,
    display: boolean,
    youtubeStrict?: boolean,
    placeholder?: string,
    onSummarise: () => void,
    onSelectPrompt: (promptKey: string) => void

}) {
    const [link, setLink] = linkState;
    const [summary, setSummary] = useState(summaryText);
    const [loading, setLoading] = useState(isLoading);
    const [linkError, setLinkError] = useState<boolean>(false);
    const [render, setRender] = useState<boolean>(display);
    const [isYoutube, setIsYoutube] = useState<boolean>(false);
    const [youtubeError, setYoutubeError] = useState<string>('');


    useEffect(() => {
        setSummary(summaryText);
        setLoading(isLoading);
        setRender(display);
    }, [summaryText, isLoading, display]);

    const validateLink = (linkValue: string) => {
        if (!linkValue) {
            setLinkError(false);
            setIsYoutube(false);
            return;
        }
        const isYoutube = linkValue.includes('youtube.com') || linkValue.includes('youtu.be');
        setLinkError(!isValidUrl(linkValue));
        setIsYoutube(isYoutube);
        if (youtubeStrict && !isYoutube) {
            // setLinkError(true);
            setYoutubeError('Invalid YouTube link');
        }
    };
    const setLinkWithValidation = (linkValue: string) => {
        validateLink(linkValue);
        setLink(linkValue);
    };

    if (!render) {
        return null;
    }

    return (
        <div
            className={`w-full flex flex-col justify-center max-h-[42rem]`}>
            <div
                className={`
            w-full p-6
            transition-transform duration-700
            max-w-xl
            m-auto
            max-h-dvh
          `}
            >

                <div className="mb-4">
                    <div className="flex flex-col md:flex-row justify-start gap-2">
                        {showPrompts &&
                            <SummarySelector onPromptChanged={onSelectPrompt} selectedKey={selectedPromptKey}/>}
                        <Input
                            type="text"
                            className={`
                  w-full p-2 border rounded-lg
                  ${linkError ? 'border-red-500' : ''}
                `}
                            color={linkError ? 'error' : 'primary'}
                            value={link}
                            onChange={(e) => setLinkWithValidation(e.target.value)}
                            onBlur={() => validateLink(link)}
                            placeholder={placeholder || 'Enter a link or YouTube link here...'}
                        />
                    </div>
                    {linkError && (
                        <p className="mt-2 text-red-800">
                            Invalid link
                        </p>
                    )}
                    {(isYoutube || youtubeStrict) && youtubeError && (
                        <p className="mt-2 error">
                            {youtubeError}
                        </p>
                    )}
                    {link && <div className="flex justify-end">
                        <Button
                            className="btn-primary rounded-xl my-4 text-white"
                            disabled={loading || linkError || ((isYoutube || youtubeStrict) && !!youtubeError) || !link}
                            onClick={() => onSummarise()}
                        >
                            {loading ? 'Summarizing...' : 'Summarize'}
                        </Button>
                    </div>}
                </div>

            </div>

            {(loading) && (<div className="flex flex-col gap-4 w-1/2 p-6 m-auto">
                <Skeleton className="h-4 w-28"></Skeleton>
                <Skeleton className="h-4 w-full"></Skeleton>
                <Skeleton className="h-4 w-28"></Skeleton>
                <Skeleton className="h-4 w-full"></Skeleton>
                <Skeleton className="h-4 w-28"></Skeleton>
                <Skeleton className="h-4 w-full"></Skeleton>
            </div>)}
            {summary.length > 0 && (
                <h1 className="text-3xl font-bold mb-8 m-auto">{NotMeetingPrompts.find(prompt => prompt.key === selectedPromptKey)?.prompt || 'Summary'}</h1>)}
            {summary.length > 0 && (
                    <article className="prose max-h-1/2 overflow-scroll text-white md:min-w-[38rem] m-auto" style={{padding: 5, marginTop: 5}}
                             dangerouslySetInnerHTML={{__html: summary}}
                    />
            )}

        </div>
    )
}
