import React, {useEffect, useState} from 'react';
import {Dropdown, Join} from 'react-daisyui';
import {DropdownIcon} from '../icons/icons.component';
import {NotMeetingPrompts} from '../../../constants/prompts';

const DefaultPrompt = 'Summary';

export function SummarySelector({selectedKey, onPromptChanged, wide}: {
    selectedKey: string,
    wide?: boolean,
    onPromptChanged: (promptKey: string) => void
}) {
    const [selectedPrompt, setSelectedPrompt] = useState(DefaultPrompt);

    useEffect(() => {
        const prompt = NotMeetingPrompts.find((p) => p.key === selectedKey);
        setSelectedPrompt(prompt?.prompt || '');
    }, [selectedKey]);

    const onPromptSelected = (key: string) => {
        const prompt = NotMeetingPrompts.find((p) => p.key === key);
        setSelectedPrompt(prompt?.prompt || '');
        onPromptChanged(key);
    }

    return <Dropdown>
        <Dropdown.Toggle button={false}>
            <Join className="bg-white f-black p-2 rounded-lg" role="button">
                        <span
                            className={`max-w-80 md:${wide ? 'max-w-96' : 'max-w-28'} font-bold text-2xl overflow-hidden whitespace-nowrap text-ellipsis`}>{selectedPrompt || DefaultPrompt}</span>
                <span className="font-bold text-xl mt-1.5"><DropdownIcon/></span>
            </Join>

        </Dropdown.Toggle>
        <Dropdown.Menu className={`w-full max-w-96 bg-neutral flex-nowrap max-h-96 overflow-y-scroll z-30`}>
            {NotMeetingPrompts.map((prompt, index) => (
                <Dropdown.Item key={prompt.key}
                               onClick={() => onPromptSelected(prompt.key)}>
                    {prompt.prompt}
                </Dropdown.Item>
            ))}
        </Dropdown.Menu>
    </Dropdown>
}
