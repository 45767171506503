import {SettingsProps} from '../../../../types/user-settings.type';
import {Badge, Button, Divider, Input, Toggle, Tooltip} from 'react-daisyui';
import {QuestionIcon} from '../../icons/icons.component';
import React, {useEffect, useState} from 'react';


export function GeneralSettingsComponent({
                                             sendNoSummary,
                                             sendSummary,
                                             redirectEmails,
                                             whiteEmailList,
                                             disableFields,
                                             onSave
                                         }: SettingsProps & {
    disableFields: boolean,
    onSave: (settings: Partial<SettingsProps>) => void
}) {
    const [settings, onSettings] = useState({
        sendNoSummary,
        sendSummary,
        redirectEmails,
        whiteEmailList
    });

    useEffect(() => {
        onSettings({sendNoSummary, sendSummary, redirectEmails, whiteEmailList});
    }, [sendNoSummary, sendSummary, redirectEmails, whiteEmailList]);

    const isWhiteListValid = () => {
        return settings.whiteEmailList && settings.whiteEmailList.length > 0 && settings.whiteEmailList.some(email => isEmailValid(email));
    }
    const updateSettings = (updatedSettings: Partial<SettingsProps>) => {
        onSettings({...settings, ...updatedSettings});
    };

    const updateWhiteEmailList = (emails: string) => {
        const emailList = emails.split(/,|\s/g);
        updateSettings({whiteEmailList: emailList});
    }

    const isEmailValid = (email: string) => {
        return email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
    }

    return (
        <div className="p-6 w-3/4">
            <h2 className="mbox-h2">Email</h2>
            <Divider/>
            <div className="grid grid-cols-2">
                <div className="col-span-1 hover:cursor-pointer"
                     onClick={() => updateSettings({sendNoSummary: !settings.sendNoSummary})}>
                    <div className="flex justify-between">
                        <label>
                            Send 'No Summary' to my email
                            <Tooltip
                                message={`When there is no summary, or it's impossible to generate the summary, do you want to get email notification?`}>
                                <QuestionIcon/>
                            </Tooltip>
                        </label>
                    </div>
                </div>
                <div className="col-span-1 col-end-7">
                    <Toggle
                        disabled={disableFields}
                        color="warning"
                        checked={settings.sendNoSummary}
                        onChange={(e) => updateSettings({sendNoSummary: e.target.checked})}
                    />
                </div>
            </div>

            <div className="grid grid-cols-2 mt-5">
                <div className="col-span-1 hover:cursor-pointer"
                     onClick={() => updateSettings({sendSummary: !settings.sendSummary})}>
                    <div className="flex justify-between">
                        <label className="block">
                            Send 'Summary' to your email(s)
                            <Tooltip
                                message={`When the summary is ready, do you want to get an email notification? It's also apply to all emails from the list below`}>
                                <QuestionIcon/>
                            </Tooltip>
                        </label>
                    </div>
                </div>
                <div className="col-span-1 col-end-7">
                    <Toggle
                        disabled={disableFields}
                        color="warning" checked={settings.sendSummary}
                        onChange={(e) => updateSettings({sendSummary: e.target.checked})}
                    />
                </div>
            </div>

            <div className="grid grid-cols-2 mt-5">
                <div className="col-span-1"
                >
                    <div className="flex justify-between">
                        <label className="block">
                            Send summary copy to emails(space to separate emails)
                            <Tooltip
                                message={'Everybody from this list will get an email with the summary of the meeting'}>
                                <QuestionIcon/>
                            </Tooltip>
                        </label>
                    </div>
                </div>
                <div className="col-span-1">
                    <Input
                        disabled={disableFields}
                        className="bg-neutral w-full hover:bg-neutral"
                        color="neutral"
                        value={settings.whiteEmailList.join(' ')}
                        onChange={(e) => updateWhiteEmailList(e.target.value)}
                    />
                </div>
                {isWhiteListValid() && (<div className="flex flex-row flex-wrap gap-3 my-10">
                    Summary also would be sent to
                    {settings.whiteEmailList.map((email, index) => (
                        isEmailValid(email) && (<div key={index} className="flex justify-between">
                            <Tooltip message="Click to remove" key={`tooltip-${index}`}>
                                <Badge
                                    color={isEmailValid(email) ? 'neutral' : 'error'}
                                    className="hover:cursor-pointer"
                                    onClick={() => {
                                        const newEmailList = settings.whiteEmailList.filter((_, i) => i !== index);
                                        updateSettings({whiteEmailList: newEmailList});
                                    }}
                                    size="lg"
                                >{email}
                                </Badge>
                            </Tooltip>
                        </div>)
                    ))}
                </div>)}
            </div>
            <Divider/>
            <div className="grid grid-cols-1">
                <div className="col-span-1 col-end-7">
                    <Button
                        disabled={disableFields || (settings.sendNoSummary === sendNoSummary && settings.sendSummary === sendSummary && settings.redirectEmails === redirectEmails && settings.whiteEmailList === whiteEmailList)}
                        color="primary"
                        variant="outline"
                        onClick={() => onSave({...settings})} size="lg">Save settings</Button>
                </div>
            </div>

        </div>
    )
}
