import {PublicUtilsEndpoint, UtilsEndpoint} from '../../constants/api';
import {Utils} from '../../constants/utils';
import {SummaryHelper} from '../helpers/summary.helper';
import {AUtils} from './utils';

export class PublicUtilsService extends AUtils {
    private utilEndpoint = PublicUtilsEndpoint;

    constructor(getToken?: () => ({
        token: string,
        getRefreshedToken: () => Promise<string>
    })) {
        const getTokenFn = getToken || (() => ({token: '', getRefreshedToken: async () => ''}));
        super(getTokenFn);
        if (!getToken) {
            this.setIsPublic(true);
        }
    }

    public async useSummarization({text, url, summaryKey}: { text?: string, url?: string, summaryKey?: string }) {
        const body: { text?: string, link?: string, params?: { summaryKey: string } } = {};
        if (text) {
            body.text = text;
        }
        if (url) {
            body.link = url;
        }
        if (summaryKey) {
            body.params = {summaryKey};
        }
        const response = await this.fetch(`${PublicUtilsEndpoint}/${url && (url?.includes('youtube') || url?.includes('youtu.be')) ? Utils.YoutubeUtils : Utils.SummaryUtils}`, {
            method: 'POST',
            body: JSON.stringify(body)
        });

        if (response) {
            const result = await response.json();
            if (!result) {
                return null;
            }

            if (result?.result?.summary) {
                result.result.summary = SummaryHelper.prepareSummary(result?.result?.summary);
            }

            if (result?.result?.metadata) {
                result.result.metadata = SummaryHelper.prepareSummary(result?.result?.metadata);
            }

            return result;
        }

        return null;
    }

    public async useYoutubeSummarization({url}: { url?: string }) {
        const body: { link?: string } = {};

        if (url) {
            body.link = url;
        }
        const response = await this.fetch(`${this.utilEndpoint}/${Utils.YoutubeUtils}`, {
            method: 'POST',
            body: JSON.stringify(body)
        });


        if (response) {
            const result = await response.json();
            if (!result) {
                return null;
            }

            if (result?.result?.summary) {
                result.result.summary = SummaryHelper.prepareSummary(result?.result?.summary);
            }

            if (result?.result?.metadata) {
                result.result.metadata = SummaryHelper.prepareSummary(result?.result?.metadata);
            }

            return result;
        }

        return null;
    }
}
