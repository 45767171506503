import {Meet} from '../../../../types/meet.type';
import {Menu} from 'react-daisyui';
import './meetings-group.scss';
import {GroupItem} from './group-item/group-item';
import {useState} from 'react';

export function MeetingsGroupComponent({date, meets, index, selectedMeet, onMeetDelete}: {
    meets: Meet[],
    date: string,
    index: number,
    selectedMeet?: string,
    onMeetDelete: (meetKey: string) => void
}) {
    const [open, setOpen] = useState(
        selectedMeet ? !!meets.find(m => m.key === selectedMeet) : index === 0
    );

    return (
        <Menu.Item key={`${date}-${index}`}>
            <Menu.Dropdown key={`${date}-dropdown`} label={
                <div key={`${date}-count`}>
                    <span>{date}</span>
                    <span className="additional-text-color">({meets.length} meeting{meets.length > 1 ? 's' : ''})</span>
                </div>
            } open={open} onClick={() => setOpen(!open)}>
                <div className={"inside-dropdown"} key={`${date}-inside-dropdown`}>
                    {meets.map(meet => <GroupItem meet={meet}
                                                  key={`${date}-${meet.key}-item`}
                                                  selected={selectedMeet === meet.key}
                                                  onMeetDelete={onMeetDelete}
                    />)}</div>
            </Menu.Dropdown>
        </Menu.Item>
    )
}
