import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {MeetService} from "./services/meet";
import {LocalStorage} from "./services/storage";
import {UserSettings} from "./services/settings/user-settings";
import {AuthService} from "./services/auth.service";
import {askExtensionForUser} from "./services/plugin/communication";
import {bind} from "@react-rxjs/core";
import {catchError, EMPTY, startWith} from "rxjs";
import {UtilsService} from './services/utils/main.utils';
import {PublicUtilsService} from './services/utils/public.utils';
import {AUtils} from './services/utils/utils';

type ServiceContextType = {
    meetService?: MeetService;
    utilsService?: UtilsService;
    publicUtilsService?: AUtils;
    localStorageService: LocalStorage;
    userSettings?: UserSettings;
    authService: AuthService;
    isReady: boolean;
    isOnboarded: boolean;
    user: any;
    getToken: (user?: any) => Promise<() => ({
        token: string,
        getRefreshedToken: () => Promise<string>
    })>
    setOnboarded: (status: boolean) => void;
};

const ServiceContext = createContext<ServiceContextType | null>(null);

const authService = new AuthService();

const [useUser] = bind(
    authService.getUserObservable().pipe(
        startWith(undefined),
        catchError(error => {
            console.error("Error in userObservable", error);
            return EMPTY;
        })
    ),
    undefined
);
export const ServiceProvider: React.FC<{ children: ReactNode }> = ({children}) => {
    const [isReady, setIsReady] = useState(false);
    const [isOnboarded, setOnboarded] = useState(true);
    const [localStorageService] = useState(new LocalStorage());

    const [meetService, setMeetService] = useState<MeetService>();
    const [utilsService, setUtilsService] = useState<UtilsService>();
    const [publicUtilsService, setPublicUtilsService] = useState<PublicUtilsService>();
    const [userSettings, setUserSettings] = useState<UserSettings>();


    const user = useUser();

    useEffect(() => {
        const initializeServices = async () => {
            setIsReady(false);

            try {
                if (user === null) {
                    setIsReady(true);
                    return;
                }

                if (user) {
                    const tokenFn = await getToken(user);
                    const meetingService = new MeetService(tokenFn);
                    const userSettingsService = new UserSettings(tokenFn);
                    const utilsService = new UtilsService(tokenFn);
                    const publicUtilsService = new PublicUtilsService(tokenFn);
                    setMeetService(meetingService);
                    setUserSettings(userSettingsService);
                    setUtilsService(utilsService);
                    setPublicUtilsService(publicUtilsService);

                    const settings = await userSettingsService.getSettings();
                    if (settings?.authError) {
                        await authService.logout();
                        setIsReady(true);
                        return;
                    }
                    setOnboarded(settings?.onboardingComplete || false);
                    const needsUser = await askExtensionForUser();
                    if (needsUser) {
                        await authService.updateUserOnExtension(user as any);
                    }

                    setIsReady(true);
                } else {
                    const publicUtilsService = new PublicUtilsService();
                    setPublicUtilsService(publicUtilsService);
                }
            } catch (error) {
                console.error("Error during initialization:", error);
            }
        };

        initializeServices();
    }, [user]); // Выполняем при изменении пользователя или сервисов


    const getToken = async (u: any) => {
        let token = await (u || user).getIdToken(true);
        return () => ({
            token,
            getRefreshedToken: async () => {
                token = await user.getIdToken(true);
                return token;
            },
        });
    };

    return (
        <ServiceContext.Provider
            value={{
                meetService,
                localStorageService,
                utilsService,
                userSettings,
                authService,
                isReady,
                isOnboarded,
                publicUtilsService,
                setOnboarded,
                user,
                getToken,
            }}
        >
            {children}
        </ServiceContext.Provider>
    );
};


export const useServices = () => {
    const context = useContext(ServiceContext);
    if (!context) {
        throw new Error("useServices must be used within ServiceProvider");
    }
    return context;
};
