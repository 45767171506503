import {
    OnboardingCompleteEndpoint,
    PlansEndpoint, ReferralEndpoint,
    SettingsEndpoint,
    SubscribeEndpoint,
    SubscriptionEndpoint
} from '../../constants/api';
import {SettingsProps} from '../../types/user-settings.type';
import {Api} from '../api';
import {RuntimeStorage} from '../runtime-storage';
import {DefaultDepthOption} from '../../constants/analyse-depth';
import {raiseNotification} from '../notify.service';
import {raiseError} from '../error.service';
import {Plans, PlanType} from '../../constants/plans';

export class UserSettings extends Api {
    public getDefault(): SettingsProps {
        return {
            sendNoSummary: false,
            sendSummary: true,
            redirectEmails: '',
            whiteEmailList: [],
            onboardingComplete: false,
            isPremium: false,
            premiumUntil: 0,
            defaultSummaryDepth: DefaultDepthOption.key,
            defaultMaxSummaryLength: 0,
            defaultMinSummaryLength: 200,
            defaultTranslateTo: '',
        };
    }

    public async getCurrentPackage(): Promise<PlanType> {
        const plans = await this.getPackages();

        return plans.find(plan => plan.current) || Plans[0]
    }

    public async getPackages(): Promise<PlanType[]> {
        const response = await this.fetch(PlansEndpoint, {
            method: 'GET',
        });
        const data = await response?.json();
        const {plans} = data;

        return [...(plans || [])] || Plans;
    }

    public async isPremium(): Promise<boolean> {
        const settings = await this.getSettings();

        const now = new Date().getTime();
        const premiumUntil = (settings.premiumUntil || 0) * 1000; // unix timestamp should be converted to milliseconds

        return (settings.isPremium && premiumUntil - now > 0) || false;
    }

    public async onboardingComplete() {
        const response = await this.fetch(OnboardingCompleteEndpoint, {
            method: 'PUT',
        });

        return await response?.json();
    }

    public async referralAccept(referralCode: string) {
        if (!referralCode) {
            return null;
        }
        const response = await this.fetch(`${ReferralEndpoint}/${referralCode}`, {
            method: 'GET',
        });

        return await response?.json();
    }

    public async getSettings(): Promise<SettingsProps & { authError: boolean }> {
        if (RuntimeStorage.getItem('settings')) {
            return RuntimeStorage.getItem('settings');
        }
        const response = await this.fetch(SettingsEndpoint, {
            method: 'GET',
        });

        const data = await response?.json();
        const defaultSettings = this.getDefault();

        const settings = {
            ...defaultSettings,
            ...data,
            authError: response?.status === 401,
        };

        RuntimeStorage.setItem('settings', settings);

        return settings;
    }

    public async updateSettings(settings: SettingsProps) {
        const response = await this.fetch(SettingsEndpoint, {
            method: 'PUT',
            body: JSON.stringify(settings),
        });

        if (response?.status === 200) {
            raiseNotification('Settings updated');
            RuntimeStorage.setItem('settings', {
                ...settings,
            });
        }

        return await response?.json();
    }

    public async cancelSubscription() {
        const response = await this.fetch(SubscriptionEndpoint, {
            method: 'DELETE',
        });
        if (response) {
            try {
                const data = await response.json();
                if (data?.error) {
                    raiseError(data.error);
                    return
                }
                if (data?.message) {
                    raiseNotification(data.message);
                    return data;
                }

                raiseNotification('Subscription cancelled');
                return data;
            } catch (ex) {
                raiseError('Error occur. Try again later.');
                return
            }
        }

        return null;
    }

    public async subscribeToPremium(planId?: string) {
        const params = new URLSearchParams();
        params.append('planID', planId || '');
        const response = await this.fetch(`${SubscribeEndpoint}?${params.toString()}`, {
            method: 'GET',

        });
        if (response) {
            try {
                const data = await response.json();
                if (data?.error) {
                    raiseError(data.error);
                    return;
                }
                if (data?.message) {
                    raiseNotification(data.message);
                    return data;
                }

                raiseNotification('Subscription cancelled');
                return data;
            } catch (ex) {
                raiseError('Error occur. Try again later.');
                return
            }
        }

        return null;
    }

    public async getReferralLink() {
        const response = await this.fetch(`${ReferralEndpoint}`, {
            method: 'GET',
        });

        const data = await response?.json();
        return data?.link || null;
    }
}
