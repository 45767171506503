import {PromptTypes} from '../types/prompt.types';

export const QuickPrompts = [
    {
        prompt: 'Summary',
        key: PromptTypes.Summary
    },
    {
        prompt: 'Meeting goals',
        key: PromptTypes.Goal
    },
    {
        prompt: 'Main topics',
        key: PromptTypes.Topic
    },
    {
        prompt: 'Summary in 1 sentence',
        key: PromptTypes.Purpose
    },
    {
        prompt: 'Key decisions',
        key: PromptTypes.Decision
    },
    {
        prompt: 'Action Items',
        key: PromptTypes.Action
    },
    {
        prompt: 'Next Steps',
        key: PromptTypes.Next
    },
    {
        prompt: 'Unresolved Issues',
        key: PromptTypes.Issue
    },
    {
        prompt: 'Missing Information',
        key: PromptTypes.Missing
    },
    {
        prompt: 'Sentiment Analysis',
        key: PromptTypes.Sentiment
    }
];

export const NotMeetingPrompts = [
    {
        prompt: 'Summary',
        key: PromptTypes.Summary
    },
    {
        prompt: 'Deep Summary',
        key: 'c_summary'
    },
    {
        prompt: 'Main topics',
        key: PromptTypes.Topic
    },
    {
        prompt: 'Summary in 1 sentence',
        key: PromptTypes.Purpose
    },
]
