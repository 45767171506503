import {Join, Loading} from 'react-daisyui';
import React from 'react';

export function SummaryLoadingComponent({prompt}: {prompt?: string}) {
    return <div className="summary-container">
        <Join vertical>
            <span className="h2-white-title"><h2 className="mbox-h2">{prompt || 'Summary'}</h2></span>
            <Loading variant="dots"/>
        </Join>
    </div>
}
