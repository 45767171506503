import {Button, ChatBubble, Divider, Indicator, Join, Skeleton, Tooltip} from 'react-daisyui';
import {MessageMark} from '../../../../types/message-marks.enum';
import {AIFixIcon, EncryptedIcon, ImportantMark, MarkIcon} from '../../icons/icons.component';
import {Message} from '../../../../types/message.type';
import {useState} from 'react';
import './meeting-message.component.scss';


export function MeetingMessageComponent({cc, onMark, onFix, showPremiumFeature}: {
    cc: Message,
    onMark: (id: string, mark: MessageMark) => void,
    onFix: (messageId: string) => Promise<string>
    showPremiumFeature?: boolean,
}) {
    const [hover, setHover] = useState<boolean>(false);
    const [onFixing, setOnFixing] = useState<boolean>(false);
    const [showOrigin, setShowOrigin] = useState<boolean>(false);

    const time = new Date(cc.lastUpdated);
    const messageTime = time.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'});
    const onAIFix = async () => {
        setOnFixing(true);
        await onFix(cc.key);
        setOnFixing(false);
    }

    return <ChatBubble key={cc.lastUpdated} className={cc.aiFixed ? "ai-fixed" : ""}>
        <ChatBubble.Header className="color-white" color="white">
            {cc?.user}
        </ChatBubble.Header>

        {cc?.userAvatar && (<ChatBubble.Avatar innerClassName="color-black bg-white" shape="circle"
                                               src={cc.userAvatar}/>)}
        {!cc?.userAvatar && (<ChatBubble.Avatar innerClassName="color-black bg-white" shape="circle"
                                                letters={cc?.user[0]}/>)}
        <ChatBubble.Message
            className={`
                    message
                     ${cc?.marks?.length || cc?.comment?.length ? 'message-marked' : ''} 
                     `
            }
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >

            <div className="flex" style={{justifyContent: 'end', flexWrap: 'wrap', gap: 10}}>
                {cc?.encrypted && (
                    <Tooltip message={"Encrypted"}><EncryptedIcon/></Tooltip>
                )}
                {cc?.marks?.includes(MessageMark.Important) &&
                    <Indicator.Item className="badge badge-neutral indicator-border">Important</Indicator.Item>}
                {cc?.marks?.includes(MessageMark.Flagged) &&
                    <Indicator.Item className="badge badge-neutral indicator-border">Flagged</Indicator.Item>}
                {cc?.comment?.length && (
                    <Indicator.Item className="badge badge-neutral indicator-border">Commented</Indicator.Item>
                )}
                {cc?.aiFixed && !showOrigin &&
                    <Tooltip message={"click to show origin"}><Indicator.Item
                        onClick={() => setShowOrigin(!showOrigin)}
                        className="badge badge-neutral indicator-border hover:cursor-pointer"
                    >Optimized</Indicator.Item></Tooltip>}
                {cc?.aiFixed && showOrigin &&
                    <Tooltip message={"click to show optimized"}><Indicator.Item
                        onClick={() => setShowOrigin(!showOrigin)}
                        className="badge badge-neutral indicator-border hover:cursor-pointer"
                    >Origin</Indicator.Item></Tooltip>}
            </div>

            {onFixing ? <div>
                <div className="flex flex-col gap-1">
                    <Skeleton className="h-4 w-48"/>
                    <Skeleton className="h-4 w-80"/>
                    <Skeleton className="h-4 w-48"/>
                    <Skeleton className="h-4 w-80"/>
                </div>
            </div> : <div>
                <CCMessage cc={cc} originFirst={showOrigin}/>
            </div>}

            {cc.comment && (
                <div>
                    <Divider color={"accent"}>Comment</Divider>
                    <span className="text-color-netural">{cc.comment}</span>
                </div>
            )}

            {((hover) &&
                <Join horizontal className="flex" style={{justifyContent: 'space-around'}}>
                    <Tooltip
                        message={cc?.marks?.includes(MessageMark.Important) ? "Mark as unimportant" : "Mark as important"}
                        position="bottom">
                        <Button
                            onClick={() => onMark(cc.key, MessageMark.Important)}
                            startIcon={<ImportantMark/>}
                            color={'neutral'}>{cc?.marks?.includes(MessageMark.Important) ? 'Unimportant' : 'Important'}</Button>
                    </Tooltip>
                    <Tooltip message={cc?.marks?.includes(MessageMark.Flagged) ? "Unflag" : "Flag"} position="bottom">
                        <Button
                            onClick={() => onMark(cc.key, MessageMark.Flagged)}
                            startIcon={<MarkIcon/>}
                            color={'neutral'}>{cc?.marks?.includes(MessageMark.Flagged) ? 'Unflag' : 'Flag'}</Button>
                    </Tooltip>

                    {showPremiumFeature && (<Tooltip message={"Fix"} position="bottom">
                        <Button
                            onClick={() => onAIFix()}
                            startIcon={<AIFixIcon/>}
                            color={'neutral'}>{cc.aiFixed ? 'Re-optimize' : 'Optimize'}</Button>
                    </Tooltip>)}
                </Join>
            )}
        </ChatBubble.Message>
        <ChatBubble.Footer>
            <div className="w-full flex flex-row justify-between">
                {cc?.lastUpdated && <span className="text-color-neutral">{messageTime}</span>}
            </div>
        </ChatBubble.Footer>

    </ChatBubble>
}

const CCMessage = ({cc, originFirst}: {
    cc: Message,
    originFirst?: boolean,
}) => {
    if (cc.aiFixed) {
        return <div>
            {!originFirst ? cc.aiFixed : cc.text}
        </div>
    }

    return <div>
        {cc.text}
    </div>
}
