import React from 'react';
import {Alert, Toast} from 'react-daisyui';
import {bind} from '@react-rxjs/core';
import {getErrorObservable} from '../../services/error.service';
import {getNotificationSubject} from '../../services/notify.service';
import {ErrorIcon, ToastInfoIcon} from '../v2/icons/icons.component';
import {PublicMenu} from './menu/public.menu';
import './public.scss';

const [useError] = bind(getErrorObservable(), null);
const [useNotification] = bind(getNotificationSubject(), null);

export default function PublicLayout({element}: { element?: React.ReactNode }) {
    const errorValue = useError();
    const notification = useNotification();

    return (
        <div className="md:mx-auto h-screen w-screen text-white">
            <PublicMenu/>
            <main className="my-4 overflow-scroll">
                <div className="children">
                    {element}
                </div>
            </main>

            {(errorValue || notification) && (
                <Toast>
                    {errorValue &&
                        <Alert status="error"><ErrorIcon/>{errorValue || 'Some error occur. Try again later'}</Alert>}
                    {notification && <Alert status="success"><ToastInfoIcon/>{notification}</Alert>}
                </Toast>
            )}
        </div>
    )
}
