import {Button} from 'react-daisyui';
import {PluginUrl} from '../../../constants';
import {LogoSmallComponent} from '../../logo/logo.component';
import {CryingCat2Icon, CryingCat3Icon, CryingCatIcon} from '../icons/icons.component';
import {useServices} from '../../../service.context';

export default function UninstallComponent() {
    const {authService, user} = useServices();

    if (user) {
        authService!.logout();
    }

    return (
        <div className="h-screen w-screen">
            <div className="flex flex-col items-center justify-center">
                <div className="max-w-lg">
                    <div className="text-5xl font-dark text-center">
                        <h2 className="font-bold">MBox Meet was uninstalled</h2>

                    </div>

                    <div className="text-3xl font-dark text-center">
                        <div>
                            We are really sorry about your decision
                            <div className="flex flex-row">
                                <CryingCatIcon/>
                                <CryingCat2Icon/>
                                <CryingCat3Icon/>
                            </div>
                        </div>
                    </div>

                    <p
                        className="text-2xl md:text-3xl font-light leading-normal font-gray-400 mt-4 mb-8 text-center"
                        style={{color: 'grey'}}
                    >Don't worry, all of your transcriptions and summaries still available</p>
                    <p className="mb-8 text-center">Install MBox Meet plugin back and continue analyze your meetings</p>

                    <div className="flex justify-center">
                        <Button
                            role="link"
                            color="neutral"
                            size="lg"
                            startIcon={<LogoSmallComponent width={25} height={31}/>}
                            onClick={() => window.open(PluginUrl, '_blank')}
                        >Install MBox Back
                        </Button>
                    </div>

                </div>

            </div>
        </div>
    );
}
