import React, {useCallback, useRef} from 'react';
import {Button, Card, Checkbox, Divider, Dropdown, Input, Join, Modal} from 'react-daisyui';
import {DropdownIcon, InfoIcon, SettingsIcon} from '../../../icons/icons.component';
import {createSignal} from '@react-rxjs/utils';
import {AnalyseDepthOption, AnalyseDepthOptions, DefaultDepthOption} from '../../../../../constants/analyse-depth';
import {bind} from '@react-rxjs/core';
import {tap} from 'rxjs';
import {SummarySettings} from '../../../../../types/summary-settings';
import {createPortal} from 'react-dom';


const MinLength = 50;
const LengthStep = 50;

const closeDepthSelector = () => {
    // @ts-ignore
    document.activeElement?.blur();
}

const [selectedDepth, onSelectedDepth] = createSignal<AnalyseDepthOption>();
const [maxLength, onMaxLength] = createSignal<number>();
const [minLength, onMinLength] = createSignal<number>();
const [isLengthControl, onLengthControl] = createSignal<boolean>();

const [useSelectedDepth] = bind(selectedDepth.pipe(
    tap(() => {
        // onDepthSelectorOpened(false);
        closeDepthSelector()
    }),
), DefaultDepthOption);
const [useMaxLength] = bind(maxLength, 0);
const [useMinLength] = bind(minLength, MinLength);
// const [useIsDepthSelectorOpened] = bind(isDepthSelectorOpened, false);
const [useLengthControl] = bind(isLengthControl, false);

export function SummarySettingsModalComponent({onRegeneratePrompt}: {
    onRegeneratePrompt: (settings?: SummarySettings) => void
}) {
    const ref = useRef<HTMLDialogElement>(null);
    const handleShow = useCallback(() => {
        ref.current?.showModal();
        closeDepthSelector();
    }, [ref]);

    const selectedDepthOption = useSelectedDepth();
    const maxLengthValue = useMaxLength();
    const minLengthValue = useMinLength();
    // const isDepthSelectorOpened = useIsDepthSelectorOpened();
    const isLengthControl = useLengthControl();


    const onSubmitWithSettings = () => {
        onRegeneratePrompt({
            depth: selectedDepthOption.key,
            maxLength: maxLengthValue,
            minLength: minLengthValue,
        });
    }

    return <>
        <Dropdown.Item onClick={handleShow} className="p-3" key="settings"><SettingsIcon/>Summary
            settings</Dropdown.Item>
        {createPortal(<Modal ref={ref} className="w-11/12 max-w-5xl " dataTheme="dark" backdrop>
            <Modal.Header className="font-bold">Summary settings for current meeting</Modal.Header>
            <Modal.Body>
                <div className="font-white h-96">
                    <div>
                        <h2 className="mbox-h2">Analyse</h2>
                        <Divider/>
                        <div className="grid grid-cols-2">
                            <div className="col-span-1">
                                <span className="">How deep to analyze the meeting?</span>
                            </div>
                            <div className="col-span-1">
                                <Dropdown id="menu-dethp-dropdown">
                                    <Dropdown.Toggle tabIndex={2}>
                                            <span
                                                className="max-w-56">{selectedDepthOption.label}</span>
                                        <span className="font-bold"><DropdownIcon/></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu id="depth-menu-inside"
                                                   className="w-64 menu-sm mt-3 p-2 bg-neutral">
                                        {AnalyseDepthOptions.map((depth) => (
                                            <Dropdown.Item key={depth.key}
                                                           onClick={() => onSelectedDepth(depth)}>
                                                {depth.label}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>

                    <div className="mt-5">
                        <div className="grid grid-cols-2">
                            <div className="col-span-1">
                                <Join>
                                    <h2 className="mbox-h2">Summary length control</h2>
                                    <Dropdown>
                                        <Dropdown.Toggle button={false}
                                                         className="btn btn-circle btn-ghost btn-xs text-info">
                                            <InfoIcon/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                            className="card compact w-80 !p-0 shadow border bg-base-100 rounded-box">
                                            <Card.Body>
                                                <Card.Title tag={'h2'}>Summary length control</Card.Title>
                                                <p>Set up a minimum or maximum words for the
                                                    summary!</p>
                                                <p><b>Minimum {MinLength} words</b></p>
                                                <p><b>If maximum is 0 it means no maximum limit </b></p>
                                            </Card.Body>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Join>
                            </div>
                            <div className="col-span-1 ml-5">
                                <Checkbox checked={isLengthControl}
                                          onChange={(e) => onLengthControl(e.target.checked)}/>
                            </div>
                        </div>
                        <Divider/>
                        {isLengthControl && (<div className="grid grid-cols-2 gap-3">
                            <div className="col-span-1">
                                Max length of the summary
                            </div>
                            <div className="col-span-1">
                                <Input
                                    type="number"
                                    className="w-36"
                                    placeholder="Max"
                                    step={LengthStep}
                                    min={MinLength}
                                    value={maxLengthValue}
                                    onChange={(e) => {
                                        onMaxLength(parseInt(e.target.value));
                                    }}
                                />
                            </div>


                            <div className="col-span-1">
                                Min length of the summary
                            </div>
                            <div className="col-span-1">
                                <Input
                                    type="number"
                                    className="w-36"
                                    placeholder="Min"
                                    step={LengthStep}
                                    min={MinLength}
                                    value={minLengthValue}
                                    onChange={(e) => {
                                        onMinLength(parseInt(e.target.value));
                                    }}
                                />
                            </div>
                        </div>)}
                    </div>
                </div>

            </Modal.Body>
            <Modal.Actions>
                <Join className="w-full flex justify-between">
                    <form method="dialog">
                        <Button size="lg" color="ghost" variant="outline" className="w-26 ">
                            Close
                        </Button>
                    </form>
                    <Button size="lg" color="primary" className="w-26" variant="outline"
                            onClick={() => onSubmitWithSettings()}>Regenerate</Button>
                </Join>
            </Modal.Actions>
        </Modal>, document.body)}
    </>
}
