import {Button} from 'react-daisyui';
import {GoogleMeetColorIcon} from '../icons/icons.component';
import {NewGoogleMeet} from '../../../constants';

export function NoMeetingsComponent() {
    const onNewGoogleMeet = () => {
        window.open(NewGoogleMeet, '_blank');
    }

    return (
        <div className="h-screen w-screen">
            <div className="flex flex-col items-center justify-center">
                <div className="max-w-md">
                    <div className="text-5xl font-dark text-center h2-white-title">
                        <h2 className="font-bold">No meetings (yet)</h2>
                    </div>
                    <p
                        className="text-2xl md:text-3xl font-light leading-normal font-gray-400 mt-4 mb-8 text-center"
                        style={{color: 'grey'}}
                    >You don't have any meetings to show yet</p>
                    <p className="mb-8 text-center">Please start any Google Meet meeting for displaying it here</p>

                    <div className="flex justify-center">
                        <Button
                            color="neutral"
                            responsive
                            startIcon={<GoogleMeetColorIcon/>}
                            onClick={() => onNewGoogleMeet()}
                        >New Google Meet
                        </Button>
                    </div>
                </div>

            </div>
        </div>
    )
}
