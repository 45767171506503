import React from 'react';
import {NavbarWithDrawer} from './menu/navbar/navbar.component';
import {Alert, Divider, Toast} from 'react-daisyui';
import './layout.scss';
import {bind} from '@react-rxjs/core';
import {getErrorObservable} from '../../services/error.service';
import {getNotificationSubject} from '../../services/notify.service';
import {ErrorIcon, ToastInfoIcon} from './icons/icons.component';

const [useError] = bind(getErrorObservable(), null);
const [useNotification] = bind(getNotificationSubject(), null);

export default function Layout({element}: { element?: React.ReactNode }) {
    const errorValue = useError();
    const notification = useNotification();

    return (
        <div className="md:mx-auto h-screen w-screen text-white">
            <NavbarWithDrawer/>
            <main className="content">
                <div className="children">
                    <Divider color={"warning"}/>
                    {element}
                </div>
            </main>

            {(errorValue || notification) && (
                <Toast>
                    {errorValue &&
                        <Alert status="error"><ErrorIcon/>{errorValue || 'Some error occur. Try again later'}</Alert>}
                    {notification && <Alert status="success"><ToastInfoIcon/>{notification}</Alert>}
                </Toast>
            )}
        </div>
    )
}
