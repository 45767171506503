import React from 'react';
import {Button, Card} from 'react-daisyui';
import {PlanType} from '../../../../constants/plans';
import {createSignal} from '@react-rxjs/utils';
import {bind} from '@react-rxjs/core';
import {useServices} from '../../../../service.context';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {UserSettings} from '../../../../services/settings/user-settings';

const ContactUs = () => {
    window.open('https://forms.gle/PFMFEwzqWZKjd7Uy9', '_blank');
}

const [isLoadingForm, onLoadingForm] = createSignal<boolean>();
const [useIsLoadingForm] = bind(isLoadingForm, false);
const [usePlans] = bind((userSettings: UserSettings) => fromPromise(userSettings.getPackages()), []);
const SubscriptionPlans = () => {
    const {userSettings} = useServices();
    const isFormLoading = useIsLoadingForm();
    const plans = usePlans(userSettings!);
    const onSubscribe = async (plan: PlanType) => {
        if (plan.title === 'Enterprise Plan') {
            ContactUs();
            return;
        }
        onLoadingForm(true);
        const data = await userSettings!.subscribeToPremium(plan.planId);
        if (data && data.url) {
            onLoadingForm(false);
            window.open(data.url, '_blank');
        }
        onLoadingForm(false);

    }

    return (
        <div className="flex flex-col items-center gap-6 p-8">
            <h1 className="text-3xl font-bold">Choose Your Plan</h1>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {plans.map((plan, index) => (
                    <Card key={index} className={`flex-1 bg-neutral text-neutral-content shadow-xl hover:scale-105 transition-transform ${plan.current ? 'bg-green-950' : ''}`}>
                        <Card.Body>
                            <Card.Title>{plan.title}</Card.Title>
                            <p className="text-xl font-semibold my-2">{plan.price}</p>
                            <ul className="pl-5 mb-4">
                                {plan.features.map((feature, i) => (
                                    <li key={i}>{feature}</li>
                                ))}
                            </ul>
                            {plan.current && (
                                <p className="text-3xl font-bold">Current Plan</p>)}
                            {!plan.current && (
                                <Button
                                    disabled={isFormLoading}
                                    color="primary"
                                    onClick={() => onSubscribe(plan)}
                                >
                                    {plan.actionText || 'Choose'}
                                </Button>
                            )}
                        </Card.Body>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default SubscriptionPlans;
