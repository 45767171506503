import {Navigate, Outlet, useLocation} from 'react-router-dom';
import React from 'react';
import {User} from 'firebase/auth';

export default function RestrictedForAuthUsersRoute({user}: { user: User }) {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const redirect = query.get('redirect');
    if (user && redirect) {
        return <Navigate to={redirect || '/'} replace/>;
    }
    return !user
        ? <Outlet/>
        : <Navigate to={`/?${query.toString()}`} replace/>;
}
