export function LogoComponent({width, height, fill}: { width?: number, height?: number, fill?: string }) {
    return (
        <svg width={width || 788} height={height || 579} viewBox="0 0 788 579" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path className="path" stroke="#fff" strokeWidth="2"
                  d="M157.467 76.1333L149.067 96H128.533H108L91.8667 79.3333C82.9334 70.1333 75.0667 62.6667 74.5334 62.6667C73.8667 62.6667 73.3334 156.667 73.3334 272.667V482.667H92H110.667V347.6V212.667L140.133 286.667C215.467 476.933 218.933 485.333 221.6 485.333C223.867 485.333 224 482.4 224 415.333V345.333H242.667H261.333V415.2V485.067L264.4 485.867C266 486.267 289.333 486.667 316.4 486.667H365.333V472V457.333H333.333H301.333V442.667V428H331.333H361.333V413.333V398.667H331.333H301.333V386V373.333H333.333H365.333V358.667V344H332H298.667V222V100H280H261.333V196.267L261.2 292.667L252.667 271.333C248 259.6 226.933 206.8 206 154C184.933 101.2 167.467 57.6 166.933 57.0667C166.4 56.5333 162.133 65.2 157.467 76.1333ZM117.467 130.667C124.4 134.4 125.867 147.867 120 154C110.8 163.733 95.4667 154.4 98.1334 140.667C99.8667 131.467 109.6 126.533 117.467 130.667ZM158.8 130.667C165.733 134.4 167.2 147.867 161.333 154C152.133 163.733 136.8 154.4 139.467 140.667C141.2 131.467 150.933 126.533 158.8 130.667Z"
                  fill={fill || 'black'}/>
            <path className="path"
                  d="M575.467 106.133C572.933 108.533 572.933 108.8 574.667 112.133C576 114.533 577.733 114.667 599.333 114.667H622.667V218.533C622.667 275.733 623.067 323.333 623.467 324.533C624.4 327.067 629.467 327.333 631.733 325.067C632.933 323.867 633.333 298.533 633.333 219.067V114.667H657.333H681.333L682.133 111.067C683.867 104.133 682.8 104 627.867 104C583.867 104 577.2 104.267 575.467 106.133Z"
                  fill={fill || 'black'}/>
            <path className="path"
                  d="M321.467 107.867C320.4 109.6 320 143.467 320 217.2C320 315.6 320.133 324 322.267 326.133C324.267 328.133 327.067 328.267 345.867 327.6C371.6 326.8 377.733 325.067 386.8 316C398 304.8 403.2 281.467 400.267 256.667C398.133 239.6 394.4 230.267 386.667 222.533L380.267 216.133L386.667 209.6C394.4 201.467 398.133 192.267 400.133 175.6C402.8 154 398.267 129.467 389.6 118.667C387.467 116.133 382.267 112.133 378 110C370.267 106 369.867 106 346.533 105.6C324 105.2 322.8 105.2 321.467 107.867ZM372.533 118.667C379.6 122 384.267 127.467 387.6 136.133C389.333 140.667 389.867 146.533 389.867 162C390 186 388.133 193.333 379.467 202C372.4 209.067 366.133 210.667 346.133 210.667H330.667V162.533V114.267L348.533 114.933C363.467 115.6 367.467 116.133 372.533 118.667ZM368.533 222.933C375.733 225.2 384.267 233.867 387.333 242.133C390.4 250.8 391.6 277.067 389.333 288.4C386.667 302 382.267 308.667 372.667 313.733C368.267 316.133 364.4 316.667 349.067 317.067L330.667 317.6V269.467V221.333H346.8C355.733 221.333 365.467 222.133 368.533 222.933Z"
                  fill={fill || 'black'}/>
            <path className="path"
                  d="M492.267 106.933C491.067 108.133 490.667 134.267 490.667 216.133C490.667 312.267 490.933 324 492.8 325.867C495.2 328.4 495.467 328.4 498.8 326.667C501.333 325.333 501.333 322.667 501.333 217.333C501.333 158.133 500.933 108.667 500.533 107.467C499.6 104.933 494.533 104.667 492.267 106.933Z"
                  fill={fill || 'black'}/>
            <path className="path"
                  d="M655.333 342.133C626.8 346.533 608.533 362 599.2 389.333C596 398.533 595.6 427.733 598.533 438C603.6 456.133 616.667 472.933 631.2 480C653.067 490.8 681.333 490.4 702.667 479.2C724.533 467.733 736.267 445.067 736.267 414.667C736.267 379.067 720.133 354.267 690.8 345.333C682.267 342.667 663.2 340.933 655.333 342.133ZM676.4 372.133C682.667 373.867 691.333 381.467 694.8 388.267C701.867 402 701.2 429.6 693.6 443.2C683.733 460.667 658.667 464.133 644.533 450C637.733 443.2 634.8 434.8 633.733 420C632.933 406.933 634.667 395.6 638.933 386.933C645.467 374 661.067 367.867 676.4 372.133Z"
                  fill={fill || 'black'}/>
            <path className="path"
                  d="M422.667 415.333V486.667H441.333H460L460.267 454.667L460.667 422.667L488.4 454.667L516.267 486.667H537.467C549.733 486.667 558.667 486.133 558.667 485.467C558.667 484.4 512.133 429.2 498.533 413.867C496 411.2 496.267 410.933 516.133 389.2C527.2 377.2 541.067 362.133 546.933 355.6L557.6 344H535.733H513.733L487.2 373.733L460.667 403.6L460.267 373.733L460 344H441.333H422.667V415.333Z"
                  fill={fill || 'black'}/>
        </svg>
    )
}

export function LogoSmallComponentAurora({
                                       width,
                                       height,
                                       className,
                                   }: {
    width?: number
    height?: number
    className?: string
}) {
    return (
        <svg
            width={width || 425}
            height={height || 431}
            viewBox="0 0 425 431"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            {/*
        1. Определяем два градиента:
           - #auroraStroke: линейный для линий (stroke).
           - #auroraFill:   радиальный для заливки (fill).
      */}
            <defs>
                {/* Линейный градиент для stroke */}
                <linearGradient
                    id="auroraStroke"
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0%" stopColor="#ff00cc">
                        <animate
                            attributeName="stop-color"
                            values="#ff00cc; #00ffff; #ff00cc"
                            dur="2s"
                            repeatCount="indefinite"
                        />
                    </stop>
                    <stop offset="100%" stopColor="#00ffff">
                        <animate
                            attributeName="stop-color"
                            values="#00ffff; #ff00cc; #00ffff"
                            dur="2s"
                            repeatCount="indefinite"
                        />
                    </stop>
                </linearGradient>

                {/* Радиальный градиент для fill */}
                <radialGradient
                    id="auroraFill"
                    cx="50%"
                    cy="50%"
                    r="70%"
                    fx="30%"
                    fy="30%"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0%" stopColor="#ff00cc">
                        <animate
                            attributeName="stop-color"
                            values="#ff00cc; #faff00; #ff00cc"
                            dur="6s"
                            repeatCount="indefinite"
                        />
                    </stop>
                    <stop offset="100%" stopColor="#00ffff">
                        <animate
                            attributeName="stop-color"
                            values="#00ffff; #ff00cc; #00ffff"
                            dur="6s"
                            repeatCount="indefinite"
                        />
                    </stop>
                </radialGradient>
            </defs>

            {/* Применяем stroke="url(#auroraStroke)" там, где обводка */}
            <path
                d="M297.5 424.5L355.5 366.5"
                stroke="url(#auroraStroke)"
                strokeWidth="8"
                strokeLinecap="round"
            />
            <path
                d="M355.5 424.5L297.5 366.5"
                stroke="url(#auroraStroke)"
                strokeWidth="8"
                strokeLinecap="round"
            />

            {/* Применяем fill="url(#auroraFill)" там, где заливка */}
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M257 429C274.673 429 289 414.673 289 397C289 379.327 274.673 365 257 365C239.327 365 225 379.327 225 397C225 414.673 239.327 429 257 429ZM257 422C270.807 422 282 410.807 282 397C282 383.193 270.807 372 257 372C243.193 372 232 383.193 232 397C232 410.807 243.193 422 257 422Z"
                fill="url(#auroraFill)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M182 429C199.673 429 214 414.673 214 397C214 379.327 199.673 365 182 365C164.327 365 150 379.327 150 397C150 414.673 164.327 429 182 429ZM182 422C195.807 422 207 410.807 207 397C207 383.193 195.807 372 182 372C168.193 372 157 383.193 157 397C157 410.807 168.193 422 182 422Z"
                fill="url(#auroraFill)"
            />
            <path
                d="M158 320.026C158.014 318.093 156.459 316.514 154.526 316.5C152.593 316.486 151.014 318.041 151 319.974L158 320.026ZM150.5 387.974L150.474 391.474L157.474 391.526L157.5 388.026L150.5 387.974ZM151 319.974L150.5 387.974L157.5 388.026L158 320.026L151 319.974Z"
                fill="url(#auroraFill)"
            />
            <path
                d="M1 348.5V37.5V32L214.5 245.5L295.5 164.5V231L210.5 316L49.5 155V348.5H1Z"
                fill="url(#auroraFill)"
            />
            <path
                d="M379.5 46.5L424 2V348.5H379.5V46.5Z"
                fill="url(#auroraFill)"
            />
            <path
                d="M424 385H379.5V430H424V385Z"
                fill="url(#auroraFill)"
            />
            <path
                d="M128 430V385H1V430H128Z"
                fill="url(#auroraFill)"
            />

            {/* Аналогично для обводки остальных контуров */}
            <path
                d="M1 348.5V37.5V32L214.5 245.5L295.5 164.5V231L210.5 316L49.5 155V348.5H1Z"
                stroke="url(#auroraStroke)"
                fill="url(#auroraFill)"
            />
            <path
                d="M379.5 46.5L424 2V348.5H379.5V46.5Z"
                stroke="url(#auroraStroke)"
                fill="url(#auroraFill)"
            />
            <path d="M424 385H379.5V430H424V385Z" stroke="url(#auroraStroke)" fill="url(#auroraFill)" />
            <path d="M128 430V385H1V430H128Z" stroke="url(#auroraStroke)" fill="url(#auroraFill)"/>
        </svg>
    )
}



export function LogoSmallComponent({width, height, fill, className}: {
    width?: number,
    height?: number,
    fill?: string,
    className?: string
}) {
    return (
        <svg width={width || 425} height={height || 431} viewBox="0 0 425 431" fill="none"
             xmlns="http://www.w3.org/2000/svg" className={className}>
            <path className="path" d="M297.5 424.5L355.5 366.5" stroke="white" strokeWidth="8" strokeLinecap="round"/>
            <path className="path" d="M355.5 424.5L297.5 366.5" stroke="white" strokeWidth="8" strokeLinecap="round"/>
            <path className="path" fillRule="evenodd" clipRule="evenodd"
                  d="M257 429C274.673 429 289 414.673 289 397C289 379.327 274.673 365 257 365C239.327 365 225 379.327 225 397C225 414.673 239.327 429 257 429ZM257 422C270.807 422 282 410.807 282 397C282 383.193 270.807 372 257 372C243.193 372 232 383.193 232 397C232 410.807 243.193 422 257 422Z"
                  fill={fill || 'white'}/>
            <path className="path" fillRule="evenodd" clipRule="evenodd"
                  d="M182 429C199.673 429 214 414.673 214 397C214 379.327 199.673 365 182 365C164.327 365 150 379.327 150 397C150 414.673 164.327 429 182 429ZM182 422C195.807 422 207 410.807 207 397C207 383.193 195.807 372 182 372C168.193 372 157 383.193 157 397C157 410.807 168.193 422 182 422Z"
                  fill={fill || 'white'}/>
            <path className="path"
                  d="M158 320.026C158.014 318.093 156.459 316.514 154.526 316.5C152.593 316.486 151.014 318.041 151 319.974L158 320.026ZM150.5 387.974L150.474 391.474L157.474 391.526L157.5 388.026L150.5 387.974ZM151 319.974L150.5 387.974L157.5 388.026L158 320.026L151 319.974Z"
                  fill={fill || 'white'}/>
            <path className="path" d="M1 348.5V37.5V32L214.5 245.5L295.5 164.5V231L210.5 316L49.5 155V348.5H1Z"
                  fill={fill || 'white'}/>
            <path className="path" d="M379.5 46.5L424 2V348.5H379.5V46.5Z" fill={fill || 'white'}/>
            <path className="path" d="M424 385H379.5V430H424V385Z" fill={fill || 'white'}/>
            <path className="path" d="M128 430V385H1V430H128Z" fill={fill || 'white'}/>
            <path className="path" d="M1 348.5V37.5V32L214.5 245.5L295.5 164.5V231L210.5 316L49.5 155V348.5H1Z"
                  stroke="white"/>
            <path className="path" d="M379.5 46.5L424 2V348.5H379.5V46.5Z" stroke="white"/>
            <path className="path" d="M424 385H379.5V430H424V385Z" stroke="white"/>
            <path className="path" d="M128 430V385H1V430H128Z" stroke="white"/>
        </svg>


    )
}

export function OnePaw() {
    return (
        <svg width="69" height="71" viewBox="0 0 69 71" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M38.3289 26.8989C42.9196 27.2826 46.7192 30.1623 50.4976 32.4719C52.5734 33.7164 54.536 34.6372 56.9252 35.1854C60.9462 36.2215 64.6737 38.8167 66.7323 42.3435C68.1127 45.0409 68.3601 47.4698 67.9701 50.4743C66.5242 54.0372 64.1382 56.5086 60.6467 58.0777C58.5958 58.7989 56.5661 59.0394 54.4177 59.2678C48.4914 59.9639 41.942 63.5667 38.1137 68.1958C35.4766 69.7819 33.2418 70.6685 30.1998 70.0954C27.0748 69.2097 24.5347 68.0746 22.8329 65.2543C20.5447 60.3939 20.3616 56.3095 22.1942 51.1625C22.4884 50.583 22.7858 50.0051 23.0855 49.4283C24.2381 47.0899 24.7043 44.6954 25.2056 42.1703C26.7249 34.5686 28.9946 27.149 38.3289 26.8989Z"
                fill={'white'} fillOpacity="0.6"/>
            <path
                d="M37.2355 0.0852757C39.8308 0.220302 41.6558 1.09016 43.5697 2.75994C46.0802 5.68448 47.7587 9.48586 47.4478 13.3935C47.3954 13.7432 47.3431 14.0929 47.2891 14.4532C47.2396 14.8323 47.1901 15.2113 47.1391 15.6019C46.6599 18.2908 45.7417 20.24 43.6203 22.0721C41.6533 23.4294 39.7485 23.8557 37.4217 23.5388C34.7959 22.8781 33.1026 21.2314 31.5727 19.1349C29.3106 15.2391 28.5771 11.3198 29.8003 6.90119C30.24 5.61208 30.7777 4.47111 31.5018 3.30719C31.6773 3.0228 31.8528 2.73842 32.0336 2.44541C33.563 1.02311 35.1765 0.267954 37.2355 0.0852757Z"
                fill={'white'} fillOpacity="0.6"/>
            <path
                d="M15.4612 10.5876C19.0211 10.8582 21.4163 12.1268 23.821 14.6434C26.3488 17.6823 27.6477 20.9338 27.5782 24.9197C27.2443 26.9432 26.7186 28.4188 25.2731 29.9348C22.8049 31.5486 20.6535 32.1794 17.7631 31.6407C13.9887 30.5564 11.4726 28.1068 9.53407 24.8111C8.03759 21.7925 7.45884 19.0825 8.2241 15.7393C9.58734 12.3624 11.716 10.6059 15.4612 10.5876Z"
                fill={'white'} fillOpacity="0.6"/>
            <path
                d="M59.6364 8.91339C60.6829 9.17796 61.6074 9.51826 62.5832 9.96388C62.9882 10.1372 63.3932 10.3104 63.8105 10.489C65.7987 12.4268 66.727 14.6936 66.9781 17.4464C66.9199 21.3207 66.0266 24.3439 63.695 27.5168C61.7514 28.9721 59.9335 29.9052 57.5015 29.8737C55.3134 29.5639 53.876 28.5926 52.4763 26.9589C50.5421 23.8636 49.6674 20.8772 50.4916 17.2632C51.7593 12.9613 54.4099 8.61138 59.6364 8.91339Z"
                fill={'white'} fillOpacity="0.6"/>
            <path
                d="M9.93116 32.0452C13.0579 32.7674 15.562 34.5754 17.7131 36.8483C19.3458 39.6443 20.3017 42.1584 19.5764 45.4163C18.5479 47.7881 17.6476 48.9944 15.2036 50.0511C12.7996 50.7611 10.4955 50.5123 8.2629 49.4997C4.37614 47.2298 2.44646 44.7434 1.01335 40.4911C0.758073 38.1762 1.46871 36.2121 2.75751 34.2446C4.67039 32.4648 7.37234 31.8457 9.93116 32.0452Z"
                fill={'white'} fillOpacity="0.6"/>
        </svg>

    )
}
