import React, {useEffect, useState} from 'react';
import {Card} from 'react-daisyui';
import {Link, useNavigate} from 'react-router-dom';
import {LogoSmallComponentAurora} from '../../logo/logo.component';
import {TextIcon, YoutubeIcon} from '../../v2/icons/icons.component';

const PublicPrefix = '/public';
const InternalPrefix = '/tools';

export function PricingSection() {
    const navigate = useNavigate();
    const goTo = (to: string) => {
        navigate(to);
    }
    return (
        <div className="py-16 px-4 md:px-8  bg-gradient-to-t from-indigo-600 via-purple-600 text-white w-full">
            {/* Заголовок секции */}
            <div className="max-w-3xl mx-auto text-center mb-12">
                <h2 className="text-3xl md:text-4xl font-bold mb-4">Plans & Pricing</h2>
                <p className="text-lg md:text-xl">
                    Enjoy free daily usage with some limits, or upgrade to unlock unlimited
                    features and priority access.
                </p>
            </div>

            {/* Карточки */}
            <div className="max-w-5xl mx-auto flex flex-col md:flex-row gap-8 items-center justify-center">
                {/* Free Plan */}
                <Card className="flex-1 bg-neutral text-neutral-content shadow-xl hover:scale-105 transition-transform">
                    <Card.Body className="text-left p-6">
                        <h3 className="text-xl font-bold mb-2">Free Plan</h3>
                        <p className="mb-4">
                            Get started at no cost, perfect for light usage.
                        </p>
                        <ul className="list-disc list-inside mb-4">
                            <li>Up to 10 summaries per day</li>
                            <li>Basic AI model</li>
                            <li>Limit of 1,000 words per summary</li>
                        </ul>
                        <p className="text-sm text-gray-300">
                            Great for exploring the core features.
                        </p>
                    </Card.Body>
                </Card>

                <Card className="flex-1 bg-neutral text-neutral-content shadow-xl hover:scale-105 transition-transform">
                    <Card.Body className="text-left p-6">
                        <h3 className="text-xl font-bold mb-2">All in One Pro Plan</h3>
                        <p className="mb-4">
                            Boost your productivity capabilities with unlimited access to MBox.
                        </p>
                        <ul className="list-disc list-inside mb-4">
                            <li>MBox AI Meet premium features</li>
                            <li>Unlimited daily summaries</li>
                            <li>Priority processing</li>
                            <li>Advanced AI model</li>
                            <li>MBox AI Meet Pro included*</li>
                        </ul>
                        <div className="mt-4 flex items-baseline gap-2">
                            <span className="text-3xl font-bold">$7.99</span>
                            <span>/ month</span>
                        </div>
                        <button className="btn btn-primary mt-6" onClick={() => goTo('/login?redirect=subscribe')}>
                            Get Premium
                        </button>
                    </Card.Body>
                </Card>
                <Card className="flex-1 bg-neutral text-neutral-content shadow-xl hover:scale-105 transition-transform">
                    <Card.Body className="text-left p-6">
                        <h3 className="text-xl font-bold mb-2">Premium Summarizer Plan</h3>
                        <p className="mb-4">
                            Boost your summarization capabilities with unlimited access.
                        </p>
                        <ul className="list-disc list-inside mb-4">
                            <li>Unlimited daily summaries</li>
                            <li>Priority processing</li>
                            <li>Advanced AI model</li>
                        </ul>
                        <div className="mt-4 flex items-baseline gap-2">
                            <span className="text-3xl font-bold">$3.99</span>
                            <span>/ month</span>
                        </div>
                        <button className="btn btn-primary mt-6" onClick={() => goTo('/login?redirect=subscribe')}>
                            Get Premium
                        </button>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}

export function PublicIntro({isInternal}: { isInternal?: boolean }) {
    const [utilLinks, setUtilLinks] = useState({
        summarizer: `${isInternal ? InternalPrefix : PublicPrefix}/summarization`,
        youtube: `${isInternal ? InternalPrefix : PublicPrefix}/youtube`,
    });

    useEffect(() => {
        setUtilLinks({
            summarizer: `${isInternal ? InternalPrefix : PublicPrefix}/summarization`,
            youtube: `${isInternal ? InternalPrefix : PublicPrefix}/youtube`,
        });
    }, [isInternal]);
    return (
        <div className="flex flex-col items-center justify-start mt-10  text-center ">
            <LogoSmallComponentAurora
                className="fade-down w-28 h-28 mb-4"
            />

            <h1 className="fade-down text-4xl font-bold mb-8">
                Welcome to MBox Utils
            </h1>


            <div className="flex flex-col md:flex-row gap-6 mb-8">
                <Link to={utilLinks.summarizer}>
                    <Card
                        className="w-80 h-56 bg-neutral text-neutral-content shadow-xl flex justify-center items-center hover:scale-105 transition-transform"
                    >
                        <Card.Body className="text-left">
                            <h2 className="card-title text-2xl"><TextIcon/>Summarizer</h2>
                            <p className="text-base">
                                Instantly generate concise summaries from any article, document, or web page.
                                Simply provide text or paste a link — our Summarizer will identify the key points
                                and save you valuable time.
                            </p>
                        </Card.Body>

                    </Card>
                </Link>

                <Link to={utilLinks.youtube}>
                    <Card
                        className="w-80 h-56 bg-neutral text-neutral-content shadow-xl flex justify-center items-center hover:scale-105 transition-transform"
                    >
                        <Card.Body className="text-left">
                            <h2 className="card-title text-2xl"><YoutubeIcon/> YouTube</h2>
                            <p className="text-base">
                                Instantly generate concise summaries from any YouTube video.
                                Simply provide the video link, and our Summarizer will highlight
                                the key points, saving you valuable time.
                            </p>
                        </Card.Body>
                    </Card>

                </Link>
            </div>

            <div className="max-w-2xl text-base text-left mt-8 p-4">
                <p>
                    Welcome to your all-in-one summarization toolkit! Our
                    <strong> Summarizer</strong> quickly distills the essence of any text
                    or link, whether it's a news article, academic paper, or blog post.
                    Simply paste your content or link, and get the key points in seconds.
                </p>
                <p className="mt-4">
                    Need to sift through a lengthy YouTube video? Our
                    <strong> YouTube Summarizer</strong> does exactly that by extracting
                    the most valuable information from any video link. Skip the filler
                    and focus on what truly matters.
                </p>
                <p className="mt-4">
                    Explore both tools and see how they can save you time, boost your
                    productivity, and streamline your information workflow.
                </p>
            </div>

            {!isInternal && <PricingSection/>}
        </div>
    );
}
