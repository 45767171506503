import React, {useEffect, useState} from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import {Button, Skeleton} from 'react-daisyui';
import {SummarySelector} from '../summary-selector';
import {NotMeetingPrompts} from '../../../../constants/prompts';


export function SummaryTextComponent({
                                         textState,
                                         showPrompts,
                                         selectedPromptKey,
                                         summaryText,
                                         isLoading,
                                         display,
                                         onSummarise,
                                         onSelectPrompt
                                     }: {
    textState: [string, React.Dispatch<React.SetStateAction<string>>],
    selectedPromptKey: string,
    isLoading?: boolean,
    summaryText: string,
    display: boolean,
    showPrompts?: boolean,
    onSummarise: () => void,
    onSelectPrompt: (promptKey: string) => void
}) {
    const [text, setText] = textState;
    const [loading, setLoading] = useState(isLoading);
    const [summary, setSummary] = useState(summaryText);
    const [render, setRender] = useState(display);

    useEffect(() => {
        setSummary(summaryText);
        setLoading(isLoading);
        setRender(display);
    }, [summaryText, isLoading, display]);

    if (!render) {
        return null;
    }

    return (
        <div
            className={`w-full flex flex-col md:flex-row items-start justify-center`}>
            <div
                className={`
            w-full p-6
            transition-transform duration-700
            max-w-xl
            max-h-[42rem]
          `}
            >
                <div className="flex flex-col gap-2">
                    {showPrompts && <div className="flex flex-col justify-between md:flex-row">
                        <h1 className="text-2xl font-bold mb-8">Type of summary</h1>
                        <SummarySelector wide onPromptChanged={onSelectPrompt} selectedKey={selectedPromptKey}/>
                    </div>}
                    <TextareaAutosize
                        className="w-full p-3 mb-4 border rounded-xl"
                        maxRows={15}
                        minRows={12}
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        placeholder="Enter your text here..."
                        style={{resize: 'none'}}
                    />
                </div>
                {text && <div className="flex justify-end">
                    <Button
                        className="btn-primary rounded-lg my-4"
                        disabled={loading || !text}
                        onClick={() => onSummarise()}
                    >
                        {loading ? 'Summarizing...' : 'Summarize'}
                    </Button>
                </div>}
            </div>

            {(loading) && (<div className="flex flex-col gap-4 w-1/2 p-6 my-32">
                <Skeleton className="h-4 w-28"></Skeleton>
                <Skeleton className="h-4 w-full"></Skeleton>
                <Skeleton className="h-4 w-28"></Skeleton>
                <Skeleton className="h-4 w-full"></Skeleton>
                <Skeleton className="h-4 w-28"></Skeleton>
                <Skeleton className="h-4 w-full"></Skeleton>
            </div>)}
            {summary.length > 0 && (
                <div className={"flex flex-col"}>
                    <h1 className="text-3xl font-bold mb-8 m-auto">{NotMeetingPrompts.find(prompt => prompt.key === selectedPromptKey)?.prompt || 'Summary'}</h1>
                    <article
                        className="prose text-white max-h-1/2 overflow-scroll m-auto "
                        dangerouslySetInnerHTML={{__html: summary}}
                    />
                </div>

            )}
        </div>
    )
}
