import {Button, Hero} from "react-daisyui";
import {LogoSmallComponent} from '../../../../logo/logo.component';
import {OnboardingStepParams} from '../../onbording.interface';
import React from 'react';


const stepName = 'welcome';

export function OnboardingWelcomeComponent({next}: OnboardingStepParams) {
    return (
        <Hero>
            <Hero.Content>
                <div className="max-w-sm rounded-lg welcome">
                    <LogoSmallComponent/>

                </div>
                <div className="ml-16">
                    <h1 className="text-5xl font-bold">Welcome to MBox Meet</h1>
                    <p className="py-6">
                        MBox is a platform that allows you to analyze and summarize your Google Meetings.
                        Let's deep dive what you can do with MBox Meet.
                    </p>
                    <div className="flex justify-end">
                        <Button color="primary" className="right-1" onClick={() => next(stepName)}>Get
                            Started</Button>
                    </div>
                </div>
            </Hero.Content>
        </Hero>


    );
}
