import {debounceTime, of, startWith, switchMap, tap} from 'rxjs';
import {bind} from '@react-rxjs/core';
import {useNavigate, useParams} from 'react-router-dom';
import {Loading, Menu} from 'react-daisyui';
import {MeetingsGroupComponent} from './meetings-group/meetings-group.component';
import './meeting-list.component.scss';
import {Panel, PanelGroup, PanelResizeHandle} from 'react-resizable-panels';
import {MeetingComponent} from '../meeting/meeting.component';
import {MeetingSummaryComponent} from '../meeting/meeting-summary/meeting-summary.component';
import {DashboardComponent} from '../dashboard/dashboard.component';
import {createSignal} from '@react-rxjs/utils';
import {NoMeetingsComponent} from '../empty/no-meetings.component';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {Meet} from '../../../types/meet.type';
import {MBoxDebounceTimeMS} from '../../../constants';
import React from 'react';
import {useServices} from '../../../service.context';
import {MeetService} from '../../../services/meet';

const [isLoading, onIsLoading] = createSignal<boolean>();
const [meets, onMeets] = createSignal<{ [key: string]: Meet[] }>();

const [useMeetings] = bind((meetService: MeetService) => meets.pipe(
        startWith(undefined),
        tap(() => onIsLoading(true)),
        debounceTime(MBoxDebounceTimeMS),
        switchMap(meets => {
            if (meets !== undefined) {
                return of(meets);
            }

            return fromPromise(meetService.getGroupedMeetList());
        }),
        tap(meets => {
            onIsLoading(false);
        })
    ),
    undefined
);

const [useIsLoading] = bind(isLoading, false);



export default function MeetingListComponent() {
    const {meetService} = useServices();
    const {id} = useParams();
    const navigate = useNavigate();
    const isLoading = useIsLoading();

    const meets = useMeetings(meetService!);
    if ((!meets || Object.keys(meets).length === 0) && isLoading) {
        return <div className="h-screen w-screen flex justify-center">
            <Loading className="h-screen" variant="dots"/>
        </div>;
    }

    if ((!meets || Object.keys(meets).length === 0)) {
        return <NoMeetingsComponent/>
    }

    const onMeetDelete = async (meetKey: string) => {
        const removed = await meetService!.removeMeet(meetKey);
        if (removed) {
            const newMeets = {...meets};
            const date = Object.keys(meets).find(date => meets[date].find(meet => meet.key === meetKey));
            if (date) {
                newMeets[date] = newMeets[date].filter(meet => meet.key !== meetKey);
            }

            onMeets(newMeets);
        }
        if (id === meetKey) {
            navigate('/');
        }
    }

    return (
        <PanelGroup autoSaveId="mbox" id={"mbox"} direction="horizontal">
            <Panel defaultSize={30} minSize={30} maxSize={50} id="meeting-groups-panel" order={0}>
                <h2 className="font-bold text-2xl p-4 bg-white rounded-lg f-black">Last meetings</h2>
                <div className="list">

                    <Menu size={'lg'}>
                        {Object.keys(meets).map((date, index) => {
                            const meetsGroup = meets[date];

                            return <MeetingsGroupComponent
                                key={date}
                                selectedMeet={id}
                                meets={meetsGroup}
                                date={date}
                                index={index}
                                onMeetDelete={onMeetDelete}
                            />
                        })}
                    </Menu>
                </div>
            </Panel>

            <PanelResizeHandle className="panel-separator"/>
            {!id && (
                <Panel minSize={20} defaultSize={70} id="dashboard-panel" order={1}>
                    <DashboardComponent meets={meets}/>
                </Panel>
            )}
            {id && <Panel minSize={25} id="meeting-panel" order={2}>
                <MeetingComponent id={id}/>
            </Panel>}
            {id && <PanelResizeHandle className="panel-separator"/>}
            {id && <Panel defaultSize={30} minSize={25} style={{overflow: 'visible'}} id="meeting-summary-panel" order={3}>
                <MeetingSummaryComponent id={id}/>
            </Panel>}
        </PanelGroup>
    )
}
