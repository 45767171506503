import {Button} from 'react-daisyui';
import './aurora.component.scss';
import {LogoSmallComponent} from '../../logo/logo.component';
import React from 'react';

export function AuroraComponent({title, subtitle, button}: {
    title: any,
    subtitle: string,
    button?: { text: string, onClick: () => void }
}) {
    return <div className="aurora-wrapper">
        <div className="content">
            <h1 className="title"><div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <LogoSmallComponent/> meet smarter
            </div>
                <div className="aurora">
                    <div className="aurora__item"></div>
                    <div className="aurora__item"></div>
                    <div className="aurora__item"></div>
                    <div className="aurora__item"></div>
                </div>
            </h1>
            <p className="subtitle">{subtitle}</p>
            {button && <Button className={"google-btn"} onClick={button.onClick}>{button.text}</Button>}
        </div>
    </div>
}
