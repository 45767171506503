import {PublicUtilsEndpoint, UtilsEndpoint} from '../../constants/api';
import {SummaryHelper} from '../helpers/summary.helper';
import {AUtils} from './utils';
import {Utils} from '../../constants/utils';


export class UtilsService extends AUtils {
    public async useYoutubeSummarization({url}: { url: string }): Promise<any> {
        const body: { link?: string } = {link: url};

        const response = await fetch(`${PublicUtilsEndpoint}/${Utils.YoutubeUtils}`, {
            method: 'POST',
            body: JSON.stringify(body)
        });

        if (response.status === 429) {
            return {error: 'Daily limit reached'};
        }
        if (response) {
            const result = await response.json();
            if (!result) {
                return null;
            }

            if (result?.result?.summary) {
                result.result.summary = SummaryHelper.prepareSummary(result?.result?.summary);
            }

            if (result?.result?.metadata) {
                result.result.metadata = SummaryHelper.prepareSummary(result?.result?.metadata);
            }

            return result;
        }

        return null;
    }

    public async useYoutubeHelper({url}: { url: string }): Promise<any> {
        const response = await this.fetch(`${UtilsEndpoint}/${Utils.YoutubeUtils}?link=${url}`, {
            method: 'GET'
        }, false);

        if (response) {
            const result = await response.json();
            if (!result) {
                return null;
            }

            return result;
        }
    }

    public async useSummarization({text, url, summaryKey}: {
        text?: string,
        url?: string,
        summaryKey: string,
    }): Promise<any> {
        const body: { text?: string, link?: string, params: { summaryKey: string } } = {params: {summaryKey}};
        if (text) {
            body.text = text;
        }
        if (url) {
            body.link = url;
        }
        const response = await this.fetch(`${UtilsEndpoint}/${url && (url?.includes('youtube') || url?.includes('youtu.be')) ? Utils.YoutubeUtils : Utils.SummaryUtils}`, {
            method: 'POST',
            body: JSON.stringify(body)
        });

        if (response) {
            const result = await response.json();
            if (!result) {
                return null;
            }

            if (result?.result?.summary) {
                result.result.summary = SummaryHelper.prepareSummary(result?.result?.summary);
            }
            if (result?.result?.metadata) {
                result.result.metadata = SummaryHelper.prepareSummary(result?.result?.metadata);
            }

            return result;
        }

        return null;
    }
}
