import {Navigate, Outlet, useLocation} from "react-router-dom";
import React from 'react';
import {User} from 'firebase/auth';

export default function GuardedRoute({user, onboardingCompleted}: { user: User, onboardingCompleted?: boolean }) {
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    if (user) {
        const redirect = query.get('redirect');
        if (redirect) {
            return <Navigate to={redirect || '/'} replace/>;
        }

        if ((onboardingCompleted === undefined || !onboardingCompleted) && !location.pathname.includes('/onboarding')) {
            return <Navigate to={`/onboarding?${query.toString()}`} replace/>;
        }
        return <Outlet/>;
    }

    return <Navigate to={`/login?${query.toString()}`} replace/>;
}


