import {Join} from 'react-daisyui';
import React from 'react';

export function NoMeetingId() {
    return <div>
        <Join horizontal style={{width: '100%', justifyContent: 'space-between'}}>
            <h2 className="prose h2-white-title" key="transcription"><h2>Transcription</h2></h2>
        </Join>
        <h2 className="prose text-white">NO ID here will be display you meeting</h2>
    </div>
}

export function NoMeeting() {
    return <div>
        <Join horizontal style={{width: '100%', justifyContent: 'space-between'}}>
            <div className="prose h2-white-title" key="transcription"><h2>Transcription</h2></div>
        </Join>
        <h2 className="prose text-white">here will be display you meeting</h2>
    </div>
}
