import {Button, Dropdown, Join, Menu} from 'react-daisyui';
import {DropdownIcon} from '../../../icons/icons.component';
import React from 'react';
import {createSignal} from '@react-rxjs/utils';
import {bind} from '@react-rxjs/core';
import {
    TranslateFullMap,
    TranslateLanguages,
    TranslateLanguagesMap,
    TranslateLanguagesReversedMap
} from '../../../../../constants/languages';
import {map, startWith} from 'rxjs';

const [selectedLanguage, onSelectedLanguage] = createSignal<[string, string]>();

const [useSelectedLanguage] = bind((selectedLng?: string) => selectedLanguage.pipe(
    startWith(selectedLng),
    map(sl => {
        if (typeof sl === 'string') {
            const language = TranslateLanguagesMap[sl];

            return (TranslateFullMap[language] || TranslateLanguages[0]) as [string, string];
        }

        return sl;
    })
), TranslateLanguages[0]);

export function SummaryTranslationComponent({onLngSelected, selectedLngCode, disabled}: {
    disabled: boolean,
    selectedLngCode: string,
    onLngSelected: (languageCode: string) => void,
}) {
    const selectedLanguage = useSelectedLanguage(selectedLngCode);

    const onLanguageSelected = (language: [string, string]) => {
        onSelectedLanguage(language);
        const languageCode = TranslateLanguagesReversedMap[language[0]];
        onLngSelected(languageCode);
        closeFilter();
    }
    const closeFilter = () => {
        // @ts-ignore
        document.activeElement?.blur();
    }
    return (
        <Dropdown className="text-white" vertical="bottom">
            <Button disabled={disabled}>
                    <span
                        className="max-w-56 overflow-hidden whitespace-nowrap text-ellipsis">{selectedLanguage![1]} {selectedLanguage![0]}</span>
                <DropdownIcon/>
            </Button>
            <Dropdown.Menu
                className="w-48 menu-sm mt-3 z-[1] p-2 bg-neutral"
            >
                <Menu className={"w-48 h-48 overflow-scroll flex-nowrap"}>

                    {TranslateLanguages.map((language, index) => (
                        <Menu.Item key={index}
                                   onClick={() => onLanguageSelected(language)}>
                            <Join key={language[0]} className="text-white">
                                <span>{language[1]}</span>
                                <span>{language[0]}</span>
                            </Join>
                        </Menu.Item>
                    ))}
                </Menu>
            </Dropdown.Menu>
        </Dropdown>
    );
}


export function SummaryTranslationShortComponent({onLngSelected}: {
    onLngSelected: (language: [string, string]) => void,
    selectedLng: [string, string],
}) {
    const onLanguageSelected = (language: [string, string]) => {
        onSelectedLanguage(language);
        onLngSelected(language);
        closeFilter();
    }
    const closeFilter = () => {
        // @ts-ignore
        document.activeElement?.blur();
    }
    return (
        <div>
            {TranslateLanguages.map((language, index) => (
                <Menu.Item className={"w-full"} key={index}
                           onClick={() => onLanguageSelected(language)}>
                    <div key={language[0]} className="text-white">
                        {language[1]} {language[0]}
                    </div>
                </Menu.Item>
            ))}
        </div>
    );
}
