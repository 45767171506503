import {Button, Divider, Dropdown, Input, Menu} from 'react-daisyui';
import {DropdownIcon} from '../../icons/icons.component';
import {AnalyseDepthOption, AnalyseDepthOptions, DefaultDepthOption} from '../../../../constants/analyse-depth';
import React, {useEffect} from 'react';
import {createSignal} from '@react-rxjs/utils';
import {bind} from '@react-rxjs/core';
import {of, switchMap, tap} from 'rxjs';
import {SettingsProps} from '../../../../types/user-settings.type';
import {
    SummaryTranslationComponent
} from '../../meeting/meeting-summary/summary-translation/summary-translation.component';
import {DefaultLanguageCode} from '../../../../constants/languages';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {UserSettings} from '../../../../services/settings/user-settings';
import {useServices} from '../../../../service.context';
import {PremiumShortUpgrade} from '../../premium-shorts/premium-short.upgrade';


const MinSummaryLength = 50;
const LengthStep = 50;

const closeDepthSelector = () => {
    // @ts-ignore
    document.activeElement?.blur();
}
const [selectedDepth, onSelectedDepth] = createSignal<AnalyseDepthOption>();
const [selectedTranslateTo, onSelectedTranslateTo] = createSignal<string>();
const [maxLength, onMaxLength] = createSignal<number>();
const [minLength, onMinLength] = createSignal<number>();

const [useSelectedDepth] = bind((defaultValue?: string) => selectedDepth.pipe(
    tap(() => {
        closeDepthSelector()
    }),
    switchMap(value => {
        if (!value) {
            return of(AnalyseDepthOptions.find(option => option.key === defaultValue) || DefaultDepthOption);
        }

        return of(value);
    })
), DefaultDepthOption);
const [useMaxLength] = bind((defaultValue?: number) => maxLength.pipe(
    switchMap(value => {
        if (!Number.isInteger(value)) {
            return of(defaultValue || 0);
        }

        return of(value);
    })
), 0);
const [useMinLength] = bind((defaultValue?: number) => minLength.pipe(
    switchMap(value => {
        if (!Number.isInteger(value)) {
            return of(defaultValue || MinSummaryLength);
        }

        return of(value);
    }),
), MinSummaryLength);

const [useSelectedTranslateTo] = bind((defaultValue?: string) => selectedTranslateTo.pipe(
    switchMap(value => {
        if (!value) {
            return of(defaultValue || DefaultLanguageCode);
        }

        return of(value);
    }),
), DefaultLanguageCode);

const [useIsPremium] = bind((userSettings: UserSettings) => fromPromise(userSettings.isPremium()), false);


export function SummarySettingsComponent({depth, minLength, maxLength, translateTo, disableFields, onSave}: {
    depth?: string,
    minLength?: number,
    maxLength?: number,
    translateTo?: string,
    disableFields?: boolean,
    onSave: (settings: Partial<SettingsProps>) => void
}) {
    const {userSettings} = useServices();
    const selectedDepthOption = useSelectedDepth(depth);
    const maxLengthValue = useMaxLength(maxLength);
    const minLengthValue = useMinLength(minLength);
    const selectedTranslateToValue = useSelectedTranslateTo(translateTo);
    const isPremium = useIsPremium(userSettings!);

    useEffect(() => {
        onSelectedDepth(AnalyseDepthOptions.find(option => option.key === depth) || DefaultDepthOption);
        onMaxLength(maxLength!);
        onMinLength(minLength!);
        onSelectedTranslateTo(translateTo!);
    }, [depth, minLength, maxLength, translateTo]);

    return (
        <div className="w-full flex flex-row gap-3">
            <div className="font-white w-3/4">
                <div>
                    <h2 className="mbox-h2">Analyse</h2>
                    <Divider/>
                    <div className="grid grid-cols-2">
                        <div className="col-span-1">
                            <span className="">How deep to analyze meetings?</span>
                        </div>
                        <div className="col-span-1 col-end-7">
                            <Menu id="depth-menu">
                                <Dropdown id="menu-dethp-dropdown">
                                    <Button disabled={disableFields || !isPremium}>
                                            <span
                                                className="max-w-56 overflow-hidden whitespace-nowrap text-ellipsis">{selectedDepthOption.label}</span>
                                        <span className="font-bold"><DropdownIcon/></span>
                                    </Button>
                                    <Dropdown.Menu className="w-64 menu-sm mt-3 z-[1] p-2 bg-neutral">
                                        {AnalyseDepthOptions.map((depth) => (
                                            <Dropdown.Item key={depth.key}
                                                           onClick={() => onSelectedDepth(depth)}>
                                                {depth.label}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Menu>
                        </div>

                        <div className="col-span-1">
                            <span className="">Translate to</span>
                        </div>
                        <div className="col-span-1 col-end-7 ml-2">
                            <SummaryTranslationComponent selectedLngCode={selectedTranslateToValue}
                                                         disabled={disableFields || !isPremium}
                                                         onLngSelected={onSelectedTranslateTo}/>
                        </div>
                    </div>
                </div>

                <div>
                    <h2 className="mbox-h2">Summary length control</h2>
                    <Divider/>
                    <div className="grid grid-cols-2 gap-3">
                        <div className="col-span-1">
                            Max length of the summary
                        </div>
                        <div className="col-span-1 col-end-7">
                            <Input
                                disabled={disableFields || !isPremium}
                                type="number"
                                className="w-36"
                                placeholder="Max"
                                step={LengthStep}
                                min={minLengthValue}
                                value={maxLengthValue}
                                onChange={(e) => {
                                    onMaxLength(parseInt(e.target.value));
                                }}
                            />
                        </div>


                        <div className="col-span-1">
                            Min length of the summary
                        </div>
                        <div className="col-span-1 col-end-7">
                            <Input
                                disabled={disableFields || !isPremium}
                                type="number"
                                className="w-36"
                                placeholder="Min"
                                step={LengthStep}
                                min={MinSummaryLength}
                                value={minLengthValue}
                                onChange={(e) => {
                                    onMinLength(parseInt(e.target.value));
                                }}
                            />
                        </div>

                    </div>
                    <Divider/>
                    <div className="grid grid-cols-1">
                        <div className="col-span-1 col-end-7">
                            <Button
                                disabled={disableFields || !isPremium}
                                color="primary"
                                variant="outline"
                                onClick={() => onSave({
                                    defaultSummaryDepth: selectedDepthOption?.key,
                                    defaultMaxSummaryLength: maxLengthValue,
                                    defaultMinSummaryLength: minLengthValue,
                                    defaultTranslateTo: selectedTranslateToValue
                                })} size="lg">Save settings</Button>
                        </div>
                    </div>
                </div>

            </div>
            {!isPremium && (
                <PremiumShortUpgrade body={"This is a premium feature. Upgrade to premium to unlock more features"}/>
            )}


        </div>
    )
}
