import {bind} from '@react-rxjs/core';
import {createSignal} from '@react-rxjs/utils';
import {Button, Hero} from 'react-daisyui';
import './login.component.scss';
import {AuroraComponent} from '../aurora/aurora.component';
import catPawBg from '../../../assets/cat-paw.png';
import {OnboardingSecurityAsset} from '../onboarding/onboarding.assets';
import {AuthService} from '../../../services/auth.service';

const [disableForm, onDisableForm] = createSignal<boolean>();
const authService = new AuthService();


const [useDisableForm] = bind(disableForm, false);


export default function LoginComponent() {
    const disableForm = useDisableForm();

    const onLogin = async () => {
        onDisableForm(true);
        await authService.login();
        onDisableForm(false);
    }

    return (
        <div className="login-wrapper w-full">
            <img alt="cat-paw" src={catPawBg} className={"cat-paw-bg"}/>
            <div className="w-full login-left-side">
                <Hero.Content>
                    <OnboardingSecurityAsset/>
                    <div>
                        <h1 className="text-5xl font-bold">Login now!</h1>
                        <p className="py-6">
                            By clicking login you agree to our terms and conditions. For more
                            information, please read our <a rel="noreferrer" href="https://mbox-meet.com/policy"
                                                            target="_blank">privacy policy</a>.
                        </p>
                        <Button disabled={disableForm} color="neutral" responsive onClick={onLogin}>Login
                            with
                            Google</Button>
                    </div>
                </Hero.Content>
            </div>
            <div className="w-full login-right-side">
                <AuroraComponent title={'Mbox - smart your meetings'} subtitle={'Developed by Nekobit LLC'}/>
            </div>
        </div>
    );
}
