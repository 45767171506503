import {Divider, Stats} from 'react-daisyui';
import {Meet} from '../../../types/meet.type';
import {AIIcon, ClockIcon, GoogleMeetIcon, HoursIcon, SevenIcon} from '../icons/icons.component';
import './dashboard.component.scss';

const getStat = (meets: Record<string, Meet[]>) => {
    const thisWeek = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    const dates = Object.keys(meets);
    let totalMeetings = 0;
    let thisWeekMeetings = 0;
    let totalDurationMin = 0;
    let totalAnalyzed = 0;
    let totalThisMonth = 0;
    let totalThisMonthAnalyzed = 0;

    let durationThisMonth = 0;

    for (const date of dates) {
        totalMeetings += meets[date].length;
        if (new Date(date) > thisWeek) {
            thisWeekMeetings += meets[date].length;
        }
        if (new Date(date).getMonth() === new Date().getMonth()) {
            totalThisMonth += meets[date].length;
        }
        for (const meet of meets[date]) {
            const meetDuration = (meet?.finishedAt || 0) - (meet?.createdAt || 0);
            if (meetDuration > 0) {
                const durationInMinutes = Math.floor(meetDuration / 60000);
                totalDurationMin += durationInMinutes

                if (new Date(meet.date).getMonth() === new Date().getMonth()) {
                    durationThisMonth += durationInMinutes;
                }
            }

            if (meet.isAnalyzed) {
                totalAnalyzed++;
                if (new Date(meet.date).getMonth() === new Date().getMonth()) {
                    totalThisMonthAnalyzed++;
                }
            }
        }

    }
    const totalDurationHours = Math.floor(totalDurationMin / 60);
    totalDurationMin = totalDurationMin % 60;

    const durationThisMonthHours = Math.floor(durationThisMonth / 60);
    durationThisMonth = durationThisMonth % 60;

    return {
        amount: {
            totalMeetings,
            totalWeekMeetings: thisWeekMeetings,
            totalThisMonth,
            totalAnalyzed
        },
        amountThisMonth: {
            totalThisMonth,
            totalThisMonthAnalyzed
        },
        duration: {
            totalDurationMin,
            totalDurationHours
        },
        durationThisMonth: {
            durationThisMonth,
            durationThisMonthHours
        }
    }
}

export function DashboardComponent({meets}: { meets: Record<string, Meet[]> }) {
    const stat = getStat(meets || {});
    const currentMonthTxt = new Date().toLocaleString('default', {month: 'long'});

    return (
        <div className="stat-wrapper">
            <div className="mbox-h2"><h2>Meetings count</h2></div>
            <Stats className="stat bg-white shadow font-sans">
                <Stats.Stat>
                    <Stats.Stat.Item variant="figure" className="text-primary">
                        <GoogleMeetIcon/>
                    </Stats.Stat.Item>
                    <Stats.Stat.Item variant="title" className="text-bold">Total meetings</Stats.Stat.Item>
                    <Stats.Stat.Item variant="value"
                                     className="text-primary">{stat.amount.totalMeetings}</Stats.Stat.Item>
                    <Stats.Stat.Item variant="desc">for whole time</Stats.Stat.Item>
                </Stats.Stat>

                <Stats.Stat>
                    <Stats.Stat.Item variant="figure" className="text-secondary">
                        <SevenIcon/>
                    </Stats.Stat.Item>
                    <Stats.Stat.Item variant="title" className="font-black">Last 7 days</Stats.Stat.Item>
                    <Stats.Stat.Item variant="value"
                                     className="text-secondary">{stat.amount.totalWeekMeetings}</Stats.Stat.Item>
                    <Stats.Stat.Item variant="desc">how much meetings in last 7 days</Stats.Stat.Item>
                </Stats.Stat>

                <Stats.Stat>
                    <Stats.Stat.Item variant="figure" className="text-primary">
                        <AIIcon/>
                    </Stats.Stat.Item>
                    <Stats.Stat.Item variant="title" className="font-black">Analyzed</Stats.Stat.Item>
                    <Stats.Stat.Item variant="value">{stat.amount.totalAnalyzed}</Stats.Stat.Item>
                    <Stats.Stat.Item variant="desc" className="text-secondary">
                        AI analyzed <span
                        className="text-bold">{Math.floor(stat.amount.totalAnalyzed / stat.amount.totalMeetings * 100)}%</span> of
                        the
                        meetings
                    </Stats.Stat.Item>
                </Stats.Stat>
            </Stats>

            <div className="mbox-h2"><h2>This month</h2></div>
            <Stats className="stat bg-white shadow font-sans">
                <Stats.Stat>
                    <Stats.Stat.Item variant="figure" className="text-primary">
                        <GoogleMeetIcon/>
                    </Stats.Stat.Item>
                    <Stats.Stat.Item variant="title" className="text-bold">Total meetings</Stats.Stat.Item>
                    <Stats.Stat.Item variant="value"
                                     className="text-primary">{stat.amountThisMonth.totalThisMonth}</Stats.Stat.Item>
                    <Stats.Stat.Item variant="desc">in <span className="text-bold">{currentMonthTxt}</span> month
                    </Stats.Stat.Item>
                </Stats.Stat>

                <Stats.Stat>
                    <Stats.Stat.Item variant="figure" className="text-secondary">
                        <AIIcon/>
                    </Stats.Stat.Item>
                    <Stats.Stat.Item variant="title" className="text-bold">Analyzed</Stats.Stat.Item>
                    <Stats.Stat.Item variant="value"
                                     className="text-secondary">{stat.amountThisMonth.totalThisMonthAnalyzed}</Stats.Stat.Item>
                    <Stats.Stat.Item variant="desc">
                        AI analyzed <span
                        className="text-bold">{Math.floor(stat.amountThisMonth.totalThisMonthAnalyzed / stat.amountThisMonth.totalThisMonth * 100) || 0}%</span> of
                        the
                        meetings this month
                    </Stats.Stat.Item>
                </Stats.Stat>
            </Stats>
            <Divider color="neutral"/>
            <div className="mbox-h2"><h2>Duration</h2></div>

            <Stats className="stat bg-white shadow font-sans">
                <Stats.Stat>
                    <Stats.Stat.Item variant="figure" className="text-primary ">
                        <HoursIcon/>
                    </Stats.Stat.Item>
                    <Stats.Stat.Item variant="figure" className="text-secondary">
                    </Stats.Stat.Item>
                    <Stats.Stat.Item variant="value">{stat.duration.totalDurationHours}</Stats.Stat.Item>
                    <Stats.Stat.Item variant="title" className="text-bold">Hours on meetings</Stats.Stat.Item>
                    <Stats.Stat.Item variant="desc" className="text-secondary">
                        how many hours you spent on meetings
                    </Stats.Stat.Item>
                </Stats.Stat>

                <Stats.Stat>
                    <Stats.Stat.Item variant="figure" className="text-primary">
                        <ClockIcon/>
                    </Stats.Stat.Item>
                    <Stats.Stat.Item variant="figure" className="text-secondary">
                    </Stats.Stat.Item>
                    <Stats.Stat.Item variant="value">{stat.duration.totalDurationMin}</Stats.Stat.Item>
                    <Stats.Stat.Item variant="title" className="text-bold">Minutes on meetings</Stats.Stat.Item>
                    <Stats.Stat.Item variant="desc" className="text-secondary">
                        how many minutes you spent on meetings
                    </Stats.Stat.Item>
                </Stats.Stat>
            </Stats>

            <div className="mbox-h2"><h2>This month</h2></div>
            <Stats className="stat bg-white shadow font-sans">
                {stat.durationThisMonth.durationThisMonthHours > 0 && (<Stats.Stat>
                    <Stats.Stat.Item variant="figure" className="text-primary ">
                        <HoursIcon/>
                    </Stats.Stat.Item>
                    <Stats.Stat.Item variant="figure" className="text-secondary">
                    </Stats.Stat.Item>
                    <Stats.Stat.Item variant="value">{stat.durationThisMonth.durationThisMonthHours}</Stats.Stat.Item>
                    <Stats.Stat.Item variant="title" className="text-bold">Hours on meetings</Stats.Stat.Item>
                    <Stats.Stat.Item variant="desc" className="text-secondary">
                        you've been in {currentMonthTxt} on meetings(hours)
                    </Stats.Stat.Item>
                </Stats.Stat>)}

                <Stats.Stat>
                    <Stats.Stat.Item variant="figure" className="text-primary">
                        <ClockIcon/>
                    </Stats.Stat.Item>
                    <Stats.Stat.Item variant="figure" className="text-secondary">
                    </Stats.Stat.Item>
                    <Stats.Stat.Item variant="value">{stat.durationThisMonth.durationThisMonth}</Stats.Stat.Item>
                    <Stats.Stat.Item variant="title" className="text-bold">Minutes on meetings</Stats.Stat.Item>
                    <Stats.Stat.Item variant="desc" className="text-secondary">
                        you've been in {currentMonthTxt} on meetings(min)
                    </Stats.Stat.Item>
                </Stats.Stat>
            </Stats>
        </div>
    );
}
