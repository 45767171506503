import {LogoutIcon, TextIcon, YoutubeIcon} from '../../v2/icons/icons.component';
import {Button, Dropdown, Navbar} from 'react-daisyui';
import {LogoSmallComponent} from '../../logo/logo.component';
import React from 'react';
import {useServices} from '../../../service.context';
import {useNavigate} from 'react-router-dom';
import {bind} from '@react-rxjs/core';
import {UserSettings} from '../../../services/settings/user-settings';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';

const MenuList = [
    {
        title: 'Text summary',
        icon: <TextIcon/>,
        route: '/public/summarization',
    },
    {
        title: 'YouTube summary',
        icon: <YoutubeIcon/>,
        route: '/public/youtube',
    },
];
const [useIsPremium] = bind(((userSettings?: UserSettings) => fromPromise(userSettings ? userSettings.isPremium() : Promise.resolve(false))), false);

const PublicMenuComponent = () => {
    const navigate = useNavigate();

    const goTo = (route: string) => {
        if (route.startsWith('http')) {
            window.open(route, '_blank');
            return;
        }
        navigate(route);
    }

    return <>
        <Dropdown>
            <Button tag="label" color="ghost" size="sm" className="my-1" tabIndex={0}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7"/>
                </svg>
                Utils
            </Button>
            <Dropdown.Menu className="z-50 min-w-52">
                {MenuList.map((menuItem, index) => (
                    <Dropdown.Item key={menuItem.title} onClick={() => goTo(menuItem.route)} >
                        {menuItem.icon}{menuItem.title}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    </>
};

export function PublicMenu() {
    const {user, userSettings} = useServices();
    const navigate = useNavigate();

    const isPremium = useIsPremium(userSettings)
    const handleAuthClick = async () => {
        if (user) {
            navigate('/');
            return
        }
        navigate('/login');
    }

    return <Navbar className="font-sans bg-neutral">
        <Navbar.Start className="px-2 lg:flex-none">
            <div role="link" onClick={() => navigate('/public')} style={{textDecoration: 'none'}} className="main-page hover:cursor-pointer">
                <LogoSmallComponent width={60} height={40} fill={'white'}/>
                {isPremium && <div className="gold">Premium</div>}
            </div>
            <PublicMenuComponent/>
        </Navbar.Start>

        <Navbar.End>
            <Button color="ghost" onClick={handleAuthClick} className={"text-white"} endIcon={<LogoutIcon/>}>
                {user ? 'MBox App' : 'Log in'}
            </Button>
        </Navbar.End>
    </Navbar>
}
