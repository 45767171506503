export class SummaryHelper {
    public static isHTML(str: string) {
        const doc = new DOMParser().parseFromString(str, "text/html");
        return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
    }

    public static  prepareSummary(s: string): any {
        if (s && !SummaryHelper.isHTML(s)) {
            s = s.replace(/\n/g, '<br/>');
        }

        if (s && SummaryHelper.isHTML(s)) {
            s = SummaryHelper.removeBrTagsFromList(s);
        }

        return s;
    }

    public static  removeBrTagsFromList(htmlString: string): string {
        // Create a temporary DOM element to parse the HTML string
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;

        // find all br tags
        const brTags = tempDiv.querySelectorAll('br');
        // remove a duplication of br tags
        for (let i = brTags.length - 1; i >= 1; i--) {
            const currentBr = brTags[i];
            const previousBr = brTags[i - 1];

            if (previousBr.nextSibling === currentBr) {
                previousBr.parentNode?.removeChild(currentBr);
            }
        }

        // Select all list items (li) within the temporary div
        const listItems = tempDiv.querySelectorAll('ul');

        // Iterate over each list item
        listItems.forEach(listItem => {
            // Select all <br> tags within the current list item
            const brTags = listItem.querySelectorAll('br');

            // Remove each <br> tag
            brTags.forEach(brTag => {
                brTag.parentNode?.removeChild(brTag);
            });
        });

        // Return the modified HTML string
        return tempDiv.innerHTML;
    }
}
