const Steps = ['welcome', 'join-meeting', 'security', 'summary'];


export const getNextStep = (currentStep: string): string => {
    const currentIndex = Steps.indexOf(currentStep);
    if (currentIndex === Steps.length - 1) {
        return '/'
    }
    return Steps[currentIndex + 1];
}

export const getPrevStep = (currentStep: string): string => {
    const currentIndex = Steps.indexOf(currentStep);
    if (currentIndex === 0) {
        return 'welcome'
    }
    return Steps[currentIndex - 1];
}
export const onNext = (currentStep: string): string => {
    return getNextStep(currentStep);
}

export const onPrev = (currentStep: string): string => {
    return getPrevStep(currentStep);
}
