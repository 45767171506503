import {Button, Dropdown, Join} from 'react-daisyui';
import {DropdownIcon, EncryptedIcon, LangIcon, MenuDotsIcon, ReloadIcon} from '../../../icons/icons.component';
import {SummaryTranslationShortComponent} from '../summary-translation/summary-translation.component';
import {TranslateFullMap} from '../../../../../constants/languages';
import React from 'react';
import {MeetingAssistComponent} from '../../meeting-assist/meeting-assist.component';
import {SummarySettingsModalComponent} from '../summary-settings/summary-settings.component';
import {SummarySettings} from '../../../../../types/summary-settings';
import {bind} from '@react-rxjs/core';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {useServices} from '../../../../../service.context';
import {UserSettings} from '../../../../../services/settings/user-settings';

const DefaultPrompt = 'Summary';

const [useIsPremium] = bind((userSettings: UserSettings) => fromPromise(userSettings.isPremium()), false);

export function SummaryMenuComponent({
                                         id,
                                         isEncrypted,
                                         promptList,
                                         selectedPrompt,
                                         selectedLng,
                                         onRegeneratePrompt,
                                         onSummaryChange,
                                         onTranslate,
                                         onQuestion,
                                     }: {
    id: string,
    isEncrypted: boolean,
    selectedPrompt: string,
    selectedLng: string,
    promptList: { key: string, prompt: string }[],
    onRegeneratePrompt: (settings?: SummarySettings) => void,
    onSummaryChange: (key: string) => void,
    onTranslate: (language: [string, string]) => void,
    onQuestion: (question: string) => void,
}) {
    const {userSettings} = useServices();
    const isPremium = useIsPremium(userSettings!);

    return (
        <Join style={{width: '100%', justifyContent: 'space-between', gap: 30}}>
            <Dropdown>
                <Dropdown.Toggle button={false}>
                    <Join className="bg-white f-black p-4 rounded-lg" role="button">
                        <span
                            className="font-bold text-2xl max-w-56 overflow-hidden whitespace-nowrap text-ellipsis">{selectedPrompt || DefaultPrompt}</span>
                        {isEncrypted && (<span className="font-bold mt-1.5"><EncryptedIcon/></span>)}
                        <span className="font-bold text-xl mt-1.5"><DropdownIcon/></span>
                    </Join>

                </Dropdown.Toggle>
                <Dropdown.Menu className="w-full min-w-96 bg-neutral flex-nowrap max-h-96 overflow-y-scroll">
                    {promptList.map((prompt, index) => (
                        <Dropdown.Item key={prompt.key}
                                       onClick={() => onSummaryChange(prompt.key)}>
                            {prompt.prompt}
                        </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>

            {isPremium && (<MeetingAssistComponent id={id!} onQuestion={onQuestion}/>)}

            <Dropdown end id="summary-menu" itemID="some-other">
                <Button tag="label" tabIndex={0} color="ghost" className="avatar" shape="circle">
                    <MenuDotsIcon/>
                </Button>
                <Dropdown.Menu className="w-64 menu-sm mt-3 z-[1] p-2 bg-neutral flex-nowrap">
                    <Dropdown.Item onClick={() => onRegeneratePrompt()} className="p-3" key="regenerate">
                        <ReloadIcon/> Regenerate
                    </Dropdown.Item>
                    <Dropdown.Item className="p-3" key="change-language">
                        <details className="max-h-80 overflow-scroll flex-nowrap">
                            <summary>
                                <Join horizontal><LangIcon/>Change language</Join>

                            </summary>

                            <SummaryTranslationShortComponent selectedLng={TranslateFullMap[selectedLng]}
                                                              onLngSelected={onTranslate}/>
                        </details>
                    </Dropdown.Item>
                    {isPremium && (<SummarySettingsModalComponent onRegeneratePrompt={onRegeneratePrompt}/>)}
                </Dropdown.Menu>
            </Dropdown>
        </Join>
    )
}
