import React, { useEffect, useState } from 'react';
import {getChangeLog} from '../../../services/changelog';
import {useServices} from '../../../service.context';
import {Loading} from 'react-daisyui';

interface Change {
    type: 'New' | 'Improvement' | 'Fix';
    description: string;
    subChanges?: Change[];
}

interface ChangelogEntry {
    version: string;
    date: string;
    changes: Change[];
}

const ChangeItem: React.FC<{ change: Change }> = ({ change }) => {
    return (
        <li className="flex flex-col">
            <div className="flex items-center">
        <span
            className={`mr-2 px-2 py-1 rounded-full text-xs font-bold ${
                change.type === 'New'
                    ? 'bg-green-500'
                    : change.type === 'Improvement'
                        ? 'bg-blue-500'
                        : 'bg-red-500'
            }`}
        >
          {change.type}
        </span>
                <p className="text-gray-300">{change.description}</p>
            </div>
            {change.subChanges && (
                <ul className="pl-8 mt-1 space-y-1">
                    {change.subChanges.map((subChange, index) => (
                        <ChangeItem key={index} change={subChange} />
                    ))}
                </ul>
            )}
        </li>
    );
};

export const ChangelogPage = () => {
    const {getToken} = useServices();
    const [changelogData, setChangelogData] = useState<ChangelogEntry[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchChangelog = async () => {
            try {
                setLoading(true);
                const data = await getChangeLog(getToken());
                setChangelogData(data);
            } catch (err) {
                setError((err as Error).message || 'Unknown error');
            } finally {
                setLoading(false);
            }
        };

        fetchChangelog();
    }, []);

    if (loading) {
        return (
            <div className="h-screen w-screen flex justify-center items-center">
                <Loading variant="dots"/>
            </div>
        );
    }

    if (error) {
        return (
            <div className="h-screen w-screen flex justify-center items-center">
                <p className="text-red-500">Error: try again later</p>
            </div>
        );
    }

    return (
        <div className="min-h-screen max-h-screen overflow-scroll text-white p-8 font-sans">
            <div className="max-w-4xl mx-auto">
                <h1 className="text-4xl font-bold mb-8">Changelog</h1>
                <p className="text-gray-400 mb-6">
                    Stay updated with the latest features, improvements, and bug fixes.
                </p>

                {changelogData.map((log, index) => (
                    <div key={index} className="mb-8">
                        <h2 className="text-2xl font-semibold text-primary mb-2">
                            {log.version} <span className="text-gray-400 text-sm">({log.date})</span>
                        </h2>
                        <ul className="space-y-2">
                            {log.changes.map((change, idx) => (
                                <ChangeItem key={idx} change={change} />
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
    );
};
