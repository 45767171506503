import React from 'react';
import type {SVGProps} from 'react';
import './icons.component.scss';

export function MeetingIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 48 48" {...props}>
        <g fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth={4}>
            <path strokeLinejoin="round" d="M44 8H4v30h15l5 5l5-5h15z"></path>
            <path d="M12 19v8m8-11v14m8-9v4m8-6v8"></path>
        </g>
    </svg>);
}

export function DashboardIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 36 36" {...props}>
        <path fill="white" d="m25.18 12.32l-5.91 5.81a3 3 0 1 0 1.41 1.42l5.92-5.81Z"
              className="clr-i-outline clr-i-outline-path-1"></path>
        <path fill="white"
              d="M18 4.25A16.49 16.49 0 0 0 5.4 31.4l.3.35h24.6l.3-.35A16.49 16.49 0 0 0 18 4.25m11.34 25.5H6.66a14.43 14.43 0 0 1-3.11-7.84H7v-2H3.55A14.4 14.4 0 0 1 7 11.29l2.45 2.45l1.41-1.41l-2.43-2.46A14.4 14.4 0 0 1 17 6.29v3.5h2V6.3a14.47 14.47 0 0 1 13.4 13.61h-3.48v2h3.53a14.43 14.43 0 0 1-3.11 7.84"
              className="clr-i-outline clr-i-outline-path-2"></path>
        <path fill="none" d="M0 0h36v36H0z"></path>
    </svg>);
}

export function SupportIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
        <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}
           color="currentColor">
            <path
                d="M17 10.805c0-.346 0-.519.052-.673c.151-.448.55-.621.95-.803c.448-.205.672-.307.895-.325c.252-.02.505.034.721.155c.286.16.486.466.69.714c.943 1.146 1.415 1.719 1.587 2.35c.14.51.14 1.044 0 1.553c-.251.922-1.046 1.694-1.635 2.41c-.301.365-.452.548-.642.655a1.27 1.27 0 0 1-.721.155c-.223-.018-.447-.12-.896-.325c-.4-.182-.798-.355-.949-.803c-.052-.154-.052-.327-.052-.673zm-10 0c0-.436-.012-.827-.364-1.133c-.128-.111-.298-.188-.637-.343c-.449-.204-.673-.307-.896-.325c-.667-.054-1.026.402-1.41.87c-.944 1.145-1.416 1.718-1.589 2.35a2.94 2.94 0 0 0 0 1.553c.252.921 1.048 1.694 1.636 2.409c.371.45.726.861 1.363.81c.223-.018.447-.12.896-.325c.34-.154.509-.232.637-.343c.352-.306.364-.697.364-1.132z"></path>
            <path d="M5 9c0-3.314 3.134-6 7-6s7 2.686 7 6m0 8v.8c0 1.767-1.79 3.2-4 3.2h-2"></path>
        </g>
    </svg>);
}

export function LogoutIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
        <path fill="currentColor"
              d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h7v2H5v14h7v2zm11-4l-1.375-1.45l2.55-2.55H9v-2h8.175l-2.55-2.55L16 7l5 5z"></path>
    </svg>);
}


export function DeleteIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
        <g fill="none">
            <path
                d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
            <path fill="currentColor"
                  d="M20 5a1 1 0 1 1 0 2h-1l-.003.071l-.933 13.071A2 2 0 0 1 16.069 22H7.93a2 2 0 0 1-1.995-1.858l-.933-13.07L5 7H4a1 1 0 0 1 0-2zm-3.003 2H7.003l.928 13h8.138zM14 2a1 1 0 1 1 0 2h-4a1 1 0 0 1 0-2z"></path>
        </g>
    </svg>);
}


export function MarkIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 512 512" {...props}>
        <path fill="#996900"
              d="M410.9 0H85.1C72.3 0 61.8 10.4 61.8 23.3V512L248 325.8L434.2 512V23.3c0-12.9-10.4-23.3-23.3-23.3"></path>
    </svg>);
}

export function ImportantMark(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 32 32" {...props}>
        <path fill="#006607"
              d="M10 8a6 6 0 0 1 12 0c0 3.523-1.986 8.536-3.16 11.19C18.346 20.31 17.227 21 16 21s-2.345-.69-2.84-1.81C11.985 16.536 10 11.522 10 8m6 22a3.5 3.5 0 1 0 0-7a3.5 3.5 0 0 0 0 7"></path>
    </svg>);
}

export function CommentIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
        <path fill="currentColor"
              d="M12 2A10 10 0 0 0 2 12a9.89 9.89 0 0 0 2.26 6.33l-2 2a1 1 0 0 0-.21 1.09A1 1 0 0 0 3 22h9a10 10 0 0 0 0-20m0 18H5.41l.93-.93a1 1 0 0 0 0-1.41A8 8 0 1 1 12 20m2-9h-1v-1a1 1 0 0 0-2 0v1h-1a1 1 0 0 0 0 2h1v1a1 1 0 0 0 2 0v-1h1a1 1 0 0 0 0-2"></path>
    </svg>);
}

export function FilterIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 28 28" {...props}>
        <path fill="currentColor"
              d="M17 19a1 1 0 1 1 0 2h-6a1 1 0 1 1 0-2zm4-6a1 1 0 1 1 0 2H7a1 1 0 1 1 0-2zm3-6a1 1 0 1 1 0 2H4a1 1 0 0 1 0-2z"></path>
    </svg>);
}

export function DropdownIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
        <path fill="currentColor" d="m7 10l5 5l5-5z"></path>
    </svg>);
}

export function ReloadIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512" {...props}>
        <path fill="none" stroke="currentColor" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={32}
              d="m400 148l-21.12-24.57A191.43 191.43 0 0 0 240 64C134 64 48 150 48 256s86 192 192 192a192.09 192.09 0 0 0 181.07-128"></path>
        <path fill="currentColor"
              d="M464 97.42V208a16 16 0 0 1-16 16H337.42c-14.26 0-21.4-17.23-11.32-27.31L436.69 86.1C446.77 76 464 83.16 464 97.42"></path>
    </svg>);
}

export function ArrowIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="10em" height="10em" viewBox="0 0 512 512" {...props}
                 className="icon-arrow">
        <path fill="white"
              className="icon-arrow-path"
              d="M237.13 18.283c-51.748 0-99.183 27.58-136.067 73.395c11.946-6.97 24.526-11.903 37.51-14.76a129.2 129.2 0 0 1 27.25-3c27.562-.123 55.824 8.283 82.636 24.318c70.828 42.362 132.585 137.45 153.704 275.178l-49.44 12.637l74.33 109.74l74.327-147.733l-51.84 13.25c.564-8.534.9-17.16.9-25.905c0-175.088-95.847-317.12-213.307-317.12zm-71.165 74.305c-7.924.04-15.734.9-23.375 2.582c-30.47 6.705-58.785 26.48-81.356 61.89C37.996 207.88 24.4 269.28 24.4 335.4c0 15.983 1.35 31.378 2.883 46.73c14.387-135.103 92.702-238.62 187.363-238.62c34.713 0 67.177 13.976 95.083 38.334c-21.608-29.968-45.915-52.65-70.863-67.57c-24.337-14.556-49.13-21.81-72.902-21.688z"></path>
    </svg>);
}

export function TotalIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="5.2em" height="5.2em" viewBox="0 0 2048 2048" {...props}>
        <path fill="black"
              d="M1792 384h-128V256H475l768 768l-768 768h1189v-128h128v256H256v-91l805-805l-805-805v-91h1536z"></path>
    </svg>);
}

export function GoogleMeetIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="5.2em" height="5.2em" viewBox="0 0 48 48" {...props}>
        <path fill="none" stroke="black" strokeLinecap="round" strokeLinejoin="round"
              d="M41.05 12.6c1.63-1.3 2.85-.23 2.85 1.14v20.52c0 1.73-1.22 2.44-2.85 1.14L26.79 24ZM14 8v32M4.9 17.16h21.89v13.68H4.9m0-13.68L14 8h18.5a3.2 3.2 0 0 1 2.85 2.85v26.26A3.2 3.2 0 0 1 32.5 40H7.75a2.81 2.81 0 0 1-2.85-2.89Z"></path>
    </svg>);
}

export function SevenIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="5.2em" height="5.2em" viewBox="0 0 24 24" {...props}>
        <path fill="none" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}
              d="M10.5 17c0-3.75 5-10 5-10s-3.75.625-6.25 0"></path>
    </svg>);
}

export function AIIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="5.2em" height="5.2em" viewBox="0 0 24 24" {...props}>
        <path fill={props?.fill || "black"}
              d="M21 11V9h-2V7a2.006 2.006 0 0 0-2-2h-2V3h-2v2h-2V3H9v2H7a2.006 2.006 0 0 0-2 2v2H3v2h2v2H3v2h2v2a2.006 2.006 0 0 0 2 2h2v2h2v-2h2v2h2v-2h2a2.006 2.006 0 0 0 2-2v-2h2v-2h-2v-2Zm-4 6H7V7h10Z"></path>
        <path fill={props?.fill || "black"}
              d="M11.361 8h-1.345l-2.01 8h1.027l.464-1.875h2.316L12.265 16h1.062Zm-1.729 5.324L10.65 8.95h.046l.983 4.374ZM14.244 8h1v8h-1z"></path>
    </svg>);
}

export function ClockIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="5.2em" height="5.2em" viewBox="0 0 24 24" {...props}>
        <path fill="none" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
              d="M3 12a9 9 0 1 0 18 0a9 9 0 1 0-18 0m9 0h3.5M12 7v5"></path>
    </svg>);
}

export function HoursIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="5.2em" height="5.2em" viewBox="0 0 24 24" {...props}>
        <path fill="black"
              d="M12.005 13.003a3 3 0 0 1 2.08 5.162l-1.91 1.837h2.83v2h-6l-.001-1.724l3.694-3.555a1 1 0 1 0-1.693-.72h-2a3 3 0 0 1 3-3m6 0v4h2v-4h2v9h-2v-3h-4v-6zm-14-1a7.99 7.99 0 0 0 3 6.246v2.416a10 10 0 0 1-5-8.662zm8-10c5.185 0 9.449 3.946 9.95 9h-2.012a8.001 8.001 0 0 0-14.554-3.5h2.616v2h-6v-6h2v2.499a9.99 9.99 0 0 1 8-4"></path>
    </svg>);
}


export function NextIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="2.2em" height="2.2em" viewBox="0 0 24 24">
        <path fill="none" stroke="black" strokeWidth="2" d="M2 12h20m-9-9l9 9l-9 9"/>
    </svg>);
}

export function PrevIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="2.2em" height="2.2em" viewBox="0 0 24 24">
        <path fill="none" stroke="black" strokeWidth="2" d="M22 12H2m9-9l-9 9l9 9"/>
    </svg>);
}


export function GoogleMeetColorIcon({width, height}: { width?: string, height?: string }) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width={width || '2.31em'} height={height || '2.2em'}
                 viewBox="0 0 256 211">
        <path fill="#00832d"
              d="m144.822 105.322l24.957 28.527l33.562 21.445l5.838-49.792l-5.838-48.669l-34.205 18.839z"></path>
        <path fill="#0066da"
              d="M0 150.66v42.43c0 9.688 7.864 17.554 17.554 17.554h42.43l8.786-32.059l-8.786-27.925l-29.11-8.786z"></path>
        <path fill="#e94235" d="M59.984 0L0 59.984l30.876 8.765l29.108-8.765l8.626-27.545z"></path>
        <path fill="#2684fc" d="M.001 150.679h59.983V59.983H.001z"></path>
        <path fill="#00ac47"
              d="M241.659 25.398L203.34 56.834v98.46l38.477 31.558c5.76 4.512 14.186.4 14.186-6.922V32.18c0-7.403-8.627-11.495-14.345-6.781"></path>
        <path fill="#00ac47"
              d="M144.822 105.322v45.338H59.984v59.984h125.804c9.69 0 17.553-7.866 17.553-17.554v-37.796z"></path>
        <path fill="#ffba00"
              d="M185.788 0H59.984v59.984h84.838v45.338l58.52-48.49V17.555c0-9.69-7.864-17.554-17.554-17.554"></path>
    </svg>);
}

export function CloseIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="2.2em" height="2.2em" viewBox="0 0 24 24" {...props}>
        <path fill="black"
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"></path>
    </svg>);
}

export function ErrorIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="2.2em" height="2.2em" viewBox="0 0 24 24" {...props}>
        <path fill="black"
              d="M12 17q.425 0 .713-.288T13 16t-.288-.712T12 15t-.712.288T11 16t.288.713T12 17m-1-4h2V7h-2zm1 9q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22"></path>
    </svg>);
}

export function TranslationIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 21 21" {...props}>
        <g fill="none" fillRule="evenodd" stroke="black" strokeLinecap="round" strokeLinejoin="round">
            <path d="M18.5 10.5v-6a2 2 0 0 0-2-2h-6a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2"></path>
            <path d="M6.5 8.503h-2a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h.003l6-.01a2 2 0 0 0 1.997-2V14.5m-5-1.997h-3"></path>
            <path d="m9 14l-1 1c-.334.333-1.166.833-2.5 1.5"></path>
            <path d="M5.5 12.503c.334 1.166.834 2 1.5 2.499S8.5 16 9.5 16.5m4-12l-3 6m3-6l3 6m-1-2h-4"></path>
        </g>
    </svg>);
}

export function CryingCatIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="5.2em" height="5.2em" viewBox="0 0 128 128" {...props}>
        <path fill="#ffc022"
              d="M114.11 70.76C112.31 44.78 94.44 26.3 64 26.3S15.69 44.78 13.89 70.76c-1.05 15.14 5.05 28.01 17.09 36.21c0 0 12.21 9.88 33.02 10.14c20.81-.26 33.02-10.14 33.02-10.14c12.03-8.2 18.14-21.07 17.09-36.21"></path>
        <path fill="#ff7043"
              d="M54.12 45.02c1.13.96 3.42.82 4.75-.72c1.61-1.87 3.29-8.17 2.24-17.91c-4.67.17-9.09.84-13.21 1.97c3.33 5.46 4.13 14.88 6.22 16.66m19.76 0c-1.13.96-3.42.82-4.75-.72c-1.61-1.87-3.29-8.17-2.24-17.91c4.67.17 9.09.84 13.21 1.97c-3.33 5.46-4.13 14.88-6.22 16.66"
              opacity={0.47}></path>
        <path
            d="M55.96 74.54c-.05-2.86 4.06-4.24 7.95-4.3s8.07 1.2 8.12 4.06s-4.86 6.64-7.95 6.64c-3.08 0-8.07-3.54-8.12-6.4"></path>
        <path fill="none" stroke="#9e9e9e" strokeLinecap="round" strokeMiterlimit={10} strokeWidth={3}
              d="M4.12 74.76s6.65-5.17 17.94-5.07M5.58 89.09s4.63-10.06 17.75-12.32m-8.29 21.52s3.31-10.47 10.24-14.73m98.6-8.8s-6.65-5.17-17.94-5.07m16.48 19.4s-4.63-10.06-17.75-12.32m8.29 21.52s-3.31-10.47-10.24-14.73"></path>
        <path
            d="M33.78 61.77c.33-1.63 1.45-3.65 3.63-4.37c3.75-1.25 6.53-2.5 7.94-3.18c.58-.28 1.26.02 1.45.63c.36 1.17.88 3.13 1.13 5.49c.57 5.25-3.06 8.44-6.99 8.68c-4.6.28-7.88-3.65-7.16-7.25m60.44 0c-.33-1.63-1.45-3.65-3.63-4.37c-3.75-1.25-6.53-2.5-7.94-3.18a1.04 1.04 0 0 0-1.45.63c-.36 1.17-.88 3.13-1.13 5.49c-.57 5.25 3.06 8.44 6.99 8.68c4.6.28 7.88-3.65 7.16-7.25M65.98 83.98v-4.45h-4v4.44c0 2.41-1.29 4.59-3.31 5.91c-4.03 2.63-6.07 4.8-7.95 7.01c-1.18 1.38-.67 3.51 1.01 4.2c1.23.51 2.65.05 3.32-1.11c1.76-3.05 5.07-7.3 8.94-7.3c3.76 0 7 4.03 8.79 7.06a2.67 2.67 0 0 0 3.58.98l.18-.1c1.52-.84 1.85-2.88.67-4.16c-1.85-2.01-4.71-4.39-7.9-6.58c-1.99-1.36-3.33-3.49-3.33-5.9"></path>
        <path fill="#28b3f2"
              d="M34.78 73.43a1.76 1.76 0 0 1 3.18.01c2.21 4.59 6.11 13.13 7.45 19.02c2.01 8.81-2.13 17.11-9.19 16.95c-8.86-.19-12.26-10.61-8.85-19.71c1.67-4.43 5.31-11.99 7.41-16.27"></path>
        <path fill="#0277bd"
              d="M40.15 107.38c-8.86.59-12.97-9.19-10.19-18.3c1.35-4.43 4.47-12.07 6.27-16.39c.04-.1.1-.17.15-.26c-.63 0-1.27.33-1.6 1c-2.1 4.28-5.74 11.84-7.4 16.27c-3.41 9.1-.01 19.52 8.85 19.71c2.39.05 4.44-.88 6.03-2.46a8.5 8.5 0 0 1-2.11.43"></path>
        <path fill="#81d4fa"
              d="M39.27 85.81c2.04-.03 3.51 4.44 3.77 9.56c.31 6.08-4.78 7.54-6.05 1.85c-.93-4.15.19-11.38 2.28-11.41"></path>
        <path fill="#ffc022"
              d="M19.91 58.62C5.73 40.06 4.92 22.48 6.11 16.64c.51-2.49 2.71-3.93 5.17-4.05c5.59-.26 20.9 4.06 34.85 16.48z"></path>
        <path fill="#ffd1d1"
              d="M15.22 23.77a1 1 0 0 0-1.29.97c.06 5.09.64 11.37 6.85 20.03c.95 1.33 2.08 3.09 4.77.13c1.49-1.64 5.92-6.36 5.92-6.36c1.19-1.07 3.61-2.94 1.86-4.58c-.08-.07-6.62-6.66-18.11-10.19"></path>
        <path fill="#ffc022"
              d="M108.09 58.62c14.18-18.56 14.99-36.15 13.8-41.98c-.51-2.49-2.71-3.93-5.17-4.05c-5.59-.26-20.9 4.06-34.85 16.48z"></path>
        <path fill="#ffd1d1"
              d="M112.78 23.77a1 1 0 0 1 1.29.97c-.06 5.09-.64 11.37-6.85 20.03c-.95 1.33-2.08 3.09-4.77.13c-1.49-1.64-5.92-6.36-5.92-6.36c-1.19-1.07-3.61-2.94-1.86-4.58c.08-.07 6.62-6.66 18.11-10.19"></path>
    </svg>);
}

export function CryingCat2Icon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="5.2em" height="5.2em" viewBox="0 0 32 32" {...props}>
        <g fill="none">
            <path fill="#ffb02e"
                  d="M4 25.942C4 28.174 5.763 30 7.918 30h16.164C26.237 30 28 28.073 28 25.84V6.43c0-1.3-1.59-1.9-2.485-1L20.975 10h-9.812L6.5 5.43c-.9-.9-2.5-.3-2.5 1z"></path>
            <path fill="#ff822d"
                  d="m9 10.927l-2.8 2.6c-.5.5-1.4.1-1.4-.6v-5.2c0-.6.9-1 1.4-.5l2.8 2.6c.3.3.3.8 0 1.1m14.05 0l2.8 2.6c.5.5 1.4.1 1.4-.6v-5.2c0-.6-.9-1-1.4-.5l-2.8 2.6c-.3.3-.3.8 0 1.1"></path>
            <path fill="#ff6723"
                  d="M2.724 20.053a.5.5 0 1 0-.448.894l4 2a.5.5 0 1 0 .448-.894zm0 6.894a.5.5 0 1 1-.448-.894l4-2a.5.5 0 1 1 .448.894zm27.223-6.671a.5.5 0 0 0-.67-.223l-4 2a.5.5 0 1 0 .447.894l4-2a.5.5 0 0 0 .223-.67m-.67 6.67a.5.5 0 1 0 .447-.894l-4-2a.5.5 0 1 0-.448.894z"></path>
            <path fill="#402a32"
                  d="M12.621 17.015a.5.5 0 0 1 .364.606c-.146.584-.412 1.025-.77 1.33c-.356.304-.77.444-1.165.484c-.392.04-.774-.02-1.095-.123c-.311-.101-.61-.26-.809-.459a.5.5 0 1 1 .708-.707c.05.051.19.143.41.214c.21.068.453.103.686.08c.23-.023.441-.1.616-.25c.174-.148.345-.395.449-.811a.5.5 0 0 1 .606-.364M16 24.5c-1.39 0-2.317.877-2.584 1.277a.5.5 0 1 1-.832-.554c.362-.543 1.384-1.514 2.916-1.694V21a.5.5 0 0 1 1 0v2.53c1.532.179 2.554 1.15 2.916 1.693a.5.5 0 1 1-.832.554c-.267-.4-1.194-1.277-2.584-1.277m3.015-6.879a.5.5 0 0 1 .97-.242c.104.416.275.663.449.81c.175.15.386.228.616.251c.233.023.476-.012.687-.08c.22-.07.359-.163.41-.214a.5.5 0 0 1 .707.707c-.2.2-.498.358-.81.459c-.32.103-.702.162-1.094.123a2.1 2.1 0 0 1-1.165-.484c-.358-.305-.624-.746-.77-1.33"></path>
            <path fill="#f70a8d"
                  d="M17.043 20h-2.086a.5.5 0 0 0-.353.854l1.043 1.042a.5.5 0 0 0 .707 0l1.043-1.042a.5.5 0 0 0-.354-.854"></path>
            <path fill="#5092ff" d="M8 23a2 2 0 1 1 4 0v3a2 2 0 1 1-4 0z"></path>
        </g>
    </svg>);
}

export function CryingCat3Icon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="5.2em" height="5.2em" viewBox="0 0 128 128" {...props}>
        <ellipse cx={97.65} cy={94.35} fill="#fff" rx={7.71} ry={12.37}></ellipse>
        <path fill="#2f2f2f" d="m26.25 68.72l72.233-6.008l1.592 19.134l-72.233 6.009z"></path>
        <path fill="#f79329"
              d="m125.09 92.24l-9.49-1.71c.34-1.72.55-3.48.67-5.26l8.95-1.33a2.13 2.13 0 0 0 1.8-2.42c-.17-1.16-1.25-1.94-2.43-1.79l-8.22 1.22c-.02-1-.05-2-.12-3c-.25-3.72-1.08-7.47-2.41-11.11c2.94-8.31 12.13-36.37 5.13-43.87c-5.34-5.72-24.06 6.53-30.24 11.07c-2.23 1.62-4.14 3.01-5.79 4.18c-6.16-1.86-12.84-2.83-19.46-2.83c-6.73-.01-13.52 1-19.77 2.92c-1.92-1.12-4.22-2.49-6.94-4.14c-6.52-3.97-26.23-14.57-31.06-8.41c-5.72 7.31 3.2 30.22 7.65 40.48c-1.46 3.83-2.37 7.78-2.63 11.71c-.07.95-.1 1.9-.12 2.84l-7.2-1.07c-1.16-.15-2.25.63-2.42 1.79c-.17 1.17.63 2.26 1.8 2.42l7.92 1.18c.12 1.88.35 3.75.71 5.58l-8.5 1.53A2.134 2.134 0 0 0 1.2 94.7a2.14 2.14 0 0 0 2.1 1.75c.12 0 .25-.01.38-.03l8.84-1.59c.65 1.97 1.51 3.88 2.58 5.71l-8.45 2.68a2.14 2.14 0 0 0-1.39 2.68c.29.91 1.13 1.48 2.03 1.48c.21 0 .43-.03.65-.1l9.69-3.08a31 31 0 0 0 5.63 5.38c6.05 4.52 13.57 7.57 21.33 9.23c2.95.63 10.56 2.1 18.55 2.1s16.35-1.47 19.3-2.1c7.75-1.66 15.28-4.71 21.33-9.23c2.31-1.73 4.23-3.62 5.82-5.64l10.5 3.33c.21.07.43.1.65.1c.9 0 1.74-.57 2.03-1.48c.35-1.12-.27-2.33-1.39-2.68l-9.31-2.96c1.02-1.8 1.84-3.68 2.47-5.61l9.8 1.77a2.15 2.15 0 0 0 2.48-1.72c.2-1.13-.57-2.24-1.73-2.45M51.88 74.3c-.1.28-2.39 6.66-9.95 8.13c-.97.19-1.88.28-2.74.28c-5.91 0-9.38-3.88-9.54-4.06c-.78-.89-.69-2.23.2-3.02c.88-.77 2.23-.68 3.01.2c.12.12 3.15 3.41 8.25 2.42c5.1-1 6.68-5.18 6.75-5.37c.4-1.1 1.62-1.67 2.72-1.28s1.68 1.59 1.3 2.7m32.38 7.59c-7.55-1.53-9.81-7.93-9.9-8.19c-.38-1.12.22-2.33 1.33-2.71s2.32.21 2.7 1.32c.07.2 1.64 4.38 6.71 5.4c5.09 1.02 8.16-2.24 8.29-2.38c.79-.87 2.14-.93 3.01-.15s.95 2.12.17 2.99c-.17.18-3.63 4-9.49 4c-.89 0-1.83-.08-2.82-.28m20.02 15.79c-.8 3.68-4.72 6.82-8.37 4.51c-2.57-1.62-2.73-4.39-2.21-7.26c.45-2.52 1.42-5.12 1.73-7.08c.1-.62.07-1.3.09-1.92c.02-.83-.14-2.85 1.06-2.94c.84-.07 1.75.72 2.39 1.17c1.55 1.13 2.76 3.13 3.73 4.77c1.55 2.64 2.23 5.71 1.58 8.75"></path>
    </svg>);
}


export function SettingsIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24" {...props}>
        <g fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}>
            <path
                d="M12.501 20.93c-.866.25-1.914-.166-2.176-1.247a1.724 1.724 0 0 0-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066-2.573c-.94-1.543.826-3.31 2.37-2.37c1 .608 2.296.07 2.572-1.065c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.074.26 1.49 1.296 1.252 2.158M19 22v-6m3 3l-3-3l-3 3"></path>
            <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0-6 0"></path>
        </g>
    </svg>);
}


export function QuestionIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 256 256" {...props}>
        <path fill="white"
              d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24m0 168a12 12 0 1 1 12-12a12 12 0 0 1-12 12m8-48.72v.72a8 8 0 0 1-16 0v-8a8 8 0 0 1 8-8c13.23 0 24-9 24-20s-10.77-20-24-20s-24 9-24 20v4a8 8 0 0 1-16 0v-4c0-19.85 17.94-36 40-36s40 16.15 40 36c0 17.38-13.76 31.93-32 35.28"></path>
    </svg>);
}

export function AIFixIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24" {...props}>
        <path fill="white"
              d="M7.5 5.6L10 7L8.6 4.5L10 2L7.5 3.4L5 2l1.4 2.5L5 7zm12 9.8L17 14l1.4 2.5L17 19l2.5-1.4L22 19l-1.4-2.5L22 14zM22 2l-2.5 1.4L17 2l1.4 2.5L17 7l2.5-1.4L22 7l-1.4-2.5zm-7.63 5.29a.996.996 0 0 0-1.41 0L1.29 18.96a.996.996 0 0 0 0 1.41l2.34 2.34c.39.39 1.02.39 1.41 0L16.7 11.05a.996.996 0 0 0 0-1.41zm-1.03 5.49l-2.12-2.12l2.44-2.44l2.12 2.12z"></path>
    </svg>);
}

export function EncryptedIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24" {...props}>
        <path fill="green"
              d="M10.5 15h3l-.575-3.225q.5-.25.788-.725T14 10q0-.825-.587-1.412T12 8t-1.412.588T10 10q0 .575.288 1.05t.787.725zm1.5 7q-3.475-.875-5.738-3.988T4 11.1V5l8-3l8 3v6.1q0 3.8-2.262 6.913T12 22"></path>
    </svg>);
}

export function MenuDotsIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 512 512" {...props}>
        <path fill="white" fillRule="evenodd"
              d="M256 117.333c17.673 0 32-14.327 32-32s-14.327-32-32-32s-32 14.327-32 32s14.327 32 32 32m0 341.333c17.673 0 32-14.327 32-32s-14.327-32-32-32s-32 14.327-32 32s14.327 32 32 32M256 288c17.673 0 32-14.327 32-32s-14.327-32-32-32s-32 14.327-32 32s14.327 32 32 32"></path>
    </svg>);
}

export function LangIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 20 20" {...props}>
        <g fill="white">
            <path
                d="M7.75 2.75a.75.75 0 0 0-1.5 0v1.258a33 33 0 0 0-3.599.278a.75.75 0 1 0 .198 1.487A31.6 31.6 0 0 1 8.7 5.545A19.4 19.4 0 0 1 7 9.56a19 19 0 0 1-1.002-2.05a.75.75 0 0 0-1.384.577a21 21 0 0 0 1.492 2.91a19.6 19.6 0 0 1-3.828 4.154a.75.75 0 1 0 .945 1.164A21 21 0 0 0 7 12.331q.143.198.29.391a.75.75 0 0 0 1.194-.91q-.306-.4-.59-.815a21 21 0 0 0 2.333-5.332q.465.047.924.108a.75.75 0 0 0 .198-1.487a33 33 0 0 0-3.599-.278z"></path>
            <path fillRule="evenodd"
                  d="M13 8a.75.75 0 0 1 .671.415l4.25 8.5a.75.75 0 1 1-1.342.67L15.787 16h-5.573l-.793 1.585a.75.75 0 1 1-1.342-.67l4.25-8.5A.75.75 0 0 1 13 8m2.037 6.5L13 10.427L10.964 14.5z"
                  clipRule="evenodd"></path>
        </g>
    </svg>);
}

export function FormatIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 16 16" {...props}>
        <path fill="none" stroke="white" strokeLinejoin="round"
              d="M6 13v-2.5M8 13V9.5m2 3.5v-2.5m-7-3h10m.5-2v8h-11v-8h4v-3h3v3z"></path>
    </svg>);
}

export function InfoIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" fill="none" viewBox="0 0 24 24"
             className="w-4 h-4 stroke-current">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" stroke="white"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
        </svg>
    )
}

export function ToastInfoIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 1024 1024">
            <path fill="currentColor"
                  d="M512 64a448 448 0 1 1 0 896.064A448 448 0 0 1 512 64m67.2 275.072c33.28 0 60.288-23.104 60.288-57.344s-27.072-57.344-60.288-57.344c-33.28 0-60.16 23.104-60.16 57.344s26.88 57.344 60.16 57.344M590.912 699.2c0-6.848 2.368-24.64 1.024-34.752l-52.608 60.544c-10.88 11.456-24.512 19.392-30.912 17.28a12.99 12.99 0 0 1-8.256-14.72l87.68-276.992c7.168-35.136-12.544-67.2-54.336-71.296c-44.096 0-108.992 44.736-148.48 101.504c0 6.784-1.28 23.68.064 33.792l52.544-60.608c10.88-11.328 23.552-19.328 29.952-17.152a12.8 12.8 0 0 1 7.808 16.128L388.48 728.576c-10.048 32.256 8.96 63.872 55.04 71.04c67.84 0 107.904-43.648 147.456-100.416z"></path>
        </svg>
    )
}

export function CheckIcon(props: SVGProps<SVGSVGElement>) {
    return <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24" {...props}>
        <path fill="currentColor" fillRule="evenodd"
              d="M12 21a9 9 0 1 0 0-18a9 9 0 0 0 0 18m-.232-5.36l5-6l-1.536-1.28l-4.3 5.159l-2.225-2.226l-1.414 1.414l3 3l.774.774z"
              clipRule="evenodd"></path>
    </svg>
}

export function CrossIcon(props: SVGProps<SVGSVGElement>) {
    return <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 512 512" {...props}>
        <path fill="currentColor" fillRule="evenodd"
              d="M256 64c106.039 0 192 85.961 192 192s-85.961 192-192 192S64 362.039 64 256S149.961 64 256 64m81.018 80.824l-81.006 81.006l-80.855-80.855l-30.17 30.17L225.842 256l-80.855 80.855l30.17 30.17l80.855-80.855l81.006 81.006l30.17-30.17L286.182 256l81.006-81.006z"></path>
    </svg>
}

export function ChangelogIcon(props: SVGProps<SVGSVGElement>) {
    return <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 48 48" {...props}>
        <g fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth={4}>
            <path d="M13 10h28v34H13z"></path>
            <path strokeLinecap="round" d="M35 10V4H8a1 1 0 0 0-1 1v33h6m8-16h12m-12 8h12"></path>
        </g>
    </svg>
}

export function FeedbackIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
        <path fill="currentColor"
              d="M12 15q.425 0 .713-.288T13 14t-.288-.712T12 13t-.712.288T11 14t.288.713T12 15m-1-4h2V5h-2zM2 22V2h20v16H6zm3.15-6H20V4H4v13.125zM4 16V4z"></path>
    </svg>);
}

export function TextIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 256 256" {...props}>
        <path fill="currentColor"
              d="M200 156c0 6.5-7.33 12-16 12s-16-5.5-16-12s7.33-12 16-12s16 5.5 16 12m32-100v144a16 16 0 0 1-16 16H40a16 16 0 0 1-16-16V56a16 16 0 0 1 16-16h176a16 16 0 0 1 16 16m-88.63 116.88l-44-104a8 8 0 0 0-14.74 0l-44 104a8 8 0 0 0 14.74 6.24L66.84 152h50.32l11.47 27.12a8 8 0 0 0 14.74-6.24M216 124c0-15.44-14.36-28-32-28a34.86 34.86 0 0 0-20.78 6.68a8 8 0 0 0 9.56 12.83A18.84 18.84 0 0 1 184 112c8.56 0 15.8 5.36 16 11.76v8a35.24 35.24 0 0 0-16-3.76c-17.64 0-32 12.56-32 28s14.36 28 32 28a35.1 35.1 0 0 0 16.93-4.26A8 8 0 0 0 216 176ZM73.61 136h36.78L92 92.53Z"></path>
    </svg>);
}

export function YoutubeIcon(props: SVGProps<SVGSVGElement>) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
        <path fill="currentColor"
              d="m10 15l5.19-3L10 9zm11.56-7.83c.13.47.22 1.1.28 1.9c.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83c-.25.9-.83 1.48-1.73 1.73c-.47.13-1.33.22-2.65.28c-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44c-.9-.25-1.48-.83-1.73-1.73c-.13-.47-.22-1.1-.28-1.9c-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83c.25-.9.83-1.48 1.73-1.73c.47-.13 1.33-.22 2.65-.28c1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44c.9.25 1.48.83 1.73 1.73"></path>
    </svg>);
}
