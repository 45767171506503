export enum PromptTypes {
    Summary = 'summary',
    Goal = 'goal',
    Topic = 'topic',
    Purpose = 'purpose',
    Decision = 'decision',
    Action = 'action',
    Next = 'next',
    Issue = 'issue',
    Missing = 'missing',
    Sentiment = 'sentiment',
    Custom = 'custom'
}
