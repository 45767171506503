import {Button, Dropdown, Join, Menu, Textarea} from 'react-daisyui';
import React, {useState} from 'react';
import {createSignal} from '@react-rxjs/utils';
import {bind} from '@react-rxjs/core';
import './meeting-assist.component.scss';

const MIN_QUESTION_LENGTH = 6;

const [meetingAssistOpened, onMeetingAssistOpened] = createSignal<boolean>();
const [loadingQuestion, onLoadingQuestion] = createSignal<boolean>();

const [useMeetingAssistOpened] = bind(meetingAssistOpened, false);
const [useLoadingQuestion] = bind(loadingQuestion, false);

export function MeetingAssistComponent({id, onQuestion}: { id: string, onQuestion: (answer: string) => void }) {
    const isMeetingAssistOpened = useMeetingAssistOpened();
    const isLoadingQuestion = useLoadingQuestion();

    const [question, setQuestion] = useState<string>('');

    const handleCloseAssist = () => {
        onMeetingAssistOpened(false);
        // @ts-ignore
        document.activeElement?.blur();
    }

    const askQuestion = async () => {
        onQuestion(question);
        setQuestion('');
        handleCloseAssist();
    }

    return (
        <div className="meeting-assist-wrapper">
            <Dropdown vertical="bottom" horizontal="left" className="text-white" open={isMeetingAssistOpened}>
                <Button variant="outline" key="question" onClick={() => () => handleCloseAssist()}
                        disabled={isLoadingQuestion}
                        title="Ask a question about the meeting">
                    <span className={"gold-2"}>Ask assistant?</span>
                </Button>
                <Dropdown.Menu
                    className="z-50 card card-compact p-2 shadow bg-neutral text-primary-content m-1 border text-white meeting-assist min-w-96 h-52">
                    <Menu>
                        <Menu.Item className="text-white" disabled={isLoadingQuestion}>
                            <div className="font-bold question-label">Ask any question about the meeting</div>
                            <Textarea
                                placeholder="Your question"
                                className={"w-full bg-neutral text-white"}
                                disabled={isLoadingQuestion}
                                size="sm"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                color={"primary"}
                            />

                        </Menu.Item>
                    </Menu>
                    <Join horizontal style={{width: '100%', justifyContent: 'space-around'}}>

                        <Button color="ghost" className="action-button text-white"
                                disabled={isLoadingQuestion}
                                onClick={() => handleCloseAssist()}>Cancel</Button>

                        <Button color="primary" className="action-button text-white"
                                disabled={question.length < MIN_QUESTION_LENGTH || isLoadingQuestion}
                                onClick={() => askQuestion()}>Ask</Button>
                    </Join>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}
