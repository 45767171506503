import {useEffect, useState} from 'react';
import {Divider} from 'react-daisyui';
import Subscription from './subscription';
import PremiumPage from './premium.page';

export function AccountSettingsComponent({isPremium, premiumUntil}: { isPremium?: boolean, premiumUntil?: number }) {
    const [settings, setSettings] = useState<{
        isPremium: boolean,
        premiumUntil: number
    }>({isPremium: isPremium || false, premiumUntil: premiumUntil || 0});

    useEffect(() => {
        const until = (premiumUntil || 0) * 1000;
        const isPremiumFull = (isPremium && until - new Date().getTime() > 0) || false;
        setSettings({isPremium: isPremiumFull || false, premiumUntil: until || 0});
    }, [isPremium, premiumUntil]);



    if (!settings.isPremium) {
        return (
            <div className="max-w-3/4">
                <div className="font-white">
                    <h2 className="font-bold text-2xl">Account settings</h2>
                    <Subscription/>
                    <Divider/>

                </div>
            </div>
        )
    }

    return <PremiumPage isPremium={settings.isPremium} premiumUntil={settings.premiumUntil}/>
}
