import React from 'react';
import {bind} from '@react-rxjs/core';
import {Loading, Tabs} from 'react-daisyui';
import {createSignal} from '@react-rxjs/utils';
import {debounceTime, of, startWith, switchMap, tap} from 'rxjs';
import {SettingsProps} from '../../../types/user-settings.type';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {MBoxDebounceTimeMS} from '../../../constants';
import {GeneralSettingsComponent} from './general/general.settings.component';
import {SummarySettingsComponent} from './summary/summary.settings.component';
import {AccountSettingsComponent} from './account/account-settings.component';
import {useServices} from '../../../service.context';
import {UserSettings} from '../../../services/settings/user-settings';


const [settings, onSettings] = createSignal<SettingsProps>();
const [isLoading, onIsLoading] = createSignal<boolean>();
const [disableFields, onDisableFields] = createSignal<boolean>();

const [useSettingsState] = bind((userSettings: UserSettings) => settings.pipe(
        startWith(undefined),
        debounceTime(MBoxDebounceTimeMS),
        switchMap((s) => {
            onIsLoading(true)
            if (s) {
                onIsLoading(false)
                return of(s);
            }

            return fromPromise(userSettings.getSettings()).pipe(tap(() => onIsLoading(false)));
        })
    ), null);
const [useIsLoading] = bind(isLoading, true);
const [useDisableFields] = bind(disableFields, false);

const SettingsComponent: React.FC = () => {
    const {userSettings: userSettingsService} = useServices();
    const isLoading = useIsLoading();
    const disabled = useDisableFields();
    const userSettings = useSettingsState(userSettingsService!);

    const onSaveSettings = async (settings: Partial<SettingsProps>) => {
        onDisableFields(true);
        try {
            await userSettingsService!.updateSettings({
                ...userSettings!,
                ...settings,
            });
            onSettings({...userSettings!, ...settings});
        } catch (e) {
            console.error(e);
        }
        onDisableFields(false);
    }

    if (isLoading) {
        return <Loading variant="dots"/>
    }


    return (
        <Tabs className="my-10 lg:mx-10" variant="lifted" size="lg" dataTheme="dark">
            <Tabs.RadioTab name="settings" label="General"
                           defaultChecked={true}
                           contentClassName="bg-base-100 border-base-300 rounded-box p-6" disabled={isLoading}>
                <GeneralSettingsComponent sendNoSummary={userSettings!.sendNoSummary}
                                          sendSummary={userSettings!.sendSummary}
                                          redirectEmails={userSettings!.redirectEmails}
                                          whiteEmailList={userSettings!.whiteEmailList}
                                          disableFields={disabled}
                                          onSave={onSaveSettings}
                />
            </Tabs.RadioTab>
            <Tabs.RadioTab name="settings" label="Summary"
                           disabled={isLoading}
                           contentClassName="bg-base-100 border-base-300 rounded-box p-6">
                <SummarySettingsComponent
                    depth={userSettings!.defaultSummaryDepth}
                    maxLength={userSettings!.defaultMaxSummaryLength}
                    minLength={userSettings!.defaultMinSummaryLength}
                    translateTo={userSettings?.defaultTranslateTo}
                    disableFields={disabled}
                    onSave={onSaveSettings}
                />
            </Tabs.RadioTab>
            <Tabs.RadioTab name="settings" label="Account"
                           disabled={isLoading}
                           contentClassName="bg-base-100 border-base-300 rounded-box p-6">
                <AccountSettingsComponent isPremium={userSettings?.isPremium} premiumUntil={userSettings?.premiumUntil}/>
            </Tabs.RadioTab>

            {/*<Tabs.RadioTab name="settings" label="Referral"*/}
            {/*               disabled={isLoading}*/}
            {/*               contentClassName="bg-base-100 border-base-300 rounded-box p-6">*/}
            {/*    <ReferralComponent />*/}
            {/*</Tabs.RadioTab>*/}
        </Tabs>

    )
};

export default SettingsComponent;
