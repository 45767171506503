import './App.scss';
import 'tailwindcss/tailwind.css';

import React, {Suspense} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Loading} from 'react-daisyui';
import Layout from './components/v2/layout';
import GuardedRoute from './components/guarded-route/guarded-route';
import RestrictedForAuthUsersRoute from './components/guarded-route/restrict-auth';
import LoginComponent from './components/v2/login/login.component';
import MeetingListComponent from './components/v2/meeting-list/meeting-list.component';
import DashboardRouteComponent from './components/v2/dashboard/dashboard-route.component';
import SettingsComponent from './components/v2/settings/settings.component';
import OnboardingComponent from './components/v2/onboarding/onboarding.component';
import {PaymentFailed, PaymentSucceeded, SubscribePage} from './components/v2/payment/payment.succeeded';
import UninstallComponent from './components/v2/uninstall/uninstall.component';
import {ServiceProvider, useServices} from './service.context';
import {ChangelogPage} from './components/v2/changelog/changelog.component';
import PublicLayout from './components/public/public.layout';
import {PublicSummarization} from './components/public/summarization/summarizatoin';
import {PublicSummarizationYoutube} from './components/public/youtube/youtube';
import {PublicIntro} from './components/public/intro/public.intro';

function AppRoutes() {
    const {user, isReady, isOnboarded, setOnboarded} = useServices();

    if (!isReady) {
        return <div className="h-screen w-screen flex justify-center"><Loading variant="dots"/></div>;
    }

    return (
        <Suspense fallback={<div className="w-screen h-screen flex justify-center"><Loading variant="dots"/></div>}>
            <Router>
                <Routes>
                    <Route element={
                        <GuardedRoute user={user} onboardingCompleted={isOnboarded}/>
                    }>
                        <Route path="/" element={<Layout element={<MeetingListComponent/>}/>}/>
                        <Route path="/dashboard" element={<Layout element={<DashboardRouteComponent/>}/>}/>
                        <Route path="/settings" element={<Layout element={<SettingsComponent/>}/>}/>


                        <Route path="/:id" element={<Layout element={<MeetingListComponent/>}/>}/>

                        <Route path="/onboarding"
                               element={<OnboardingComponent onboardCompletedFn={() => setOnboarded(true)}/>}/>
                        <Route path="/onboarding/:step"
                               element={<OnboardingComponent onboardCompletedFn={() => setOnboarded(true)}/>}/>
                        <Route path="/tools" element={<Layout element={<PublicIntro isInternal={true}/>}/>}/>
                        <Route path="/tools/summarization" element={<Layout element={<PublicSummarization/>}/>}/>
                        <Route path="/tools/youtube" element={<Layout element={<PublicSummarizationYoutube/>}/>}/>

                        <Route path="/changelog" element={<Layout element={<ChangelogPage/>}/>}/>
                        <Route path="/subscribe" element={<Layout element={<SubscribePage/>}/>}/>
                        <Route path="/payment-succeeded" element={<Layout element={<PaymentSucceeded/>}/>}/>
                        <Route path="/payment-failed" element={<Layout element={<PaymentFailed/>}/>}/>

                    </Route>
                    <Route element={<RestrictedForAuthUsersRoute user={user}/>}>
                        <Route path="/login" element={<LoginComponent/>}/>
                    </Route>

                    <Route path="/uninstall" element={<Layout element={<UninstallComponent/>}/>}/>
                    <Route path="/public" element={<PublicLayout element={<PublicIntro/>}/>}/>
                    <Route path="/public/summarization" element={<PublicLayout element={<PublicSummarization/>}/>}/>
                    <Route path="/public/youtube" element={<PublicLayout element={<PublicSummarizationYoutube/>}/>}/>
                </Routes>
            </Router>
        </Suspense>
    );
}

export default function App() {
    return (
        <ServiceProvider>
            <AppRoutes/>
        </ServiceProvider>
    );
}
