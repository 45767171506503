import {Badge, Button, Join, Menu, Tooltip} from 'react-daisyui';
import {Meet} from '../../../../../types/meet.type';
import {DeleteIcon, EncryptedIcon} from '../../../icons/icons.component';
import {useNavigate} from 'react-router-dom';
import './group-item.component.scss';

const MAX_USERS_TO_SHOW = 5;
const MAX_USER_NAME_SYMBOLS = 20;

export function GroupItem({meet, selected, onMeetDelete, short}: {
    meet: Meet,
    short?: boolean,
    onMeetDelete: (meetKey: string) => void,
    selected?: boolean
}) {
    const navigate = useNavigate();
    const meetDuration = (meet?.finishedAt || 0) - (meet?.createdAt || 0);
    const durationInMinutes = Math.floor(meetDuration / 60000);
    const date = new Date(meet.date);
    const endDate = new Date(meet.finishedAt);
    const finishHours = endDate.getHours();
    const finishMin = endDate.getMinutes();
    const hours = date.getHours();
    const min = date.getMinutes();

    const onMenuClicked = (uid: string) => {
        return navigate(`/${uid}`);
    }

    const getUserName = (user: string) => {
        if (user.length > MAX_USER_NAME_SYMBOLS) {
            return user.slice(0, MAX_USER_NAME_SYMBOLS) + '...';
        }
        return user;
    }

    const getUsers = (users: string[]) => {
        const filteredUsers = users.filter((u, i) => users.indexOf(u) === i);
        if (filteredUsers.length <= MAX_USERS_TO_SHOW) {
            return filteredUsers.map(user => (
                <Badge key={`${meet.key}-${user}`} color={"neutral"} className={"user-badge"}>
                    {getUserName(user)}
                </Badge>
            ));
        }
        const firstPack = filteredUsers.slice(0, MAX_USERS_TO_SHOW);
        const restUsers = filteredUsers.slice(MAX_USERS_TO_SHOW);

        return firstPack.map(user => (
            <Badge key={`${meet.key}-${user}`} color={"neutral"} className={"user-badge"}>
                {getUserName(user)}
            </Badge>
        )).concat(
            <Badge key={`${meet.key}-rest`} color={"neutral"} className={"user-badge"}>
                <Tooltip message={restUsers.join(',')}>+{restUsers.length}</Tooltip>
            </Badge>
        );
    }

    const onRemoveClick = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        onMeetDelete(meet.key);
        return
    }

    // if (short) {
    //     return <Menu.Item key={meet.key} className={`meetings-group-item-short ${selected ? 'selected-meet' : ''}`}
    //                       onClick={() => onMenuClicked(meet.key)}>
    //         <div className="meet-item">
    //             <Join horizontal className="meeting-title-block">
    //                 {meet.encrypted && <Tooltip message={"This meet is encrypted"}><EncryptedIcon/></Tooltip>}
    //                 <span>{meet.title || 'Untitled meet'}</span>
    //             </Join>
    //
    //             <div className="actions-block">
    //                 <Button shape="circle" startIcon={<DeleteIcon/>} onClick={onRemoveClick}></Button>
    //             </div>
    //         </div>
    //
    //     </Menu.Item>
    //
    // }

    return <Menu.Item key={meet.key} className={`meetings-group-item ${selected ? 'selected-meet' : ''}`}
                      onClick={() => onMenuClicked(meet.key)}>
        <div className="meet-item">
            <Join horizontal className={"main-block"}>
                <Join vertical className="time-block">
                    <span>{`${hours > 9 ? hours : '0' + hours}:${min > 9 ? min : '0' + min}`}</span>
                    {meet.finishedAt &&
                        <span>{`${finishHours > 9 ? finishHours : '0' + finishHours}:${finishMin > 9 ? finishMin : '0' + finishMin}`}</span>}
                </Join>
                <div>
                    <Join horizontal className="meeting-title-block">
                        {meet.encrypted && <Tooltip message={"This meet is encrypted"}><EncryptedIcon/></Tooltip>}
                        <span>{meet.title || 'Untitled meet'}</span>
                        {durationInMinutes >= 0 && <span
                            className={"duration"}>for {durationInMinutes >= 0 ? durationInMinutes : 0} minutes</span>}

                    </Join>
                    <div className="participations-block">
                        {getUsers(meet.users)}
                    </div>
                </div>
            </Join>

            <div className="actions-block">
                <Button shape="circle" startIcon={<DeleteIcon/>} onClick={onRemoveClick}></Button>
            </div>
        </div>

    </Menu.Item>
}
