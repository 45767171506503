import {raiseError} from './error.service';

export class Api {
    private PREMIUM_SUFFIX = '/v1/p/';
    private token: string = '';
    private readonly RetryCount = 2;
    private retryCount = 0;

    constructor(private readonly getToken: () => ({
        token: string,
        getRefreshedToken: () => Promise<string>
    })) {
        this.token = this.getToken().token;
    }

    public async fetch(url: string, options: RequestInit, raiseCatchError = true): Promise<Response | null> {
        try {
            const response = await fetch(url, {
                ...options,
                headers: {
                    ...options.headers,
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.token}`,
                },
            });

            if (response.status === 401 && this.retryCount < this.RetryCount && !url.includes(this.PREMIUM_SUFFIX)) {
                this.token = await this.getToken().getRefreshedToken();
                this.retryCount++;

                return this.fetch(url, options);
            }

            if (response.status === 429) {
                raiseError('Daily limit reached');
                return response;
            }

            if (response.status >= 400 && raiseCatchError) {
                raiseError('Error occur. Try again later.');
            }

            return response;
        } catch (ex) {
            console.log(ex);
            if (raiseCatchError) {
                raiseError('Error occur. Try again later.');
            }

            return null;
        }
    }
}
