import {bind} from '@react-rxjs/core';
import {startWith, tap} from 'rxjs';
import {DashboardComponent} from './dashboard.component';
import {Loading} from 'react-daisyui';
import {createSignal} from '@react-rxjs/utils';
import {NoMeetingsComponent} from '../empty/no-meetings.component';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {useServices} from '../../../service.context';
import {MeetService} from '../../../services/meet';

const [isLoading, onIsLoading] = createSignal<boolean>();

const [useMeetings] = bind((meetService: MeetService) => fromPromise(meetService.getGroupedMeetList()).pipe(
        startWith(undefined),
        tap(() => onIsLoading(true)),
        tap(meets => {
            onIsLoading(false);
        })
    ),
    {}
);
const [useIsLoading] = bind(isLoading, false);

export default function DashboardRouteComponent() {
    const {meetService} = useServices();
    const isLoading = useIsLoading();

    const meets = useMeetings(meetService!);

    if ((!meets || Object.keys(meets).length === 0) && isLoading) {
        return <Loading variant="dots"/>;
    }

    if ((!meets || Object.keys(meets).length === 0)) {
        return <NoMeetingsComponent/>
    }

    return <DashboardComponent meets={meets}/>
}
