import {Subject} from 'rxjs';

const subject = new Subject<string>();

export function raiseNotification(notification: string, timeoutMs: number = 5000) {
    subject.next(notification);
    setTimeout(() => subject.next(''), timeoutMs);
}

export function getNotificationSubject() {
    return subject.asObservable();
}
