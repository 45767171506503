import React from 'react';
import {Button, Link, Navbar} from 'react-daisyui';
import {MenuComponent} from '../menu.component';
import {LogoutIcon} from '../../icons/icons.component';
import {LogoSmallComponent} from '../../../logo/logo.component';
import {useNavigate} from 'react-router-dom';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {bind} from '@react-rxjs/core';
import {UserSettings} from '../../../../services/settings/user-settings';
import {useServices} from '../../../../service.context';

const [useIsPremium] = bind((userSettings: UserSettings) => fromPromise(userSettings.isPremium()), false);

export const NavbarWithDrawer = () => {
    const {userSettings, authService} = useServices();
    const navigate = useNavigate();
    const isPremium = useIsPremium(userSettings!)

    const logOut = async () => {
        authService?.logout();
        navigate('/login');
    };

    return <Navbar className="font-sans bg-neutral">
        <Navbar.Start className="px-2 lg:flex-none">
            <Link href="/" style={{textDecoration: 'none'}} className="main-page">
                <LogoSmallComponent width={60} height={40} fill={'white'}/>
                {isPremium && <div className="gold">Premium</div>}
            </Link>
            <MenuComponent/>
        </Navbar.Start>

        <Navbar.End>
            <Button color="ghost" onClick={logOut} className={"text-white"} endIcon={<LogoutIcon/>}>
                Log out
            </Button>
        </Navbar.End>
    </Navbar>
};
