import {OnboardingStepParams} from '../../onbording.interface';
import {Button, Hero} from 'react-daisyui';
import {OnboardingJoinMeetingAsset} from '../../onboarding.assets';
import {NextIcon, PrevIcon} from '../../../icons/icons.component';

export function OnboardingJoinMeetingComponent({next, prev}: OnboardingStepParams) {
    const stepName = 'join-meeting';

    return (
        <Hero>
            <Hero.Content>
                <div className="max-w-sm rounded-lg welcome">

                    {/*<img src={exampleChat} alt="chat example" className="rounded"/>*/}
                    <OnboardingJoinMeetingAsset/>
                </div>
                <div className="ml-16">
                    <h1 className="text-5xl font-bold">Join Google Meet</h1>
                    <p className="py-6">
                        Join a Google Meet meeting as usual.
                        MBox Meet will start automatically and analyze the meeting.
                        No video or audio record, only a real-time transcription.
                    </p>
                    <div className="flex justify-between">
                        <Button shape="circle" className="right-1" startIcon={<PrevIcon/>}
                                onClick={() => prev!(stepName)}></Button>
                        <Button shape="circle" className="right-1" startIcon={<NextIcon/>}
                                onClick={() => next(stepName)}></Button>
                    </div>
                </div>
            </Hero.Content>
        </Hero>
    );
}
