import {Api} from '../api';

export abstract class AUtils extends Api {
    protected isPublic: boolean = false;

    public setIsPublic(isPublic: boolean) {
        this.isPublic = isPublic;
    }

    abstract useSummarization({text, url, summaryKey}: { text?: string, url?: string, summaryKey?: string }): Promise<any>;

    abstract useYoutubeSummarization({url}: { url?: string }): Promise<any>;
}
